const styles = {
  guidelinesContainer: {
    display: 'flex',
    px: 5,
    pt: 2,
    pb: 7,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2.5,
    flex: '1 0 0',
    alignSelf: 'stretch',
    margin: '0px !important'
  },
  categoryText: {
    color: 'gray.900',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '120%'
  },
  subHeadingText: {
    color: 'gray.900',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '120%'
  },
  divider: { my: 1.5, borderColor: 'gray.200', backgroundColor: 'transparent !important' },
  statusDivider: {
    width: '100%',
    borderColor: 'gray.200',
    backgroundColor: 'transparent !important'
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    my: 1
  },
  flag: { width: 18, height: 12, mr: '12px' },
  statusContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: 1,
    border: '0.5px solid',
    borderColor: 'gray.300',
    backgroundColor: 'gray.0',
    width: 'fit-content'
  },
  hideButton: {
    color: 'primary.600',
    fontSize: 13,
    fontWeight: 600,
    cursor: 'pointer'
  },
  statusChip: {
    display: 'flex',
    alignItems: 'center',
    height: 16,
    justifyContent: 'flex-start',
    fontSize: 11,
    maxWidth: 'fit-content',
    fontWeight: 600,
    mr: 0.5,
    pl: 0.5,
    pr: 0.5,
    ml: 0.5,
    borderRadius: 0.5
  },
  linkButton: {
    color: 'secondary.700',
    fontSize: 11,
    fontWeight: 600,
    width: 50,
    textDecoration: 'none'
  },
  workingListHeading: {
    fontSize: 14,
    fontWeight: 600,
    color: 'primary.600',
    cursor: 'pointer'
  },
  toolTipText: {
    p: 0.5,
    display: 'flex',
    maxWidth: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    wordBreak: 'break-word'
  }
};

export default styles;
