import React from 'react';
import { Box, Typography, Stack, Divider, Tooltip } from '@mui/material';
import styles from '../../styles/GuidelineSectionDetails.styles';
import { PipedDataProps } from '../../types';

const PipedData: React.FC<PipedDataProps> = ({ data }: any) => {
  return (
    <Stack
      display='flex'
      direction='row'
      divider={
        <Divider orientation='vertical' sx={{ height: 16, mx: 1, borderColor: 'gray.400' }} />
      }>
      {data.map((d: any) => (
        <Tooltip key={d?.index} title={d?.value}>
          <Box key={d?.index} display='flex'>
            {d.label && (
              <Typography
                key={d.label}
                sx={{ ...styles.valueText, color: 'gray.800', fontWeight: 400, pl: 0 }}>
                {d.label}:
              </Typography>
            )}
            <Typography sx={styles.valueText}>{d.value || 'NA'}</Typography>
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default React.memo(PipedData);
