import { memo, useState, useCallback } from 'react';

import {
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider
} from '@mui/material';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent
} from '@mui/lab';

import componentStyles from '../styles/Components.styles';

import { DropdownIcon } from '../../../assets/svgs/Icons';

import { IStepsToRecreate } from '../interface';

import ElementRenderer from './ElementRenderer';

interface StepsToRecreateProps {
  steps: IStepsToRecreate[] | undefined;
}

const EmptyTimeLine = () => {
  return (
    <Timeline sx={componentStyles.stepsToRecreate.timelineContainer}>
      <TimelineItem sx={componentStyles.stepsToRecreate.emptyTimelineItem}>
        <TimelineSeparator sx={componentStyles.stepsToRecreate.emptyTimelineSeparator}>
          <TimelineConnector sx={componentStyles.stepsToRecreate.timelineConnector} />
        </TimelineSeparator>
        <TimelineContent sx={componentStyles.stepsToRecreate.timelineContentContainer}>
          <Stack />
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

const StepsToRecreate = ({ steps }: StepsToRecreateProps) => {
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);

  const handleAccordionChange = useCallback(
    (panel: number) => () => {
      setExpandedAccordions(prevExpandedAccordions =>
        prevExpandedAccordions.includes(panel)
          ? prevExpandedAccordions.filter(id => id !== panel)
          : [...prevExpandedAccordions, panel]
      );
    },
    []
  );

  return (
    <Stack gap={2} mb={2.5}>
      <Typography sx={componentStyles.stepsToRecreate.text}>
        Steps to recreate the flow for precedence:
      </Typography>
      <Stack sx={componentStyles.stepsToRecreate.stepsContainer}>
        <EmptyTimeLine />
        {steps?.map((step: IStepsToRecreate, index) => {
          const isLastItem = index === steps.length - 1;
          return (
            <Timeline key={step.id} sx={componentStyles.stepsToRecreate.timelineContainer}>
              <TimelineItem sx={componentStyles.stepsToRecreate.timelineItem}>
                <TimelineSeparator sx={componentStyles.stepsToRecreate.timelineSeparator}>
                  <IconButton
                    sx={{
                      ...componentStyles.stepsToRecreate.iconButtonDefault,
                      ...(expandedAccordions.includes(step.id) &&
                        componentStyles.stepsToRecreate.iconButtonHighlighted)
                    }}>
                    {index + 1}
                  </IconButton>
                  <TimelineConnector sx={componentStyles.stepsToRecreate.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent sx={componentStyles.stepsToRecreate.timelineContentContainer}>
                  <Accordion
                    disableGutters
                    sx={componentStyles.stepsToRecreate.accordionContainer}
                    onChange={handleAccordionChange(step.id)}>
                    <AccordionSummary
                      expandIcon={<DropdownIcon />}
                      aria-controls={`panel-${step.id}-content`}
                      id={`panel-${step.id}-header`}
                      sx={componentStyles.stepsToRecreate.accordionSummaryContainer}>
                      <Typography sx={componentStyles.stepsToRecreate.accordionHeader}>
                        {step.header}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={componentStyles.stepsToRecreate.accordionDetailsContainer}>
                      {step.content.map(content => (
                        <ElementRenderer key={content.id} content={content} />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  {!isLastItem && <Divider sx={componentStyles.stepsToRecreate.divider} />}
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          );
        })}
        <EmptyTimeLine />
      </Stack>
    </Stack>
  );
};

export default memo(StepsToRecreate);
