const styles = {
  common: {
    oneLineText: {
      lineClamp: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical'
    },
    twoLineText: {
      lineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical'
    },
    scroll: {
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.lightVariant3',
        borderRadius: '4px',
        opacity: '0.8'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      }
    }
  },
  document: {
    drawer: {
      '& .MuiDrawer-paperAnchorRight': {
        top: 0
      },
      '& >.MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.25)'
      }
    }
  },
  detail: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.lightVariant3',
        borderRadius: '4px',
        opacity: '0.8'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      mx: 1.25,
      my: 1.5
    },
    accordion: {
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'gray.300',
      boxShadow: 'none',
      '&::before': {
        display: 'none'
      }
    },
    accordionSummary: {
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      py: 1,
      px: 2,
      minHeight: 35,
      '& .MuiAccordionSummary-expandIconWrapper': {
        '@media (min-width: 900px)': {
          mt: 0
        }
      },
      '&.Mui-expanded': {
        px: 2,
        py: 1,
        md: { minHeight: 35 }
      },
      '& .MuiAccordionSummary-content': {
        marginTop: '0px',
        marginBottom: '0px'
      }
    },
    accordionDetail: {
      px: 2,
      pt: 1.5
    },
    accordionDetailSubCategoryContainer: {
      mt: 1,
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      p: 1,
      borderRadius: 1,
      border: '1px solid',
      '&:hover': { bgcolor: 'gray.100' },
      '&:active': { bgcolor: 'gray.300' }
    }
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    py: 1.5,
    backgroundColor: 'primary.600',
    height: '5.5%'
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: 'gray.0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    gap: 1,
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    backgroundColor: 'gray.100'
  },
  divider: { mt: 1.75, borderColor: 'gray.300', width: '100%' },
  documentViewer: {
    snackBarContainer: {
      top: `50px !important`,
      left: '0px !important'
    },
    pdfContainer: {
      borderRadius: 5,
      mb: 10,
      background: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      maxHeight: '99vh',
      '& > div#pdfBox': { height: '86%', width: '96%' }
    },
    titleText: {
      fontSize: 14,
      fontWeight: 700,
      textOverflow: 'ellipsis'
    },
    chatRiaButton: {
      display: 'flex',
      ml: '10px',
      width: 'fit-content',
      borderRadius: '16px',
      height: '32px',
      padding: '6px 16px',
      border: '1px solid',
      borderColor: 'blue.backgroundLight',
      backgroundColor: 'white.main',
      textTransform: 'capitalize',
      color: 'blue.backgroundLight',
      fontSize: '12px',
      fontWeigth: '400'
    },
    chatRiaIcon: {
      width: '16px',
      height: '16px',
      p: '2.667px 2.666px 2.667px 2.667px'
    },
    closeIcon: { width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' },
    flagIcon: { width: 17, height: 12, mt: 0.25, ml: 1.5 }
  },
  overview: {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'start',
      gap: 1,
      width: '100%'
    },
    labelBox: {
      minWidth: 110
    },
    labelText: {
      flex: 1,
      boxSizing: 'border-box',
      overflow: 'hidden',
      color: 'gray.600',
      textOverflow: 'ellipsis',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '120%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      maxHeight: '3em'
    },
    valueText: {
      color: 'gray.800',
      textOverflow: 'ellipsis',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '120%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
      maxHeight: '3em',
      overflowY: 'clip',
      textTransform: 'capitalize',
      paddingLeft: 0.75
    }
  }
};

export default styles;
