import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import GroupTabs from '../../Guidelines/components/common/GroupTabs';
import GuidelinesList from './GuidelinesList';
import { CATEGORY_ORDER } from '../contants';

interface GuidelinesDetailsProps {
  guidelines: any;
  selectedGroup: string;
  setSelectedGroup: any;
}
const GuidelinesDetails = ({
  guidelines,
  selectedGroup,
  setSelectedGroup
}: GuidelinesDetailsProps) => {
  const [currentExpandedCard, setCurrentExpandedCard] = useState<string>('');

  const handleSectionExpand = (id: string) => {
    if (id === currentExpandedCard) {
      setCurrentExpandedCard('');
    } else {
      setCurrentExpandedCard(id);
    }
  };

  const tabsToDisplay = useMemo(() => {
    let tabList: Array<any> = [];
    const predefinedOrder = CATEGORY_ORDER;

    const predefinedTabs = predefinedOrder
      .filter(item => Object.keys(guidelines).includes(item))
      .map(item => ({
        id: item,
        label: item
      }));

    const remainingTabs = Object.keys(guidelines)
      .filter(key => !predefinedOrder.includes(key))
      .sort((a, b) => a.localeCompare(b))
      .map(item => ({
        id: item,
        label: item
      }));

    tabList = [...predefinedTabs, ...remainingTabs];
    const isSelectedGroupValid = tabList.some((item: { id: string }) => item?.id === selectedGroup);
    if (!isSelectedGroupValid) {
      setSelectedGroup('All');
    }
    return tabList;
  }, [guidelines, selectedGroup]);

  const handleTabChange = (selectedTab: string) => {
    setSelectedGroup(selectedTab);
  };

  const sortedGuidelines = useMemo(() => {
    const order = CATEGORY_ORDER;

    const sortedData: any = Object.fromEntries(
      Object.entries(guidelines).sort((a, b) => {
        const indexA = order.indexOf(a[0]);
        const indexB = order.indexOf(b[0]);

        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
      })
    );
    return sortedData;
  }, [guidelines]);

  return (
    <Box>
      <GroupTabs tabs={tabsToDisplay} selectedTab={selectedGroup} onChange={handleTabChange} />
      <Box pt={2}>
        {Object.keys(sortedGuidelines)
          ?.filter((item: string) => selectedGroup === 'All' || item === selectedGroup)
          ?.map((item: string) => (
            <GuidelinesList
              key={item}
              category={item}
              guidelineData={guidelines[item]}
              onSectionExpand={handleSectionExpand}
              expanded={currentExpandedCard}
            />
          ))}
      </Box>
    </Box>
  );
};

export default React.memo(GuidelinesDetails);
