import { createSvgIcon } from '@mui/material';

const TwFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_8986_3609)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0 0H18V12H0V0Z' fill='#FE0000' />
      <path fillRule='evenodd' clipRule='evenodd' d='M0 0H9V6H0V0Z' fill='#000095' />
      <path
        d='M4.5 0.75L3.375 4.94856L6.44856 1.875L2.25 3L6.44856 4.125L3.375 1.05144L4.5 5.25L5.625 1.05144L2.55144 4.125L6.75 3L2.55144 1.875L5.625 4.94856L4.5 0.75Z'
        fill='white'
      />
      <path
        d='M4.4998 4.20005C5.16255 4.20005 5.6998 3.66279 5.6998 3.00005C5.6998 2.33731 5.16255 1.80005 4.4998 1.80005C3.83706 1.80005 3.2998 2.33731 3.2998 3.00005C3.2998 3.66279 3.83706 4.20005 4.4998 4.20005Z'
        fill='white'
        stroke='#000095'
        strokeWidth='0.05'
      />
    </g>
    <defs>
      <clipPath id='clip0_8986_3609'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'TwFlagIcon'
);

export default TwFlagIcon;
