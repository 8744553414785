import { Box, Divider, Grid, Link, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from '../styles/GuidelinesList.styles';
import Stats from '../../SearchResults/components/Stats';
import { sourceFlagMapping } from '../../SearchResults/constants';
import StatusChips from './StatusChips';
import WorkingList from './WorkingList';
import { STATUS_MAPPING } from '../contants';
import Documents from './Documents/Documents';
import FormatHtmlString from '../../Guidelines/components/common/FormatHtmlString';

export const ListComponent = ({
  dataList,
  data,
  setDocumentDetails,
  setPartyName,
  showDivider = false
}: {
  dataList: Array<any>;
  data: any;
  setDocumentDetails: any;
  setPartyName: any;
  showDivider: boolean;
}) => {
  const partyList = data?.documents?.map((item: any) => item?.party_name);

  return (
    <List sx={{ width: '100%', pt: 0, pb: 0 }}>
      {dataList?.map((item: any, index: number) => {
        const countryCode = item?.country_code?.toLowerCase();
        const FlagComponent =
          countryCode && sourceFlagMapping[countryCode]
            ? sourceFlagMapping[countryCode]
            : sourceFlagMapping.us;
        return (
          <React.Fragment key={item?.party}>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: 1,
                height: 28
              }}>
              {FlagComponent && <FlagComponent sx={styles.flag} />}
              <Typography sx={{ minWidth: 140, fontSize: 11, fontWeight: 500 }}>
                {item?.party}
              </Typography>
              <Box sx={{ width: 'max-content' }}>
                <StatusChips key={item?.party} statusData={item} />
              </Box>

              {partyList?.includes(item?.party) && item?.condition === 'Implemented' && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  component='button'
                  variant='body2'
                  sx={styles.linkButton}
                  onClick={() => {
                    setDocumentDetails(data);
                    setPartyName(item?.party);
                  }}>
                  Link
                </Link>
              )}
            </ListItem>
            {showDivider ? (
              index < dataList.length - 1 && <Divider sx={styles.statusDivider} />
            ) : (
              <Divider sx={styles.statusDivider} />
            )}{' '}
          </React.Fragment>
        );
      })}
    </List>
  );
};

const GuidelinesActionsDetails: React.FC<any> = ({ data }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const [openWorkingListDrawer, setOpenWorkingListDrawer] = useState<boolean>(false);
  const descriptionRef = useRef<any>(null);
  const [documentDetails, setDocumentDetails] = useState({});
  const [partyName, setPartyName] = useState('');

  const toggleText = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const checkTruncation = () => {
      if (descriptionRef?.current) {
        setIsTruncated(
          (descriptionRef?.current?.scrollHeight || 0) >
            (descriptionRef?.current?.clientHeight || 0)
        );
      }
    };

    checkTruncation(); // Initial check
    window.addEventListener('resize', checkTruncation); // Check again on window resize

    return () => {
      window.removeEventListener('resize', checkTruncation); // Clean up event listener
    };
  }, [data?.description]); // Re-run when the description changes

  const statusCount = useMemo(() => {
    const conditionCount = data?.implementation_status?.reduce(
      (acc: { [x: string]: any }, item: { condition: string }) => {
        const status = item?.condition?.toLowerCase();
        const mappedCondition = STATUS_MAPPING[status in STATUS_MAPPING ? status : 'default'];
        acc[mappedCondition] = (acc[mappedCondition] || 0) + 1;
        return acc;
      },
      {}
    );

    const newStats: Array<any> = [];

    newStats.push({
      title: 'Total',
      value: data?.implementation_status?.length ?? 0
    });

    // Explicit ordering for specific titles
    const orderedTitles = ['Implemented', 'In-Process', 'Not yet'];
    orderedTitles.forEach(title => {
      if (conditionCount[title]) {
        newStats.push({
          title,
          value: conditionCount[title]
        });
      }
    });

    // Add remaining categories in default order
    Object.keys(conditionCount).forEach((item: string) => {
      if (!orderedTitles.includes(item)) {
        newStats.push({
          title: item,
          value: conditionCount[item]
        });
      }
    });

    return newStats;
  }, [data]);

  const firstHalf = data?.implementation_status?.slice(
    0,
    Math.ceil(data.implementation_status.length / 2)
  );
  const secondHalf = data?.implementation_status?.slice(
    Math.ceil(data.implementation_status.length / 2)
  );

  return (
    <>
      <Stack>
        <Typography fontWeight={400} color='gray.800'>
          <Typography fontSize={14} fontWeight={700}>
            About:
          </Typography>
        </Typography>
        <Box>
          <Typography
            ref={descriptionRef}
            fontSize={14}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: expanded ? 'unset' : 2 // Show 2 lines initially, 'unset' when expanded
            }}>
            <FormatHtmlString htmlString={data?.description} />
          </Typography>
          {isTruncated && (
            <Stack direction='row' justifyContent='flex-start'>
              <Box onClick={toggleText} sx={styles.hideButton}>
                {expanded ? 'Hide' : 'See All'}
              </Box>
            </Stack>
          )}
        </Box>
        <Divider orientation='horizontal' sx={styles.divider} />
        {(Object.keys(data?.wg_list).length !== 0 || data?.experts?.length !== 0) && (
          <Box
            onClick={() => {
              setOpenWorkingListDrawer(true);
            }}>
            <Typography sx={styles.workingListHeading}>See Working Group (WG) List</Typography>
            <Divider orientation='horizontal' sx={styles.divider} />
          </Box>
        )}

        {data?.implementation_status?.length !== 0 && (
          <Stack pb={1.5}>
            <Typography fontSize={14} component='span' fontWeight={700} color='gray.800'>
              Implementation Status:
            </Typography>
            <Box sx={styles.contentContainer}>
              <Stats stats={statusCount} />
            </Box>
            <Stack sx={styles.statusContainer}>
              <Grid container xs={12} sx={{ width: '100%' }}>
                <Grid item xs={6}>
                  <Stack display='flex' flexDirection='row' width='100%'>
                    <ListComponent
                      dataList={firstHalf}
                      data={data}
                      setDocumentDetails={setDocumentDetails}
                      setPartyName={setPartyName}
                      showDivider
                    />
                    <Divider orientation='vertical' flexItem />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <ListComponent
                    dataList={secondHalf}
                    data={data}
                    setDocumentDetails={setDocumentDetails}
                    setPartyName={setPartyName}
                    showDivider={false}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        )}
        {openWorkingListDrawer && (
          <WorkingList
            openDrawer={openWorkingListDrawer}
            onClose={() => {
              setOpenWorkingListDrawer(false);
            }}
            data={data}
          />
        )}
      </Stack>
      {Object.keys(documentDetails)?.length > 0 && (
        <Documents
          documentDetails={documentDetails}
          setDocumentDetails={setDocumentDetails}
          partyName={partyName}
        />
      )}
    </>
  );
};

export default React.memo(GuidelinesActionsDetails);
