import { useLocation } from 'react-router-dom';
import { useEffect, useState, memo, useMemo } from 'react';

import { IconButton, Dialog } from '@mui/material';
import { Close } from '@mui/icons-material';

import { STARTUP_VIDEOS } from '../const';
import startupVideoStyles from '../styles/StartupVideo.styles';

const StartupVideo = ({ id }: { id: string }) => {
  const location = useLocation();
  const [showStartupVideo, setShowStartupVideo] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const currentVideo = useMemo(() => STARTUP_VIDEOS?.[id], [id, location]);

  const handleVideoClose = () => {
    localStorage.setItem(currentVideo.localStorageKey, 'false');
    setShowStartupVideo(false);
  };

  useEffect(() => {
    if (!currentVideo) {
      setShowStartupVideo(false);
      return;
    }

    if (
      location.hash === `#${currentVideo.urlHash}` ||
      !localStorage.getItem(currentVideo.localStorageKey)
    ) {
      setShowStartupVideo(true);
    } else {
      setShowStartupVideo(false);
    }
  }, [location]);

  return (
    <Dialog
      fullWidth
      open={showStartupVideo}
      onClose={handleVideoClose}
      sx={startupVideoStyles.dialog}>
      <IconButton
        onMouseEnter={() => setShowCloseIcon(true)}
        onClick={handleVideoClose}
        sx={{
          ...startupVideoStyles.closeIconButton,
          display: showCloseIcon ? 'flex' : 'none'
        }}>
        <Close />
      </IconButton>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        onMouseEnter={() => setShowCloseIcon(true)}
        onMouseLeave={() => setShowCloseIcon(false)}
        controls
        src={currentVideo.videoUrl}
        controlsList='nodownload'
        style={startupVideoStyles.video}
        poster={currentVideo.poster}>
        Your browser does not support the video tag.
      </video>
    </Dialog>
  );
};
export default memo(StartupVideo);
