import { useCallback, useEffect, useMemo } from 'react';

import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import useSavedData from '../../hooks/useSavedData';
import useProjectData from '../../hooks/useProjectData';
import NotificationLoading from '../../../Notifications/components/NotificationLoading';
import Searches from './Searches';
import Documents from './Documents';
import Applications from './Applications';
import Articles from './Articles';
import styles from '../../styles/SavedItems.styles';
import applicationTabStyles from '../../styles/ApplicationsTab.styles';

// This component is used to render the saved items and project page based on the page mode
// The page mode can be either 'savedItems' or 'project'
// If the page mode is 'savedItems', it will render the saved items page
// If the page mode is 'project', it will render the project page

const SavedItemsAndProjectPage = ({ pageMode }: { pageMode: 'savedItems' | 'project' }) => {
  // Fetch the saved data and project data based on the page mode using the custom hooks
  const {
    fetchSavedData,
    isLoading: isSavedItemPageLoading,
    savedData,
    handleSavedSearchTabFavorite,
    handleSavedSearchTabProject,
    handleSavedDocumentTabFavorite,
    handleSavedDocumentTabProject,
    handleSavedApplicationTabFavorite,
    handleSavedApplicationTabProject,
    handleSavedApplicationTabSubscription
  } = useSavedData();
  const {
    fetchProjectData,
    isLoading: isProjectPageLoading,
    projectData,
    handleProjectsSearchTabFavorite,
    handleProjectsSearchTabProject,
    handleProjectsDocumentTabFavorite,
    handleProjectsDocumentTabProject,
    handleProjectsApplicationTabFavorite,
    handleProjectsApplicationTabProject,
    handleProjectsApplicationTabSubscription
  } = useProjectData();
  const { projectId } = useParams() as any;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabValue = searchParams.get('tab') ?? 'searches';

  useEffect(() => {
    if (pageMode === 'project') {
      fetchProjectData(projectId ?? 0);
    } else {
      fetchSavedData();
    }
  }, [projectId, pageMode]);

  const isPageLoading = useMemo(() => {
    return pageMode === 'project' ? isProjectPageLoading : isSavedItemPageLoading;
  }, [isProjectPageLoading, isSavedItemPageLoading, pageMode]);

  const searches = useMemo(() => {
    return pageMode === 'savedItems' ? savedData.searches : projectData.searches;
  }, [savedData, projectData, pageMode]);

  const documents = useMemo(() => {
    return pageMode === 'savedItems' ? savedData.documents : projectData.documents;
  }, [savedData, projectData, pageMode]);

  const applications = useMemo(() => {
    return pageMode === 'savedItems' ? savedData.applications : projectData.applications;
  }, [savedData, projectData, pageMode]);

  // Memoize the handleFavoriteHook, handleProjectHook and handleSubscriptionHook based on the tab value and page mode
  const handleFavoriteHook = useMemo(() => {
    switch (tabValue) {
      case 'searches':
        return pageMode === 'savedItems'
          ? handleSavedSearchTabFavorite
          : handleProjectsSearchTabFavorite;
      case 'documents':
        return pageMode === 'savedItems'
          ? handleSavedDocumentTabFavorite
          : () => handleProjectsDocumentTabFavorite;
      case 'applications':
        return pageMode === 'savedItems'
          ? handleSavedApplicationTabFavorite
          : () => handleProjectsApplicationTabFavorite;
      default:
        return () => {};
    }
  }, [
    handleSavedSearchTabFavorite,
    handleSavedDocumentTabFavorite,
    handleSavedApplicationTabFavorite,
    handleProjectsSearchTabFavorite,
    handleProjectsDocumentTabFavorite,
    handleProjectsApplicationTabFavorite,
    tabValue,
    pageMode
  ]);

  // Memoize the handleProjectHook based on the tab value and page mode
  const handleProjectHook = useMemo(() => {
    switch (tabValue) {
      case 'searches':
        return pageMode === 'savedItems'
          ? handleSavedSearchTabProject
          : handleProjectsSearchTabProject;
      case 'documents':
        return pageMode === 'savedItems'
          ? handleSavedDocumentTabProject
          : handleProjectsDocumentTabProject;
      case 'applications':
        return pageMode === 'savedItems'
          ? handleSavedApplicationTabProject
          : () => handleProjectsApplicationTabProject;
      default:
        return () => {};
    }
  }, [
    handleSavedSearchTabProject,
    handleSavedDocumentTabProject,
    handleSavedApplicationTabProject,
    handleProjectsSearchTabProject,
    handleProjectsDocumentTabProject,
    handleProjectsApplicationTabProject,
    tabValue,
    pageMode
  ]);

  // Memoize the handleSubscriptionHook based on the page mode
  const handleSubscriptionHook = useMemo(() => {
    return pageMode === 'savedItems'
      ? handleSavedApplicationTabSubscription
      : handleProjectsApplicationTabSubscription;
  }, [handleSavedApplicationTabSubscription, handleProjectsApplicationTabSubscription, pageMode]);

  // Render the tab content based on the tab value
  const renderTabContent = useCallback(() => {
    switch (tabValue) {
      case 'searches':
        return (
          <Searches
            searches={searches}
            handleFavoriteHook={handleFavoriteHook}
            handleProjectHook={handleProjectHook}
          />
        );
      case 'documents':
        return (
          <Documents
            searches={documents}
            handleFavoriteHook={handleFavoriteHook}
            handleProjectHook={handleProjectHook}
          />
        );
      case 'applications':
        return (
          <Applications
            applications={applications}
            handleFavoriteHook={handleFavoriteHook}
            handleProjectHook={handleProjectHook}
            handleSubscriptionHook={handleSubscriptionHook}
          />
        );
      case 'articles':
        return <Articles />;
      default:
        return null;
    }
  }, [tabValue, searches, documents, applications]);

  return (
    <Box sx={styles.root}>
      {isPageLoading && tabValue === 'applications' && (
        <Box sx={styles.loadingBox}>
          <Grid container>
            {Array.from({ length: 3 }).map((_, index) => (
              <Grid
                // eslint-disable-next-line react/no-array-index-key
                key={`application_loading_${index}`}
                item
                xs={12}
                md={6}
                lg={4}
                xl={4}
                sx={applicationTabStyles.applicationsGrid}>
                <Skeleton
                  variant='rectangular'
                  width={300}
                  height={142}
                  sx={styles.applicationSkeletion}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {isPageLoading && tabValue !== 'applications' && (
        <Box sx={styles.listSkeletonBox}>
          <Stack height={500}>
            <NotificationLoading />
            <NotificationLoading />
            <NotificationLoading />
          </Stack>
        </Box>
      )}
      {!isPageLoading && renderTabContent()}
    </Box>
  );
};

export default SavedItemsAndProjectPage;
