const styles = {
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'gray.background'
  },
  labelBox: {
    minWidth: 120
  },
  cardSection: {
    backgroundColor: 'gray.50',
    px: 2,
    pt: 1.5,
    pb: 1,
    width: '100%',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: '1px solid',
    borderColor: 'gray.50'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 1,
    width: '100%'
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 16px',
    gap: 5,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    backgroundColor: 'primary.background',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px solid',
    borderColor: 'primary.background'
  },
  cardHeaderText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'gray.950',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px'
  },
  cardHeaderTimelineText: {
    color: 'blue.background',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px'
  },
  labelText: {
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
    color: 'gray.600',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '120%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    maxHeight: '3em'
  },
  valueText: {
    color: 'gray.800',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '120%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    maxHeight: '3em',
    overflowY: 'clip',
    textTransform: 'capitalize',
    paddingLeft: 0.75
  },
  divider: { mt: 1.5, mb: 1, borderColor: 'gray.200', backgroundColor: 'transparent !important' }
};

export default styles;
