import makeRequest, { makeBlobRequestWithCancel, makeStreamRequestWithCancel } from '../client';

/**
 * Function to get details of the Tab for a Label.
 * @param {string}
 */
export const getLabelTab = ndaNumber => makeRequest(`/clignosis/pdf_reader/${ndaNumber}`, 'GET');

/**
 * Function to get Time Line details for Reg360 page.
 * @param {string} ndaNumber
 */
export const getTimeLinePage360 = ndaNumber =>
  makeRequest(`/clignosis/drug/approvaljourney?applicationno=${ndaNumber}`, 'GET');

/**
 * Function to get Procedural Steps details for Reg360 page.
 * @param {string} ndaNumber
 */
export const getProceduralSteps = ndaNumber =>
  makeRequest(`/clignosis/drug/proceduralsteps?applicationno=${ndaNumber}`, 'GET');

/**
 * Function to get and display Marketing Review on Reg360 page.
 * @param {string} ndaNumber
 */
export const postMarkintgReviewApi = ndaNumber => makeRequest(`/PMR?nda=${ndaNumber}`, 'GET');

/**
 * Function to submit data for comparing Labels.
 * @param {object} pdfdata
 */
export const getLabelComparision = pdfdata =>
  makeRequest(`/comparison/viewer/pdf`, 'POST', pdfdata);

/**
 * Function to get details of Application Content.
 * @param {string} ndaNumber
 */
export const getApplicationContent = ndaNumber =>
  makeRequest(`/clignosis/ApplnContent/${ndaNumber}`, 'GET');

/**
 * Function to get RLD details
 * @param {string} ndaNumber
 */
export const getRLD = (ndaNumber, db) =>
  makeRequest(
    `/clignosis/drug/approvaljourney?applicationno=${db}${ndaNumber}&parents=true`,
    'GET'
  );

/**
 * Function to get Search List details
 * @param {string} category
 * @param {string} module
 * @param {string} activeIngredient
 * @param {string} source
 * @param {object} payload
 */
export const getSearchList = (category, activeIngredient, source, payload) =>
  makeRequest(
    `/quick_search/synonyms/${source.toLowerCase()}/${category}/${activeIngredient}?synonyms=true`,
    'GET',
    payload
  );

export const get505b2SearchList = (category, activeIngredient, source, module, payload) =>
  makeRequest(
    `/quick_search/synonyms/${source.toLowerCase()}/${category}/${activeIngredient}${
      module ? `?module_name=${module}` : ''
    }`,
    'GET',
    payload
  );

export const getComparisonSummary = payload =>
  makeRequest(`/chat_ria/summarize-difference`, 'POST', payload);

export const getSignedLabelURL = payload => makeRequest('/comparison/resource', 'POST', payload);

export const sendComparisonQuery = ({ payload, cancelToken, onDownloadProgress }) => {
  return makeStreamRequestWithCancel(
    `/chat_ria/conversations/comparision`,
    'POST',
    payload,
    {},
    cancelToken,
    onDownloadProgress,
    false
  );
};

export const downloadApplicationSnapshot = (applicationNumbers, cancelToken) => {
  return makeBlobRequestWithCancel(
    '/reg360/snapshots/applications',
    'POST',
    {
      application_numbers: applicationNumbers
    },
    {},
    cancelToken
  );
};

export const downloadSubmissionSnapshot = (
  applicationNumber,
  submissionType,
  submissionNumber,
  documentId,
  cancelToken
) => {
  return makeBlobRequestWithCancel(
    '/reg360/snapshots/submissions',
    'POST',
    {
      application_number: applicationNumber,
      submission_type: submissionType,
      submission_number: submissionNumber,
      document_id: documentId
    },
    {},
    cancelToken
  );
};
