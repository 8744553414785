import React, { JSX } from 'react';

import { Box, Typography, Stack, Avatar, LinearProgress } from '@mui/material';

import { Check, Clear, QuestionMark } from '@mui/icons-material';

import { Poll, PollOption } from '../interface';
import componentStyles from '../styles/Components.styles';

const iconMap: { [key: string]: JSX.Element } = {
  Check: <Check />,
  Clear: <Clear />,
  QuestionMark: <QuestionMark />
};

interface PollProps {
  poll: Poll | undefined;
}

const Polls = ({ poll }: PollProps) => {
  return (
    <Stack sx={componentStyles.poll.container}>
      <Typography sx={componentStyles.poll.title}>{poll?.title}</Typography>
      <Stack gap={1.5}>
        {poll?.options?.map((option: PollOption) => {
          const AvatarIcon = iconMap[option.icon];

          return (
            <Box key={option.id} sx={componentStyles.poll.optionContainer}>
              <Avatar sx={{ ...componentStyles.poll.avatar, bgcolor: option.color }}>
                {AvatarIcon}
              </Avatar>
              <Typography sx={componentStyles.poll.optionText}>
                {option.labelPrefix}
                <strong>{option.percentage}%</strong>
                {option.labelSuffix}
              </Typography>
              <Box flexGrow={1}>
                <LinearProgress
                  variant='determinate'
                  value={option.percentage}
                  sx={{
                    ...componentStyles.poll.progressBar,
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: option.color,
                      borderRadius: 0.5
                    }
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default React.memo(Polls);
