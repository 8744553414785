const styles = {
  mainContainer: { width: 740, mx: 'auto', mt: 4 },
  backButtonContainer: {
    cursor: 'pointer',
    fontSize: 14,
    color: 'gray.700',
    fontFamily: 'Mulish',
    fontWeight: 600
  },
  arrowSmallLeftIcon: { height: 8, width: 8 },
  articleTitle: {
    fontSize: 28,
    fontWeight: 700,
    fontFamily: 'Mulish',
    color: 'gray.900',
    mt: 4.5
  },
  topDivider: { mt: 2, borderColor: 'gray.200' },
  avatar: { height: 16, width: 16 },
  personIcon: { height: 12, width: 12 },
  articleMetaDataContainer: {
    fontSize: 14,
    color: 'gray.800',
    fontFamily: 'Mulish',
    fontWeight: 400
  },
  verticalDivider: { borderColor: 'gray.300' },
  bottomDivider: {
    mb: 2,
    borderColor: 'gray.200'
  },
  articleNotFound: {
    fontSize: 28,
    fontWeight: 700,
    fontFamily: 'Mulish',
    color: 'gray.900',
    mt: 4.5,
    textAlign: 'center'
  },
  tabsHeaderContainer: {
    '& .MuiTabs-indicator': {
      color: 'primary.700'
    },
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#0000001f'
  },
  tabHeaderItem: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Mulish',
    '&.Mui-selected': {
      color: 'primary.700',
      fontWeight: 700
    }
  }
};

export default styles;
