const styles = {
  headingText: {
    color: 'gray.900',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '160%',
    pt: 2
  },
  sectionContainer: {
    display: 'flex',
    pt: 2,
    alignItems: 'flex-start',
    width: '100%'
  },
  sectionHeading: {
    width: '30%',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: 'gray.600'
  },
  divider: { mt: 2, width: '100%' },
  infoContainer: {
    display: 'flex',
    p: '0px 60px 0px 40px',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%'
  },
  infoText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '21px',
    color: 'gray.900'
  }
};

export default styles;
