const styles = {
  mainDrawer: {
    '& .MuiDrawer-paper': {
      right: 0,
      width: 700,
      maxWidth: 700,
      height: '100%',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      overflow: 'hidden',
      backgroundColor: 'gray.50'
    }
  },
  drawerTitle: {
    maxWidth: '45%',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'white.main',
    fontSize: 20,
    fontWeight: 700
  },
  crossIcon: { ml: 'auto', p: 0.75, mr: -0.75 },
  drawerHeaderIcon: { fontSize: 16, color: 'white.main' },
  headingText: {
    color: 'gray.900',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '160%'
  },
  sectionContainer: {
    display: 'flex',
    py: 2,
    alignItems: 'flex-start',
    width: '100%'
  },
  sectionHeading: {
    width: '30%',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: 'gray.600'
  },
  divider: { mt: 2, width: '100%' },
  infoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'white.main',
    borderRadius: 3,
    p: '0px 40px 0px 20px',
    ml: 2.5
  },
  infoText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '21px',
    color: 'gray.900'
  }
};

export default styles;
