import { Box, Tooltip, Typography } from '@mui/material';
import { formatStringWithSpaces } from '../../utils/common/getFilterOptions';
import DocumentIcon from '../../../../assets/svgs/PDCO/DocumentIcon';
import styles from '../../styles/GuidelinesCards.styles';

const GuidelinesCardHeader = ({ headerText, isDocumentsAvailable, onDocumentClick }: any) => {
  const getHeaderText = () =>
    headerText && headerText !== 'NA'
      ? formatStringWithSpaces(headerText.replace(/\b\w/g, (char: string) => char.toUpperCase()))
      : 'NA';
  return (
    <Box sx={{ ...styles.cardHeader, gap: 3 }}>
      <Tooltip title={`${getHeaderText()}`} placement='top'>
        <Typography sx={styles.cardHeaderText}>{`${getHeaderText()}`}</Typography>
      </Tooltip>
      <Box ml='auto' display='flex'>
        <Tooltip title={`${isDocumentsAvailable ? '' : 'No Documents Available'}`} placement='top'>
          <Box
            display='flex'
            alignItems='center'
            ml={2}
            sx={{ cursor: `${isDocumentsAvailable ? 'pointer' : 'auto'}` }}
            onClick={() => isDocumentsAvailable && onDocumentClick?.()}>
            <DocumentIcon
              sx={{ fontSize: 14, color: isDocumentsAvailable ? 'secondary.700' : 'gray.500' }}
            />
            <Box marginLeft='4px'>
              <Typography
                sx={{
                  ...styles.cardHeaderTimelineText,
                  color: isDocumentsAvailable ? 'secondary.700' : 'gray.500'
                }}>
                Documents
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default GuidelinesCardHeader;
