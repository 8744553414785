const styles = {
  searchBarContainer: {
    marginTop: '144px',
    alignSelf: 'center',
    width: { xs: '762px', md: '762px', lg: '794px' },
    minWidth: '762px',
    maxWidth: '794px'
  },
  expertAnswersQueryText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    color: 'gray.700',
    lineHeight: 1
  },
  expertAnswersButton: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'primary.700',
    color: 'primary.700',
    textTransform: 'none',
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 600,
    px: 2,
    py: 0.75,
    height: 32,
    width: 140,
    '&:hover': {
      backgroundColor: 'primary.0'
    },
    '&:active': {
      backgroundColor: 'primary.50'
    },
    '&:disabled': {
      borderWidth: 0,
      backgroundColor: 'gray.200',
      color: 'gray.500'
    }
  }
};

export default styles;
