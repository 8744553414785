/* eslint-disable camelcase */
import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles';
import { SectionDetailsProps } from '../../types';
import EmphasisedContent from './EmphasisedContent';
import PipedData from './PipedData';

const SectionDetails: React.FC<SectionDetailsProps> = ({ data }: { data: any[] }) => {
  return (
    <Box sx={styles.container}>
      {/* Scope Section */}
      {data.map(d => (
        <Box display='flex' key={d.label}>
          <Box sx={styles.labelBox}>
            <Typography sx={styles.labelText}>{d.label}:</Typography>
          </Box>
          <Box>
            {(d.type === 'text' || d.type === 'emphasised-text') && (
              <EmphasisedContent
                label={d.label}
                mainText={d.mainText}
                tags={d?.tagTexts}
                boldEmphasis={d?.boldText}
                toolTipText={d?.toolTipText}
              />
            )}
            {d.type === 'piped-text' && <PipedData data={d.metadata} />}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(SectionDetails);
