import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Box, Stack, Typography, TextField, InputAdornment, Grid, Tooltip } from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';
import themev5 from '../../themev5';

import articlesStyles from './styles/Articles.styles';

import { ARTICLES } from './utils/constants';
import { MagnifyingGlassIcon } from '../../assets/svgs/Icons';

const Articles = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');

  const newArticles = useMemo(() => {
    return ARTICLES.filter(article => {
      return (
        article.title.toLowerCase().includes(searchText.toLowerCase()) ||
        article.rawDescription.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [searchText]);

  return (
    <ThemeProvider theme={themev5}>
      <Box sx={articlesStyles.mainContainer}>
        <Stack alignItems='center' gap={2.5} mt={8.25}>
          <Typography sx={articlesStyles.primaryHeading}>Expert Answers</Typography>
          <TextField
            placeholder='Search for Articles'
            sx={articlesStyles.textField}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Box sx={articlesStyles.inputAdornmentIconWrapper}>
                    <MagnifyingGlassIcon sx={articlesStyles.magnifyingGlassIcon} />
                  </Box>
                </InputAdornment>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              maxLength: 75
            }}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
        </Stack>

        <Typography sx={articlesStyles.secondaryHeading}>All Articles</Typography>
        {newArticles.length === 0 && (
          <Typography sx={articlesStyles.emptySearchText}>No articles found.</Typography>
        )}
        {newArticles.length > 0 && (
          <Grid container sx={articlesStyles.gridContainer}>
            {newArticles.map(article => (
              <Grid
                onClick={() => {
                  history.push(`/expert-answers/${article.id}`);
                }}
                key={article.id}
                item
                sx={articlesStyles.gridItem}>
                <Box sx={articlesStyles.articleCardContainer}>
                  <Stack direction='row' gap={2.5} justifyContent='space-between'>
                    <Stack>
                      <Tooltip title={article.title}>
                        <Typography sx={articlesStyles.articleTitle}>{article.title}</Typography>
                      </Tooltip>

                      <Tooltip title={article.rawDescription}>
                        <Typography sx={articlesStyles.articleRawDescription}>
                          {article.rawDescription}
                        </Typography>
                      </Tooltip>

                      <Typography sx={articlesStyles.articleDate}>
                        {article.addedOn.toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          timeZone: 'UTC'
                        })}
                      </Typography>
                    </Stack>
                    <Stack>
                      <img
                        src={article.image}
                        alt={article.image}
                        style={articlesStyles.articleImage}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(Articles);
