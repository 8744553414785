/* eslint-disable no-nested-ternary */
// @ts-nocheck
import { Box, Divider, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styles from '../../Core/Core.styles';
import suggestionStyles from '../styles/SearchSuggestion.styles';
import { getSearchSuggestions } from '../../../api/pages/ResultsPage';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../utils/constants';
import style from '../../Aria/styles/Banner.styles';
import FeatureButton from '../../../components/Buttons/FeatureButton';
import { savePathnameForAriaBackBtn } from '../../Aria/utils/ariaCache';
import AriaLogoSmall from '../../../assets/svgs/Core/AriaLogoSmall';

export const sourceMap: { [key: string]: string } = {
  us: 'US FDA - Drugs & Biologics',
  ca: 'CA HPFB - Drugs & Biologics',
  eu: 'EU EMA - Drugs & Biologics',
  ct: 'CT GOV - Clinical Trials',
  'us-devices': 'US FDA - Devices',
  adcomm: 'Adcomm',
  'adcomm-cdrh': 'Adcomm',
  'adcomm-cber': 'Adcomm',
  guidance: 'FDA Guidance',
  'ema-pips': 'EMA-PIPs',
  uk: 'UK-NICE',
  'ema-guidance': 'EMA Guidance',
  'canada-guidance': 'CA Guidance',
  'canada-consultation': 'CA Guidance',
  'fda-letters': 'FDA Warnings and Untitled Letters',
  'fda-written-request': 'FDA Written Requests',
  chmp: 'CHMP',
  hpra: 'IE HPRA - Drugs & Biologics',
  hma: 'EU HMA - Drugs & Biologics',
  jp: 'JP PMDA - Drugs & Biologics',
  'dd-tools': 'Drug Development Tools',
  euctr: 'EU Clinical Trials Register',
  ema_orphan: 'EMA Orphan Designations',
  au: 'AU TGA - Drugs & Biologics',
  pdco: 'PDCO',
  mhra: 'UK MHRA - Drugs & Biologics',
  'sec-edgar': 'SEC Edgar',
  'fda-guidance': 'FDA Guidance',
  hta: 'UK NICE',
  pmr: 'PMR Impact',
  'pmr-surveillance': 'PMR Surveillance',
  'us-adcomm': 'Adcomm',
  'ich-guideline': 'ich-guideline'
};
const ariaMap: { [key: string]: string } = {
  us: 'us',
  eu: 'eu',
  ca: 'ca',
  'us-devices': 'us-devices',
  ct: 'ct',
  hma: 'hma',
  jp: 'jp',
  au: 'au',
  mhra: 'mhra'
};
// @ts-ignore
const SuggestionChips: ({
  // eslint-disable-next-line no-unused-vars
  source,
  // eslint-disable-next-line no-unused-vars
  category,
  // eslint-disable-next-line no-unused-vars
  count
}: {
  source: string;
  category: string;
  count: number;
  // eslint-disable-next-line react/prop-types
}) => Element = ({ source, category, count }) => {
  // @ts-ignore
  const { module, search } = useParams();
  if (category === 'aria') return null;
  // @ts-ignore
  return (
    <Link href={`/search/label/${module}/${category}/${search}/${source}`} underline='none'>
      <Box sx={{ ...styles.eachInfo, ...suggestionStyles.chip }}>
        <Box sx={suggestionStyles.chip.circle}>
          <Tooltip title={count}>
            <Typography sx={suggestionStyles.chip.count}>{count}</Typography>
          </Tooltip>
        </Box>
        <Box sx={styles.fourInfoText}>
          <Typography sx={suggestionStyles.chip.upText}>
            {
              // @ts-ignore
              sourceMap[source]
            }
          </Typography>
          <Typography sx={suggestionStyles.chip.downText}>
            {/* eslint-disable-next-line react/prop-types */}
            {ELASTIC_SEARCH_ATTRIBUTES_LABELS[source?.toLowerCase()][category]}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

const SearchSuggestions = () => {
  // @ts-ignore
  const { module, category, search, source, searchType } = useParams();
  const [suggestions, setSuggestions] = useState([]);
  const [aria, setAria] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const ariaStyle = style();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchSuggestions = async () => {
      const response = await getSearchSuggestions({
        source: source.toLowerCase(),
        category: category.toLowerCase(),
        searchText: decodeURIComponent(search as string)?.toLowerCase(),
        module: module.toLowerCase()
      });
      setLoading(false);
      if (response.data && response.data?.body) {
        setSuggestions(response.data.body);
        const localAria = response.data.body.filter(item => item.category === 'aria');
        if (localAria.length > 0) {
          setAria(localAria[0]);
        }
      }
    };
    if (pathname.startsWith('/search/advance/')) {
      setIsAdvancedSearch(true);
      setLoading(false);
    } else fetchSuggestions();
  }, []);

  return (
    <>
      <Box maxWidth={1000}>
        {loading ? (
          <Typography align='center' variant='subtitle1' fontStyle='italic'>
            No approved applications matched the search term:{' '}
            <Typography component='span' variant='subtitle1' sx={ariaStyle.searchText}>
              <b>
                {searchType === 'label' && decodeURIComponent(search as string)}
                {searchType === 'advance' && 'Advanced Search'}
              </b>
            </Typography>
            . <br /> Searching for results from alternative sources.
          </Typography>
        ) : suggestions.length > 0 ? (
          <Typography align='center' variant='subtitle1' fontStyle='italic'>
            No approved applications matched the search term:{' '}
            <Typography component='span' variant='subtitle1' sx={ariaStyle.searchText}>
              <b>{decodeURIComponent(search as string)}</b>
            </Typography>{' '}
            for the selected source and category. <br />
            Please check your spelling or select from the options below to execute a new search.
          </Typography>
        ) : isAdvancedSearch ? (
          <Typography align='center' variant='subtitle1' fontStyle='italic'>
            No approved applications matched the advanced search. <br /> Please check your spelling
            or try a new search.
          </Typography>
        ) : (
          <Typography align='center' variant='subtitle1' fontStyle='italic'>
            No approved applications matched the search term:{' '}
            <Typography component='span' variant='subtitle1' sx={ariaStyle.searchText}>
              <b>{decodeURIComponent(search as string)}</b>
            </Typography>
            . <br /> Please check your spelling or try a new search.
          </Typography>
        )}
      </Box>

      <Stack alignItems='center' direction='column' mt={5}>
        {aria && 'module_name' in aria && (
          <Stack
            justifyContent='center'
            alignItems='center'
            onClick={() => {
              savePathnameForAriaBackBtn(
                pathname,
                history,
                // @ts-ignore
                `/aria/${aria.module_name}/${
                  ariaMap[source.toLowerCase()]
                }?term=${search}&bucket=all`
              );
            }}>
            <Box sx={{ ...suggestionStyles.chip, height: '100% !important' }} pb={1} pt={1}>
              <Box width='100%' textAlign='center'>
                <Typography sx={suggestionStyles.chip.upText}>
                  {
                    // @ts-ignore
                    sourceMap[source.toLowerCase()]
                  }
                </Typography>
              </Box>
              <Box sx={suggestionStyles.chip.circle}>
                <Tooltip
                  title={
                    // @ts-ignore
                    aria.count
                  }>
                  <Typography sx={suggestionStyles.chip.count}>
                    {
                      // @ts-ignore
                      aria.count
                    }
                  </Typography>
                </Tooltip>
              </Box>
              <Box sx={styles.fourInfoText}>
                {
                  // @ts-ignore
                  <FeatureButton
                    iconId='aria'
                    text='Ask RIA'
                    handleClick={() => {
                      history.push(
                        `/aria/${aria.module_name}/${
                          ariaMap[source.toLowerCase()]
                        }?term=${search}&bucket=all`
                      );
                    }}
                    startIcon={<AriaLogoSmall />}
                  />
                }
              </Box>
            </Box>
            {suggestions.length > 1 && (
              <Box mt={2} mb={2}>
                <Divider variant='middle' sx={{ width: 500 }}>
                  <Typography variant='h3'>or</Typography>
                </Divider>
              </Box>
            )}
          </Stack>
        )}
        <Stack direction='row' flexWrap='wrap' justifyContent='center'>
          {loading && (
            <>
              <Skeleton
                sx={{ ...styles.eachInfo, ...suggestionStyles.chip, backgroundColor: 'grey' }}
                variant='rectangular'
              />
              <Skeleton
                sx={{ ...styles.eachInfo, ...suggestionStyles.chip, backgroundColor: 'grey' }}
                variant='rectangular'
              />
              <Skeleton
                sx={{ ...styles.eachInfo, ...suggestionStyles.chip, backgroundColor: 'grey' }}
                variant='rectangular'
              />
              <Skeleton
                sx={{ ...styles.eachInfo, ...suggestionStyles.chip, backgroundColor: 'grey' }}
                variant='rectangular'
              />
            </>
          )}
          {suggestions.map(({ source: sourceNew, category: categoryNew, count }, index) => {
            return (
              <SuggestionChips
                // eslint-disable-next-line react/no-array-index-key
                key={`suggestion-${index}`}
                source={sourceNew}
                category={categoryNew}
                count={count}
              />
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};
export default SearchSuggestions;
