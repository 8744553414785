import { createSvgIcon } from '@mui/material';

const BraFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_8986_2517)'>
      <path d='M0 0H18V12H0V0Z' fill='#009B3A' />
      <path
        d='M1.5293 6.00001L8.9993 10.5429L16.4693 6.00001L8.9993 1.45715L1.5293 6.00001Z'
        fill='#FEDF00'
      />
      <path
        d='M8.99961 9C10.7393 9 12.1496 7.65685 12.1496 6C12.1496 4.34315 10.7393 3 8.99961 3C7.25991 3 5.84961 4.34315 5.84961 6C5.84961 7.65685 7.25991 9 8.99961 9Z'
        fill='#002776'
      />
      <mask id='mask0_8986_2517' maskUnits='userSpaceOnUse' x='5' y='3' width='8' height='6'>
        <path
          d='M8.99961 9C10.7393 9 12.1496 7.65685 12.1496 6C12.1496 4.34315 10.7393 3 8.99961 3C7.25991 3 5.84961 4.34315 5.84961 6C5.84961 7.65685 7.25991 9 8.99961 9Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_8986_2517)'>
        <path
          d='M-0.451172 11.9999C-0.451172 10.0677 0.354808 8.21451 1.78946 6.84817C3.22412 5.48183 5.16992 4.71423 7.19883 4.71423C9.22773 4.71423 11.1735 5.48183 12.6082 6.84817C14.0428 8.21451 14.8488 10.0677 14.8488 11.9999H14.3988C14.3988 10.1813 13.6403 8.43718 12.29 7.15122C10.9397 5.86525 9.10839 5.1428 7.19883 5.1428C5.28927 5.1428 3.45792 5.86525 2.10766 7.15122C0.757397 8.43718 -0.00117186 10.1813 -0.00117186 11.9999H-0.451172Z'
          fill='white'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.31313 5.12302C6.34867 5.11886 6.38083 5.09994 6.40253 5.07041C6.42423 5.04088 6.43369 5.00316 6.42885 4.96556C6.424 4.92795 6.40523 4.89354 6.37667 4.86988C6.34812 4.84623 6.31211 4.83528 6.27657 4.83943C6.24103 4.84359 6.20888 4.86251 6.18718 4.89204C6.16548 4.92157 6.15601 4.95929 6.16086 4.99689C6.16571 5.0345 6.18447 5.06892 6.21303 5.09257C6.24159 5.11622 6.2776 5.12717 6.31313 5.12302ZM6.30634 5.07035C6.32721 5.06791 6.34602 5.05618 6.35862 5.03774C6.37123 5.0193 6.37659 4.99566 6.37355 4.97202C6.3705 4.94839 6.35929 4.92669 6.34237 4.9117C6.32546 4.89671 6.30423 4.88966 6.28336 4.8921C6.26249 4.89454 6.24368 4.90627 6.23108 4.92471C6.21848 4.94315 6.21311 4.96679 6.21616 4.99043C6.21921 5.01407 6.23042 5.03577 6.24733 5.05076C6.26424 5.06574 6.28547 5.07279 6.30634 5.07035Z'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.67991 5.09722L6.71034 5.07016L6.6573 5.00635L6.72284 4.94806C6.7365 4.93591 6.74487 4.91857 6.74611 4.89986C6.74735 4.88114 6.74136 4.86258 6.72946 4.84826C6.71756 4.83394 6.70072 4.82503 6.68264 4.82349C6.66457 4.82196 6.64674 4.82792 6.63308 4.84006L6.53945 4.92333L6.67991 5.09722ZM6.63078 4.97444L6.69398 4.91823C6.69957 4.91326 6.70299 4.90617 6.7035 4.89851C6.70401 4.89086 6.70156 4.88326 6.69669 4.87741C6.69182 4.87155 6.68493 4.8679 6.67754 4.86727C6.67014 4.86665 6.66285 4.86908 6.65726 4.87405L6.59406 4.93026L6.63078 4.97444Z'
        fill='#009B3A'
      />
      <path
        d='M6.81118 5.0239C6.79063 5.00079 6.74544 4.94996 6.70609 4.97909L6.65625 5.01599C6.71396 4.97326 6.75915 5.0241 6.7797 5.0472'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.93688 5.07473L7.07828 5.07238C7.09517 5.0721 7.11182 5.06866 7.12731 5.06225C7.14279 5.05583 7.1568 5.04658 7.16853 5.03501C7.18025 5.02345 7.18948 5.00979 7.19566 4.99483C7.20185 4.97987 7.20489 4.96389 7.20459 4.94782L7.20384 4.90701C7.20355 4.89093 7.19993 4.87506 7.1932 4.86032C7.18647 4.84557 7.17675 4.83223 7.1646 4.82106C7.15246 4.80989 7.13812 4.80111 7.12241 4.79521C7.1067 4.78932 7.08993 4.78643 7.07305 4.78671L6.93164 4.78906L6.93688 5.07473ZM6.99161 5.02075L7.07303 5.0194C7.09462 5.01904 7.11518 5.01053 7.13018 4.99573C7.14518 4.98094 7.1534 4.96107 7.15302 4.94051L7.15257 4.91602C7.1522 4.89546 7.14326 4.87588 7.12772 4.86159C7.11219 4.8473 7.09133 4.83948 7.06973 4.83984L6.98832 4.84119L6.99161 5.02075Z'
        fill='#009B3A'
      />
      <path
        d='M7.31961 5.07118L7.58944 5.08016L7.59139 5.02713L7.37295 5.01987L7.37564 4.94644L7.54697 4.95214L7.54876 4.90319L7.37744 4.89749L7.37953 4.84038L7.58512 4.84722L7.58706 4.79419L7.33008 4.78564L7.31961 5.07118Z'
        fill='#009B3A'
      />
      <path
        d='M7.69846 5.08651L7.7497 5.09078L7.76762 4.8956L7.80947 5.09576L7.85643 5.09967L7.93413 4.90948L7.9162 5.10465L7.96744 5.10892L7.99358 4.82429L7.91887 4.81807L7.84117 5.00826L7.79932 4.80811L7.72461 4.80188L7.69846 5.08651Z'
        fill='#009B3A'
      />
      <path
        d='M8.32146 5.15333L8.54321 5.18962L8.55192 5.14135L8.38086 5.11335L8.39247 5.04899L8.53186 5.0718L8.54057 5.02353L8.40118 5.00072L8.40916 4.95647L8.57495 4.9836L8.58366 4.93533L8.36719 4.8999L8.32146 5.15333Z'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.88973 5.25989L8.94367 5.27318L8.97157 5.17043L9.08775 5.19905C9.11196 5.20501 9.13766 5.20157 9.15921 5.18948C9.18075 5.1774 9.19638 5.15765 9.20264 5.1346C9.2089 5.11154 9.20528 5.08706 9.19259 5.06654C9.1799 5.04602 9.15917 5.03114 9.13496 5.02518L8.96899 4.9843L8.88973 5.25989ZM8.98552 5.11906L9.09755 5.14666C9.10745 5.14909 9.11797 5.14769 9.12678 5.14274C9.1356 5.1378 9.14199 5.12972 9.14455 5.12029C9.14711 5.11086 9.14563 5.10084 9.14044 5.09245C9.13525 5.08405 9.12677 5.07797 9.11686 5.07553L9.00483 5.04793L8.98552 5.11906Z'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.25859 5.30908L9.30229 5.33419L9.35278 5.24945L9.4469 5.30352C9.46652 5.31479 9.48983 5.31804 9.51171 5.31256C9.53359 5.30708 9.55225 5.29332 9.56358 5.2743C9.57491 5.25529 9.57799 5.23257 9.57213 5.21116C9.56627 5.18974 9.55197 5.17138 9.53235 5.16011L9.39789 5.08286L9.25859 5.30908ZM9.37803 5.20708L9.46879 5.25922C9.47681 5.26383 9.48635 5.26516 9.4953 5.26292C9.50425 5.26068 9.51188 5.25505 9.51652 5.24727C9.52115 5.23949 9.52241 5.23019 9.52002 5.22143C9.51762 5.21267 9.51177 5.20516 9.50374 5.20055L9.41298 5.14841L9.37803 5.20708Z'
        fill='#009B3A'
      />
      <path
        d='M9.48076 5.42643C9.50033 5.39439 9.54337 5.32391 9.4915 5.29577L9.42578 5.26013C9.50187 5.3014 9.45882 5.37188 9.43925 5.40392'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.74795 5.51016C9.78149 5.5221 9.81919 5.51945 9.85275 5.5028C9.88632 5.48615 9.913 5.45686 9.92693 5.42137C9.94087 5.38588 9.9409 5.34711 9.92704 5.31357C9.91318 5.28003 9.88655 5.25448 9.85301 5.24254C9.81948 5.23059 9.78178 5.23324 9.74821 5.24989C9.71465 5.26654 9.68796 5.29583 9.67403 5.33132C9.6601 5.36681 9.66006 5.40559 9.67392 5.43913C9.68779 5.47266 9.71441 5.49822 9.74795 5.51016ZM9.76746 5.46046C9.78716 5.46747 9.80953 5.46533 9.82965 5.45452C9.84977 5.44371 9.86599 5.4251 9.87475 5.40279C9.8835 5.38048 9.88408 5.3563 9.87634 5.33557C9.86861 5.31484 9.8532 5.29925 9.8335 5.29224C9.81381 5.28522 9.79144 5.28736 9.77132 5.29817C9.7512 5.30899 9.73497 5.3276 9.72622 5.3499C9.71746 5.37221 9.71689 5.39639 9.72462 5.41712C9.73236 5.43785 9.74777 5.45344 9.76746 5.46046Z'
        fill='#009B3A'
      />
      <mask id='mask1_8986_2517' maskUnits='userSpaceOnUse' x='9' y='5' width='2' height='1'>
        <path
          d='M9.97671 5.5696L10.1289 5.33276L10.3439 5.46048L10.1917 5.69732L9.97671 5.5696ZM10.1864 5.47444L10.1603 5.51504L10.2678 5.5789L10.2939 5.5383L10.1864 5.47444Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask1_8986_2517)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.0836 5.63417C10.1123 5.65091 10.1477 5.65443 10.1822 5.64393C10.2166 5.63343 10.2472 5.60979 10.2673 5.57819C10.2873 5.5466 10.2952 5.50964 10.2891 5.47546C10.2831 5.44128 10.2636 5.41266 10.2349 5.39591C10.2063 5.37917 10.1709 5.37565 10.1364 5.38615C10.102 5.39665 10.0714 5.42029 10.0513 5.45189C10.0313 5.48348 10.0234 5.52044 10.0295 5.55462C10.0355 5.5888 10.055 5.61742 10.0836 5.63417ZM10.1117 5.58992C10.1286 5.59976 10.1497 5.6013 10.1705 5.59421C10.1913 5.58713 10.2101 5.57199 10.2227 5.55213C10.2353 5.53227 10.2407 5.50932 10.2377 5.48832C10.2348 5.46732 10.2237 5.45 10.2068 5.44016C10.19 5.43032 10.1689 5.42878 10.1481 5.43587C10.1273 5.44295 10.1085 5.45809 10.0959 5.47795C10.0833 5.49781 10.0779 5.52076 10.0808 5.54176C10.0838 5.56276 10.0949 5.58008 10.1117 5.58992Z'
          fill='#009B3A'
        />
      </g>
      <path
        d='M10.1836 5.52319L10.2845 5.57014L10.2643 5.60375L10.1633 5.55681L10.1836 5.52319Z'
        fill='#009B3A'
      />
      <path
        d='M10.252 5.55005L10.2854 5.57012L10.2198 5.69733L10.1863 5.67726L10.252 5.55005Z'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3244 5.72966L10.3709 5.75589L10.4267 5.66551L10.5268 5.722C10.5477 5.73377 10.5726 5.737 10.5962 5.73098C10.6198 5.72496 10.6401 5.71018 10.6527 5.6899C10.6652 5.66962 10.6689 5.64549 10.663 5.62282C10.6571 5.60016 10.6421 5.58081 10.6212 5.56904L10.4782 5.48835L10.3244 5.72966ZM10.4546 5.62032L10.5511 5.67479C10.5596 5.6796 10.5699 5.68092 10.5795 5.67846C10.5892 5.676 10.5975 5.66996 10.6026 5.66166C10.6077 5.65336 10.6093 5.64349 10.6068 5.63422C10.6044 5.62494 10.5983 5.61703 10.5897 5.61222L10.4932 5.55775L10.4546 5.62032Z'
        fill='#009B3A'
      />
      <path
        d='M10.5458 5.85044C10.5674 5.81583 10.6148 5.73969 10.5607 5.70966L10.4922 5.67163C10.5715 5.71567 10.5241 5.79181 10.5025 5.82642'
        fill='#009B3A'
      />
      <path
        d='M10.6648 5.90639L10.8998 6.03301L10.9272 5.98683L10.737 5.88432L10.775 5.82038L10.9242 5.90078L10.9495 5.85815L10.8003 5.77775L10.8298 5.72802L11.0089 5.82449L11.0363 5.77831L10.8125 5.65771L10.6648 5.90639Z'
        fill='#009B3A'
      />
      <path
        d='M11.1026 6.04624C11.0865 6.07033 11.1029 6.09719 11.139 6.11912C11.1751 6.14105 11.1998 6.14025 11.2102 6.12479C11.243 6.07573 11.0533 6.00419 11.1117 5.91867C11.1622 5.84521 11.2484 5.90238 11.2809 5.92212C11.3135 5.94186 11.3657 5.99291 11.3251 6.05174L11.27 6.0183C11.2872 5.99248 11.2683 5.96766 11.2393 5.95012C11.2113 5.93312 11.1886 5.92536 11.1719 5.95032C11.1439 5.99022 11.3298 6.06552 11.2728 6.15072C11.2308 6.21354 11.1577 6.18612 11.1089 6.15651C11.0673 6.13129 11.0052 6.07183 11.0457 6.0117L11.1026 6.04624Z'
        fill='#009B3A'
      />
      <path
        d='M11.4238 6.24886C11.4063 6.27212 11.4212 6.29975 11.4561 6.32345C11.491 6.34715 11.5156 6.34759 11.5268 6.33266C11.5623 6.28531 11.3768 6.2044 11.4399 6.12192C11.4942 6.05107 11.5772 6.11247 11.6086 6.1338C11.64 6.15513 11.6894 6.20872 11.6456 6.26544L11.5924 6.2293C11.6111 6.20438 11.5935 6.17865 11.5656 6.15968C11.5385 6.14132 11.5162 6.13243 11.4982 6.15652C11.4681 6.19498 11.6496 6.27944 11.588 6.36168C11.5426 6.42232 11.4711 6.39129 11.424 6.35929C11.3838 6.33204 11.3251 6.26956 11.3688 6.21153L11.4238 6.24886Z'
        fill='#009B3A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7294 6.57757C11.7575 6.59879 11.7942 6.60737 11.8315 6.60142C11.8688 6.59546 11.9037 6.57545 11.9285 6.5458C11.9532 6.51615 11.9659 6.47928 11.9636 6.4433C11.9613 6.40733 11.9442 6.37519 11.9162 6.35396C11.8882 6.33274 11.8515 6.32416 11.8141 6.33011C11.7768 6.33607 11.7419 6.35608 11.7172 6.38573C11.6924 6.41538 11.6798 6.45225 11.6821 6.48822C11.6844 6.5242 11.7014 6.55634 11.7294 6.57757ZM11.7641 6.53604C11.7806 6.54851 11.8026 6.55306 11.8252 6.54869C11.8478 6.54433 11.8693 6.53141 11.8849 6.51277C11.9004 6.49413 11.9088 6.47131 11.9082 6.44931C11.9076 6.42732 11.898 6.40796 11.8815 6.39549C11.8651 6.38302 11.8431 6.37847 11.8204 6.38283C11.7978 6.3872 11.7763 6.40012 11.7608 6.41876C11.7452 6.4374 11.7368 6.46022 11.7374 6.48222C11.7381 6.50421 11.7477 6.52357 11.7641 6.53604Z'
        fill='#009B3A'
      />
      <path d='M6.42773 5.33264L6.38602 5.45492L6.45021 5.47479' fill='white' />
      <path d='M6.42773 5.33264L6.46945 5.45492L6.40526 5.47479' fill='white' />
      <path d='M6.55078 5.42151L6.42189 5.43435L6.428 5.48579' fill='white' />
      <path d='M6.55664 5.40662L6.46604 5.49699L6.40775 5.45979' fill='white' />
      <path d='M6.29883 5.40662L6.38943 5.49699L6.44772 5.45979' fill='white' />
      <path d='M6.30664 5.42139L6.43553 5.43423L6.42942 5.48567' fill='white' />
      <path d='M6.50781 5.56641L6.46654 5.44285L6.4019 5.46144' fill='white' />
      <path d='M6.50977 5.56519L6.39848 5.49077L6.43609 5.4376' fill='white' />
      <path d='M6.34766 5.56519L6.45894 5.49077L6.42134 5.4376' fill='white' />
      <path d='M6.34961 5.56653L6.39088 5.44297L6.45552 5.46156' fill='white' />
      <path d='M6.70703 6.59387L6.66531 6.71615L6.72951 6.73602' fill='white' />
      <path d='M6.70703 6.59387L6.74875 6.71615L6.68455 6.73602' fill='white' />
      <path d='M6.83008 6.68274L6.70119 6.69558L6.7073 6.74702' fill='white' />
      <path d='M6.83594 6.66785L6.74534 6.75822L6.68704 6.72102' fill='white' />
      <path d='M6.57812 6.66785L6.66872 6.75822L6.72702 6.72102' fill='white' />
      <path d='M6.58594 6.68262L6.71483 6.69546L6.70872 6.7469' fill='white' />
      <path d='M6.78711 6.82764L6.74583 6.70408L6.6812 6.72267' fill='white' />
      <path d='M6.78906 6.82642L6.67778 6.752L6.71538 6.69884' fill='white' />
      <path d='M6.62695 6.82642L6.73824 6.752L6.70063 6.69884' fill='white' />
      <path d='M6.62891 6.82776L6.67018 6.7042L6.73482 6.72279' fill='white' />
      <path d='M6.32031 6.88464L6.28555 6.98654L6.33905 7.0031' fill='white' />
      <path d='M6.32031 6.88464L6.35508 6.98654L6.30158 7.0031' fill='white' />
      <path d='M6.42383 6.95862L6.31642 6.96932L6.32151 7.01219' fill='white' />
      <path d='M6.42773 6.94629L6.35224 7.0216L6.30366 6.9906' fill='white' />
      <path d='M6.21484 6.94629L6.29034 7.0216L6.33892 6.9906' fill='white' />
      <path d='M6.21875 6.95862L6.32616 6.96932L6.32107 7.01219' fill='white' />
      <path d='M6.38672 7.07947L6.35232 6.97651L6.29846 6.992' fill='white' />
      <path d='M6.38867 7.07849L6.29593 7.01648L6.32727 6.97217' fill='white' />
      <path d='M6.25391 7.07849L6.34664 7.01648L6.31531 6.97217' fill='white' />
      <path d='M6.25586 7.07959L6.29026 6.97663L6.34412 6.99212' fill='white' />
      <path d='M7.01758 6.47754L6.99771 6.53577L7.02828 6.54523' fill='white' />
      <path d='M7.01758 6.47754L7.03744 6.53577L7.00687 6.54523' fill='white' />
      <path d='M7.07422 6.5199L7.01284 6.52601L7.01575 6.55051' fill='white' />
      <path d='M7.07617 6.51282L7.03303 6.55585L7.00527 6.53814' fill='white' />
      <path d='M6.95508 6.51282L6.99822 6.55585L7.02598 6.53814' fill='white' />
      <path d='M6.95703 6.5199L7.01841 6.52601L7.0155 6.55051' fill='white' />
      <path d='M7.05469 6.58887L7.03503 6.53003L7.00425 6.53888' fill='white' />
      <path d='M7.05664 6.58826L7.00365 6.55282L7.02156 6.5275' fill='white' />
      <path d='M6.97656 6.58826L7.02956 6.55282L7.01165 6.5275' fill='white' />
      <path d='M6.97656 6.58887L6.99622 6.53003L7.027 6.53888' fill='white' />
      <path d='M7.36133 6.91321L7.32656 7.01511L7.38006 7.03166' fill='white' />
      <path d='M7.36133 6.91321L7.39609 7.01511L7.3426 7.03166' fill='white' />
      <path d='M7.46484 6.98718L7.35744 6.99788L7.36253 7.04075' fill='white' />
      <path d='M7.46875 6.97485L7.39325 7.05017L7.34467 7.01916' fill='white' />
      <path d='M7.25586 6.97485L7.33136 7.05017L7.37994 7.01916' fill='white' />
      <path d='M7.25977 6.98718L7.36717 6.99788L7.36208 7.04075' fill='white' />
      <path d='M7.42773 7.10803L7.39334 7.00507L7.33947 7.02056' fill='white' />
      <path d='M7.42969 7.10706L7.33695 7.04505L7.36829 7.00074' fill='white' />
      <path d='M7.29492 7.10706L7.38766 7.04505L7.35632 7.00074' fill='white' />
      <path d='M7.29688 7.10815L7.33127 7.00519L7.38514 7.02068' fill='white' />
      <path d='M7.26953 7.23267L7.24172 7.31419L7.28452 7.32743' fill='white' />
      <path d='M7.26953 7.23267L7.29734 7.31419L7.25455 7.32743' fill='white' />
      <path d='M7.35156 7.29187L7.26564 7.30043L7.26971 7.33473' fill='white' />
      <path d='M7.35547 7.28198L7.29507 7.34223L7.25621 7.31743' fill='white' />
      <path d='M7.18359 7.2821L7.24399 7.34235L7.28286 7.31755' fill='white' />
      <path d='M7.1875 7.29199L7.27343 7.30055L7.26935 7.33485' fill='white' />
      <path d='M7.32227 7.38855L7.29475 7.30618L7.25166 7.31857' fill='white' />
      <path d='M7.32422 7.3877L7.25003 7.33809L7.2751 7.30264' fill='white' />
      <path d='M7.21484 7.38782L7.28903 7.33821L7.26396 7.30276' fill='white' />
      <path d='M7.21484 7.38855L7.24236 7.30618L7.28545 7.31857' fill='white' />
      <path d='M9.97656 4.9408L9.93485 5.06307L9.99904 5.08294' fill='white' />
      <path d='M9.97656 4.9408L10.0183 5.06307L9.95408 5.08294' fill='white' />
      <path d='M10.0996 5.02966L9.97072 5.04251L9.97683 5.09395' fill='white' />
      <path d='M10.1055 5.01477L10.0149 5.10515L9.95658 5.06794' fill='white' />
      <path d='M9.84766 5.01477L9.93825 5.10515L9.99655 5.06794' fill='white' />
      <path d='M9.85547 5.02954L9.98436 5.04238L9.97825 5.09383' fill='white' />
      <path d='M10.0566 5.17456L10.0154 5.05101L9.95073 5.0696' fill='white' />
      <path d='M10.0586 5.17334L9.94731 5.09893L9.98491 5.04576' fill='white' />
      <path d='M9.89648 5.17334L10.0078 5.09893L9.97016 5.04576' fill='white' />
      <path d='M9.89844 5.17468L9.93971 5.05113L10.0044 5.06972' fill='white' />
      <path d='M11.207 6.92444L11.1653 7.04672L11.2295 7.06658' fill='white' />
      <path d='M11.207 6.92444L11.2487 7.04672L11.1846 7.06658' fill='white' />
      <path d='M11.3301 7.01331L11.2012 7.02615L11.2073 7.07759' fill='white' />
      <path d='M11.3359 6.99841L11.2453 7.08879L11.187 7.05158' fill='white' />
      <path d='M11.0781 6.99841L11.1687 7.08879L11.227 7.05158' fill='white' />
      <path d='M11.0859 7.01318L11.2148 7.02603L11.2087 7.07747' fill='white' />
      <path d='M11.2871 7.1582L11.2458 7.03465L11.1812 7.05324' fill='white' />
      <path d='M11.2891 7.15698L11.1778 7.08257L11.2154 7.0294' fill='white' />
      <path d='M11.127 7.15698L11.2382 7.08257L11.2006 7.0294' fill='white' />
      <path d='M11.1289 7.15833L11.1702 7.03477L11.2348 7.05336' fill='white' />
      <path d='M11.6445 6.99597L11.6167 7.07749L11.6595 7.09073' fill='white' />
      <path d='M11.6445 6.99597L11.6723 7.07749L11.6295 7.09073' fill='white' />
      <path d='M11.7266 7.05518L11.6406 7.06374L11.6447 7.09803' fill='white' />
      <path d='M11.7305 7.04529L11.6701 7.10554L11.6312 7.08073' fill='white' />
      <path d='M11.5586 7.04541L11.619 7.10566L11.6579 7.08086' fill='white' />
      <path d='M11.5625 7.0553L11.6484 7.06386L11.6444 7.09815' fill='white' />
      <path d='M11.6973 7.15186L11.6697 7.06949L11.6267 7.08188' fill='white' />
      <path d='M11.6992 7.151L11.625 7.10139L11.6501 7.06595' fill='white' />
      <path d='M11.5898 7.15112L11.664 7.10152L11.639 7.06607' fill='white' />
      <path d='M11.5898 7.15186L11.6174 7.06949L11.6605 7.08188' fill='white' />
      <path d='M11.334 7.21118L11.2992 7.31308L11.3527 7.32964' fill='white' />
      <path d='M11.334 7.21118L11.3687 7.31308L11.3153 7.32964' fill='white' />
      <path d='M11.4375 7.28516L11.3301 7.29586L11.3352 7.33873' fill='white' />
      <path d='M11.4414 7.27283L11.3659 7.34814L11.3173 7.31713' fill='white' />
      <path d='M11.2285 7.27283L11.304 7.34814L11.3526 7.31713' fill='white' />
      <path d='M11.2324 7.28516L11.3398 7.29586L11.3347 7.33873' fill='white' />
      <path d='M11.4004 7.40601L11.366 7.30304L11.3121 7.31853' fill='white' />
      <path d='M11.4023 7.40503L11.3096 7.34302L11.3409 7.29871' fill='white' />
      <path d='M11.2676 7.40503L11.3603 7.34302L11.329 7.29871' fill='white' />
      <path d='M11.2695 7.40613L11.3039 7.30317L11.3578 7.31866' fill='white' />
      <path d='M10.5762 7.83984L10.5414 7.94174L10.5949 7.9583' fill='white' />
      <path d='M10.5762 7.83984L10.6109 7.94174L10.5574 7.9583' fill='white' />
      <path d='M10.6797 7.91382L10.5723 7.92452L10.5774 7.96739' fill='white' />
      <path d='M10.6836 7.90149L10.6081 7.9768L10.5595 7.9458' fill='white' />
      <path d='M10.4707 7.90149L10.5462 7.9768L10.5948 7.9458' fill='white' />
      <path d='M10.4746 7.91382L10.582 7.92452L10.5769 7.96739' fill='white' />
      <path d='M10.6426 8.03467L10.6082 7.93171L10.5543 7.9472' fill='white' />
      <path d='M10.6445 8.03369L10.5518 7.97168L10.5831 7.92737' fill='white' />
      <path d='M10.5098 8.03369L10.6025 7.97168L10.5712 7.92737' fill='white' />
      <path d='M10.5117 8.03479L10.5461 7.93183L10.6 7.94732' fill='white' />
      <path d='M10.5742 8.16321L10.5464 8.24473L10.5892 8.25797' fill='white' />
      <path d='M10.5742 8.16321L10.602 8.24473L10.5592 8.25797' fill='white' />
      <path d='M10.6562 8.22241L10.5703 8.23097L10.5744 8.26527' fill='white' />
      <path d='M10.6602 8.21252L10.5998 8.27277L10.5609 8.24797' fill='white' />
      <path d='M10.4883 8.21265L10.5487 8.2729L10.5875 8.24809' fill='white' />
      <path d='M10.4922 8.22253L10.5781 8.2311L10.574 8.26539' fill='white' />
      <path d='M10.627 8.31909L10.5994 8.23672L10.5563 8.24912' fill='white' />
      <path d='M10.6289 8.31824L10.5547 8.26863L10.5798 8.23318' fill='white' />
      <path d='M10.5195 8.31836L10.5937 8.26875L10.5687 8.23331' fill='white' />
      <path d='M10.5195 8.31909L10.547 8.23672L10.5901 8.24912' fill='white' />
      <path d='M10.8906 7.62451L10.8628 7.70603L10.9056 7.71927' fill='white' />
      <path d='M10.8906 7.62451L10.9184 7.70603L10.8756 7.71927' fill='white' />
      <path d='M10.9727 7.68372L10.8867 7.69228L10.8908 7.72657' fill='white' />
      <path d='M10.9766 7.67383L10.9162 7.73408L10.8773 7.70927' fill='white' />
      <path d='M10.8047 7.67395L10.8651 7.7342L10.9039 7.7094' fill='white' />
      <path d='M10.8086 7.68384L10.8945 7.6924L10.8904 7.7267' fill='white' />
      <path d='M10.9434 7.7804L10.9158 7.69803L10.8728 7.71042' fill='white' />
      <path d='M10.9453 7.77954L10.8711 7.72993L10.8962 7.69449' fill='white' />
      <path d='M10.8359 7.77966L10.9101 7.73006L10.8851 7.69461' fill='white' />
      <path d='M10.8359 7.7804L10.8635 7.69803L10.9065 7.71042' fill='white' />
      <path d='M11.1426 7.45203L11.1078 7.55393L11.1613 7.57048' fill='white' />
      <path d='M11.1426 7.45203L11.1773 7.55393L11.1238 7.57048' fill='white' />
      <path d='M11.2461 7.526L11.1387 7.5367L11.1438 7.57957' fill='white' />
      <path d='M11.25 7.51367L11.1745 7.58898L11.1259 7.55798' fill='white' />
      <path d='M11.0371 7.51367L11.1126 7.58898L11.1612 7.55798' fill='white' />
      <path d='M11.041 7.526L11.1484 7.5367L11.1433 7.57957' fill='white' />
      <path d='M11.209 7.64685L11.1746 7.54389L11.1207 7.55938' fill='white' />
      <path d='M11.2109 7.64587L11.1182 7.58386L11.1495 7.53956' fill='white' />
      <path d='M11.0762 7.64587L11.1689 7.58386L11.1376 7.53956' fill='white' />
      <path d='M11.0781 7.64697L11.1125 7.54401L11.1664 7.5595' fill='white' />
      <path d='M10.5645 7.56738L10.5366 7.6489L10.5794 7.66215' fill='white' />
      <path d='M10.5645 7.56738L10.5923 7.6489L10.5495 7.66215' fill='white' />
      <path d='M10.6465 7.62659L10.5606 7.63515L10.5646 7.66944' fill='white' />
      <path d='M10.6504 7.6167L10.59 7.67695L10.5511 7.65215' fill='white' />
      <path d='M10.4785 7.61682L10.5389 7.67707L10.5778 7.65227' fill='white' />
      <path d='M10.4824 7.62671L10.5683 7.63527L10.5643 7.66957' fill='white' />
      <path d='M10.6172 7.72327L10.5897 7.6409L10.5466 7.65329' fill='white' />
      <path d='M10.6191 7.72241L10.5449 7.6728L10.57 7.63736' fill='white' />
      <path d='M10.5098 7.72253L10.584 7.67293L10.5589 7.63748' fill='white' />
      <path d='M10.5098 7.72327L10.5373 7.6409L10.5804 7.65329' fill='white' />
      <path d='M7.79883 6.01526L7.76406 6.11716L7.81756 6.13371' fill='white' />
      <path d='M7.79883 6.01526L7.83359 6.11716L7.7801 6.13371' fill='white' />
      <path d='M7.90234 6.08923L7.79494 6.09994L7.80003 6.1428' fill='white' />
      <path d='M7.90625 6.0769L7.83075 6.15222L7.78217 6.12121' fill='white' />
      <path d='M7.69336 6.0769L7.76886 6.15222L7.81744 6.12121' fill='white' />
      <path d='M7.69727 6.08923L7.80467 6.09994L7.79958 6.1428' fill='white' />
      <path d='M7.86523 6.21008L7.83084 6.10712L7.77697 6.12261' fill='white' />
      <path d='M7.86719 6.20911L7.77445 6.1471L7.80579 6.10279' fill='white' />
      <path d='M7.73242 6.20911L7.82516 6.1471L7.79382 6.10279' fill='white' />
      <path d='M7.73438 6.21021L7.76877 6.10724L7.82264 6.12273' fill='white' />
      <path d='M9.85742 5.76331L9.82961 5.84482L9.87241 5.85807' fill='white' />
      <path d='M9.85742 5.76331L9.88523 5.84482L9.84244 5.85807' fill='white' />
      <path d='M9.93945 5.82251L9.85353 5.83107L9.8576 5.86537' fill='white' />
      <path d='M9.94336 5.81262L9.88296 5.87287L9.8441 5.84807' fill='white' />
      <path d='M9.77148 5.81274L9.83188 5.87299L9.87075 5.84819' fill='white' />
      <path d='M9.77539 5.82263L9.86132 5.83119L9.85724 5.86549' fill='white' />
      <path d='M9.91016 5.91919L9.88264 5.83682L9.83955 5.84921' fill='white' />
      <path d='M9.91211 5.91833L9.83792 5.86873L9.86299 5.83328' fill='white' />
      <path d='M9.80273 5.91846L9.87693 5.86885L9.85185 5.8334' fill='white' />
      <path d='M9.80273 5.91919L9.83025 5.83682L9.87334 5.84921' fill='white' />
      <path d='M9 7.21838L8.95828 7.34066L9.02248 7.36053' fill='white' />
      <path d='M9 7.21838L9.04172 7.34066L8.97752 7.36053' fill='white' />
      <path d='M9.12305 7.30725L8.99416 7.32009L9.00027 7.37154' fill='white' />
      <path d='M9.12891 7.29236L9.03831 7.38273L8.98001 7.34553' fill='white' />
      <path d='M8.87109 7.29236L8.96169 7.38273L9.01999 7.34553' fill='white' />
      <path d='M8.87891 7.30713L9.0078 7.31997L9.00169 7.37141' fill='white' />
      <path d='M9.08008 7.45215L9.0388 7.32859L8.97416 7.34718' fill='white' />
      <path d='M9.08203 7.45093L8.97075 7.37652L9.00835 7.32335' fill='white' />
      <path d='M8.91992 7.45093L9.03121 7.37652L8.9936 7.32335' fill='white' />
      <path d='M8.92188 7.45227L8.96315 7.32872L9.02779 7.34731' fill='white' />
      <path d='M9.36328 6.64392L9.32852 6.74582L9.38201 6.76237' fill='white' />
      <path d='M9.36328 6.64392L9.39805 6.74582L9.34455 6.76237' fill='white' />
      <path d='M9.4668 6.7179L9.35939 6.7286L9.36448 6.77147' fill='white' />
      <path d='M9.4707 6.70557L9.39521 6.78088L9.34663 6.74987' fill='white' />
      <path d='M9.25781 6.70557L9.33331 6.78088L9.38189 6.74987' fill='white' />
      <path d='M9.26172 6.7179L9.36913 6.7286L9.36404 6.77147' fill='white' />
      <path d='M9.42969 6.83875L9.39529 6.73578L9.34143 6.75127' fill='white' />
      <path d='M9.43164 6.83777L9.3389 6.77576L9.37024 6.73145' fill='white' />
      <path d='M9.29688 6.83777L9.38961 6.77576L9.35827 6.73145' fill='white' />
      <path d='M9.29883 6.83887L9.33322 6.73591L9.38709 6.7514' fill='white' />
      <path d='M8.99805 6.37451L8.96328 6.47641L9.01678 6.49297' fill='white' />
      <path d='M8.99805 6.37451L9.03281 6.47641L8.97931 6.49297' fill='white' />
      <path d='M9.10156 6.44849L8.99415 6.45919L8.99925 6.50206' fill='white' />
      <path d='M9.10547 6.43616L9.02997 6.51147L8.98139 6.48046' fill='white' />
      <path d='M8.89258 6.43616L8.96808 6.51147L9.01666 6.48046' fill='white' />
      <path d='M8.89648 6.44849L9.00389 6.45919L8.9988 6.50206' fill='white' />
      <path d='M9.06445 6.56934L9.03006 6.46637L8.97619 6.48187' fill='white' />
      <path d='M9.06641 6.56836L8.97367 6.50635L9.00501 6.46204' fill='white' />
      <path d='M8.93164 6.56836L9.02438 6.50635L8.99304 6.46204' fill='white' />
      <path d='M8.93359 6.56946L8.96799 6.4665L9.02186 6.48199' fill='white' />
      <path d='M8.68359 6.66528L8.65578 6.7468L8.69858 6.76005' fill='white' />
      <path d='M8.68359 6.66528L8.71141 6.7468L8.66861 6.76005' fill='white' />
      <path d='M8.76562 6.72449L8.6797 6.73305L8.68377 6.76734' fill='white' />
      <path d='M8.76953 6.7146L8.70913 6.77485L8.67027 6.75005' fill='white' />
      <path d='M8.59766 6.71472L8.65805 6.77497L8.69692 6.75017' fill='white' />
      <path d='M8.60156 6.72461L8.68749 6.73317L8.68342 6.76747' fill='white' />
      <path d='M8.73633 6.82117L8.70881 6.7388L8.66572 6.75119' fill='white' />
      <path d='M8.73828 6.82031L8.66409 6.7707L8.68916 6.73526' fill='white' />
      <path d='M8.62891 6.82043L8.7031 6.77083L8.67803 6.73538' fill='white' />
      <path d='M8.62891 6.82117L8.65642 6.7388L8.69952 6.75119' fill='white' />
      <path d='M8.84375 6.89795L8.82388 6.95618L8.85445 6.96564' fill='white' />
      <path d='M8.84375 6.89795L8.86362 6.95618L8.83305 6.96564' fill='white' />
      <path d='M8.90039 6.94031L8.83901 6.94642L8.84192 6.97092' fill='white' />
      <path d='M8.90234 6.93323L8.8592 6.97626L8.83144 6.95855' fill='white' />
      <path d='M8.78125 6.93323L8.82439 6.97626L8.85215 6.95855' fill='white' />
      <path d='M8.7832 6.94031L8.84458 6.94642L8.84167 6.97092' fill='white' />
      <path d='M8.88086 7.00928L8.8612 6.95044L8.83042 6.95929' fill='white' />
      <path d='M8.88281 7.00867L8.82982 6.97323L8.84773 6.94791' fill='white' />
      <path d='M8.80273 7.00867L8.85573 6.97323L8.83782 6.94791' fill='white' />
      <path d='M8.80273 7.00928L8.82239 6.95044L8.85317 6.95929' fill='white' />
      <path d='M9.94141 7.91321L9.90664 8.01511L9.96014 8.03166' fill='white' />
      <path d='M9.94141 7.91321L9.97617 8.01511L9.92267 8.03166' fill='white' />
      <path d='M10.0449 7.98718L9.93751 7.99788L9.94261 8.04075' fill='white' />
      <path d='M10.0488 7.97485L9.97333 8.05017L9.92475 8.01916' fill='white' />
      <path d='M9.83594 7.97485L9.91144 8.05017L9.96001 8.01916' fill='white' />
      <path d='M9.83984 7.98718L9.94725 7.99788L9.94216 8.04075' fill='white' />
      <path d='M10.0078 8.10803L9.97342 8.00507L9.91955 8.02056' fill='white' />
      <path d='M10.0098 8.10706L9.91703 8.04505L9.94837 8.00074' fill='white' />
      <path d='M9.875 8.10706L9.96774 8.04505L9.9364 8.00074' fill='white' />
      <path d='M9.87695 8.10815L9.91135 8.00519L9.96521 8.02068' fill='white' />
      <path d='M10.2129 7.66943L10.1851 7.75095L10.2279 7.7642' fill='white' />
      <path d='M10.2129 7.66943L10.2407 7.75095L10.1979 7.7642' fill='white' />
      <path d='M10.2949 7.72864L10.209 7.7372L10.2131 7.77149' fill='white' />
      <path d='M10.2988 7.71875L10.2384 7.779L10.1996 7.7542' fill='white' />
      <path d='M10.127 7.71887L10.1874 7.77912L10.2262 7.75432' fill='white' />
      <path d='M10.1309 7.72876L10.2168 7.73732L10.2127 7.77162' fill='white' />
      <path d='M10.2656 7.82532L10.2381 7.74295L10.195 7.75534' fill='white' />
      <path d='M10.2676 7.82446L10.1934 7.77485L10.2185 7.73941' fill='white' />
      <path d='M10.1582 7.82458L10.2324 7.77498L10.2073 7.73953' fill='white' />
      <path d='M10.1582 7.82532L10.1857 7.74295L10.2288 7.75534' fill='white' />
      <path d='M9.69531 7.59595L9.6675 7.67747L9.7103 7.69071' fill='white' />
      <path d='M9.69531 7.59595L9.72312 7.67747L9.68033 7.69071' fill='white' />
      <path d='M9.77734 7.65515L9.69142 7.66371L9.69549 7.69801' fill='white' />
      <path d='M9.78125 7.64526L9.72085 7.70551L9.68199 7.68071' fill='white' />
      <path d='M9.60938 7.64539L9.66977 7.70564L9.70864 7.68083' fill='white' />
      <path d='M9.61328 7.65527L9.69921 7.66384L9.69513 7.69813' fill='white' />
      <path d='M9.74805 7.75183L9.72053 7.66946L9.67744 7.68185' fill='white' />
      <path d='M9.75 7.75098L9.67581 7.70137L9.70088 7.66592' fill='white' />
      <path d='M9.64062 7.7511L9.71482 7.70149L9.68974 7.66604' fill='white' />
      <path d='M9.64062 7.75183L9.66814 7.66946L9.71123 7.68185' fill='white' />
      <path d='M7.73438 7.46326L7.69266 7.58554L7.75685 7.6054' fill='white' />
      <path d='M7.73438 7.46326L7.77609 7.58554L7.7119 7.6054' fill='white' />
      <path d='M7.85742 7.55212L7.72853 7.56497L7.73464 7.61641' fill='white' />
      <path d='M7.86328 7.53723L7.77268 7.62761L7.71439 7.5904' fill='white' />
      <path d='M7.60547 7.53723L7.69607 7.62761L7.75436 7.5904' fill='white' />
      <path d='M7.61328 7.552L7.74217 7.56484L7.73606 7.61629' fill='white' />
      <path d='M7.81445 7.69702L7.77318 7.57347L7.70854 7.59206' fill='white' />
      <path d='M7.81641 7.6958L7.70512 7.62139L7.74273 7.56822' fill='white' />
      <path d='M7.6543 7.6958L7.76558 7.62139L7.72798 7.56822' fill='white' />
      <path d='M7.65625 7.69714L7.69753 7.57359L7.76216 7.59218' fill='white' />
      <path d='M9 8.30408L8.98609 8.34484L9.00749 8.35146' fill='white' />
      <path d='M9 8.30408L9.01391 8.34484L8.99251 8.35146' fill='white' />
      <path d='M9.04102 8.33374L8.99805 8.33802L9.00009 8.35517' fill='white' />
      <path d='M9.04297 8.32874L9.01277 8.35886L8.99334 8.34646' fill='white' />
      <path d='M8.95703 8.32874L8.98723 8.35886L9.00666 8.34646' fill='white' />
      <path d='M8.96094 8.33374L9.0039 8.33802L9.00186 8.35517' fill='white' />
      <path d='M9.02734 8.38196L9.01359 8.34077L8.99204 8.34697' fill='white' />
      <path d='M9.02734 8.38159L8.99025 8.35679L9.00278 8.33906' fill='white' />
      <path d='M8.97461 8.38159L9.0117 8.35679L8.99917 8.33906' fill='white' />
      <path d='M8.97461 8.38196L8.98837 8.34077L9.00991 8.34697' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_8986_2517'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'BraFlagIcon'
);

export default BraFlagIcon;
