export const ELEMENT_TYPE = {
  PARAGRAPH: 'paragraph',
  PARAGRAPH_2: 'paragraph-2',
  HEADING: 'heading',
  UL: 'unordered-list',
  UL_2: 'unordered-list-2',
  HR: 'horizontal-rule',
  HEADER_1: 'header-1',
  HEADER_2: 'header-2',
  SPACE: 'space',
  REFERENCE_PARAGRAPH: 'reference-paragraph',
  POLL: 'poll',
  DOCUMENT_CARD: 'document-card',
  DATA_TABLE: 'data-table',
  NORMAL_TABLE: 'normal-table',
  NOTES: 'notes'
};

export type ElementType = keyof typeof ELEMENT_TYPE;

export interface IDocumentCard {
  title: string;
  flag: string;
  tag: string;
  chip: {
    label: string;
    color: string;
    icon: string;
    borderColor: string;
  };
  metaData: Array<{
    key: string;
    tooltip: string;
    value: string;
  }>;
  content: ArticleContent[];
}

export interface IUnorderedList {
  id: number;
  type: ElementType;
  content: string;
  link?: string;
  referenceNumber?: number;
}

export interface INormalTable {
  headerRow: {
    id: string;
    label: string;
  }[];
  dataRows: {
    id: number;
    cells: {
      id: string;
      value: string;
    }[];
  }[];
}

export interface ArticleContent {
  id: number;
  type: ElementType;
  allowedInReport?: boolean;
  styles?: Record<string, unknown>;
  paragraph?: string;
  list?: IUnorderedList[];
  header1?: string;
  header2?: string;
  poll?: Poll;
  documentCard?: IDocumentCard;
  notes?: string;
  referenceParagraph?: string;
  link?: string;
  referenceNumber?: number;
  normalTable?: INormalTable;
}

export interface PollOption {
  id: number;
  labelPrefix: string;
  percentage: number;
  labelSuffix: string;
  color: string;
  icon: string;
}

export interface Poll {
  title: string;
  options: PollOption[];
}

export interface IStepsToRecreate {
  id: number;
  header: string;
  content: ArticleContent[];
}

export interface IHistory {
  id: number | string;
  dateModified: Date;
  changes: string;
  author: string;
  view: string;
  changeType?: string;
}

export interface Article {
  id: string;
  title: string;
  rawDescription: string;
  image: string;
  addedOn: Date;
  updatedOn: Date;
  author: string;
  mainContent: ArticleContent[];
  stepsToRecreate: IStepsToRecreate[];
  history: IHistory[];
}
