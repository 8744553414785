import React from 'react';

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  IconButton,
  Divider
} from '@mui/material';

import componentStyles from '../styles/Components.styles';

import { ELEMENT_TYPE, ArticleContent } from '../interface';

import UnorderedList from './UnorderedList';
import Polls from './Polls';
import DocumentCard from './DocumentCard';
import NormalTable from './NormalTable';

const ElementRenderer = ({
  content,
  isReporting = false
}: {
  content: ArticleContent;
  isReporting?: boolean;
}) => {
  switch (content.type) {
    case ELEMENT_TYPE.PARAGRAPH:
      return (
        <Typography key={content.id} sx={componentStyles.paragraph}>
          {content.paragraph}
        </Typography>
      );
    case ELEMENT_TYPE.REFERENCE_PARAGRAPH:
      return (
        <Typography key={content.id} sx={componentStyles.paragraph}>
          {content.referenceParagraph}
          <IconButton sx={componentStyles.referenceNumber} size='small'>
            {content.referenceNumber}
          </IconButton>
        </Typography>
      );
    case ELEMENT_TYPE.HEADER_1:
      return (
        <Typography key={content.id} sx={componentStyles.header1}>
          {content.header1}
        </Typography>
      );
    case ELEMENT_TYPE.HEADER_2:
      return (
        <Typography key={content.id} sx={componentStyles.header2}>
          {content.header2}
        </Typography>
      );
    case ELEMENT_TYPE.UL:
      return <UnorderedList key={content.id} list={content.list} />;
    case ELEMENT_TYPE.UL_2:
      return (
        <List key={content.id} sx={componentStyles.ul2.list}>
          {content?.list?.map(item => {
            return (
              <ListItem key={item.id} sx={componentStyles.ul.listItem}>
                <ListItemText
                  primary={
                    <Typography sx={componentStyles.ul2.listItemText}>{item.content}</Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      );
    case ELEMENT_TYPE.SPACE:
      return <Stack key={content.id} sx={content.styles} />;
    case ELEMENT_TYPE.HR:
      return <Divider key={content.id} sx={content.styles} />;
    case ELEMENT_TYPE.POLL:
      return <Polls key={content.id} poll={content.poll} />;
    case ELEMENT_TYPE.DOCUMENT_CARD:
      return (
        <DocumentCard key={content.id} document={content.documentCard} isReporting={isReporting} />
      );
    case ELEMENT_TYPE.NORMAL_TABLE:
      return <NormalTable key={content.id} table={content.normalTable} />;
    case ELEMENT_TYPE.NOTES:
      return (
        <Stack key={content.id} direction='row' sx={componentStyles.notes.container}>
          <Typography sx={componentStyles.notes.text}>Notes:</Typography>
          <Typography sx={componentStyles.notes.content}>{content.notes}</Typography>
        </Stack>
      );
    default:
      return <Stack />;
  }
};

ElementRenderer.defaultProps = {
  isReporting: false
};

export default React.memo(ElementRenderer);
