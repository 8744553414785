import { createSvgIcon } from '@mui/material';

const SgpFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_8986_2032)'>
      <path d='M0 0H18V12H0V0Z' fill='white' />
      <path d='M0 0H18V6H0V0Z' fill='#ED2939' />
      <path
        d='M3.80208 5.20829C5.02171 5.20829 6.01042 4.21959 6.01042 2.99996C6.01042 1.78033 5.02171 0.791626 3.80208 0.791626C2.58245 0.791626 1.59375 1.78033 1.59375 2.99996C1.59375 4.21959 2.58245 5.20829 3.80208 5.20829Z'
        fill='white'
      />
      <path
        d='M4.80208 5.20829C6.02171 5.20829 7.01042 4.21959 7.01042 2.99996C7.01042 1.78033 6.02171 0.791626 4.80208 0.791626C3.58245 0.791626 2.59375 1.78033 2.59375 2.99996C2.59375 4.21959 3.58245 5.20829 4.80208 5.20829Z'
        fill='#ED2939'
      />
      <path
        d='M5.00989 1.15833L4.67189 2.19833L5.55689 1.55566H4.46289L5.34789 2.19866L5.00989 1.15833Z'
        fill='white'
      />
      <path
        d='M6.76083 2.43087L5.66728 2.43079L6.55197 3.07388L6.21391 2.03343L5.87586 3.07381L6.76083 2.43087Z'
        fill='white'
      />
      <path
        d='M3.92661 4.48988L4.81135 3.84718L3.71762 3.84692L4.60269 4.48995L4.26465 3.44956L3.92661 4.48988Z'
        fill='white'
      />
      <path
        d='M6.20633 4.38649L5.57089 3.48947L5.5571 4.56919L6.21476 3.71907L5.16424 4.01457L6.20633 4.38649Z'
        fill='white'
      />
      <path
        d='M3.16982 2.59302L4.3861 3.01012L3.53538 1.91239L3.69575 3.27151L4.28738 2.17029L3.16982 2.59302Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_8986_2032'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'SgpFlagIcon'
);

export default SgpFlagIcon;
