const getFiltersOptions = (meetingFilters: any, apiData: any) => {
  const filters = meetingFilters.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};

export const formatStringWithSpaces = (input: string | null | undefined): string => {
  if (!input || input.trim().toUpperCase() === 'NA') {
    return input ?? ''; // Return input as-is if it's `null`, `undefined`, or `"NA"`
  }

  return input
    .split(',')
    .map(item => item.trim())
    .join(', ');
};

export default getFiltersOptions;
