import { ELEMENT_TYPE, Article, ElementType } from '../interface';

const ARTICLE_3_TABLE = {
  headerRow: [
    { id: 'aspect', label: 'Aspect' },
    { id: 'nda', label: 'NDA' },
    { id: 'bla', label: 'BLA' }
  ],
  dataRows: [
    {
      id: 1,
      cells: [
        { id: 'aspect-1', value: 'Application type' },
        { id: 'nda-1', value: 'New Drug Application' },
        { id: 'bla-1', value: '1976' }
      ]
    },
    {
      id: 2,
      cells: [
        { id: 'aspect-2', value: 'Applicant information' },
        { id: 'nda-2', value: 'Required' },
        { id: 'bla-2', value: '1976' }
      ]
    },
    {
      id: 3,
      cells: [
        { id: 'aspect-3', value: 'Manufacturing details' },
        { id: 'nda-3', value: 'Detailed manufacturing process and quality control' },
        { id: 'bla-3', value: '1976' }
      ]
    },
    {
      id: 4,
      cells: [
        { id: 'aspect-4', value: 'Clinical studies' },
        { id: 'nda-4', value: 'Phase 1, 2, and 3 data must be submitted' },
        { id: 'bla-4', value: '1987' }
      ]
    },
    {
      id: 5,
      cells: [
        { id: 'aspect-5', value: 'Labeling requirements' },
        { id: 'nda-5', value: 'Proposed labeling including package insert' },
        { id: 'bla-5', value: '1983' }
      ]
    },
    {
      id: 6,
      cells: [
        { id: 'aspect-6', value: 'Regulatory authority' },
        { id: 'nda-6', value: 'Center for Drug Evaluation and Research (CDER)' },
        { id: 'bla-6', value: 'Center for Biologics Evaluation and Research (CBER) or CDER' }
      ]
    },
    {
      id: 7,
      cells: [
        { id: 'aspect-7', value: 'Product type' },
        { id: 'nda-7', value: 'Small molecule drugs' },
        {
          id: 'bla-7',
          value: 'Biological products (e.g., vaccines, blood products, gene therapies)'
        }
      ]
    },
    {
      id: 8,
      cells: [
        { id: 'aspect-8', value: 'Legal basis' },
        { id: 'nda-8', value: 'Federal Food, Drug, and Cosmetic Act' },
        { id: 'bla-8', value: 'Public Health Service Act' }
      ]
    },
    {
      id: 9,
      cells: [
        { id: 'aspect-9', value: 'Application form' },
        { id: 'nda-9', value: 'FDA Form 356h' },
        { id: 'bla-9', value: 'FDA Form 356h' }
      ]
    },
    {
      id: 10,
      cells: [
        { id: 'aspect-10', value: 'Manufacturing facility' },
        { id: 'nda-10', value: 'Detailed description required' },
        { id: 'bla-10', value: 'Detailed description required, with emphasis on consistency' }
      ]
    },
    {
      id: 11,
      cells: [
        { id: 'aspect-11', value: 'Preclinical data' },
        { id: 'nda-11', value: 'Required' },
        { id: 'bla-11', value: 'Required' }
      ]
    },
    {
      id: 12,
      cells: [
        { id: 'aspect-12', value: 'Product characterization' },
        { id: 'nda-12', value: 'Chemical structure and properties' },
        { id: 'bla-12', value: 'Complex biological structure and function' }
      ]
    }
  ]
};

const ARTICLE_1_STEPS_TO_RECREATE = [
  {
    id: 1,
    header: 'Search for “Did FDA agree with the sponsor not to submit "2.7.3" OR "2.7.4?”',
    content: [
      {
        id: 1,
        type: ELEMENT_TYPE.HEADER_2 as ElementType,
        header2: '1. Navigate to the Homepage'
      },
      {
        id: 2,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 8 }
      },
      {
        id: 3,
        type: ELEMENT_TYPE.UL_2 as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: 'Start by going to the homepage of RIA'
          }
        ]
      },
      {
        id: 4,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 24 }
      },
      {
        id: 5,
        type: ELEMENT_TYPE.HEADER_2 as ElementType,
        header2: '2. Access Search & Set Parameters'
      },
      {
        id: 6,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 8 }
      },
      {
        id: 7,
        type: ELEMENT_TYPE.UL_2 as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `In the “Source’ selection menu, choose “US-FDA - Drugs & Biologics”`
          },
          {
            id: 2,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `Then search for “Did FDA agree with the sponsor not to submit "2.7.3" OR "2.7.4?”`
          }
        ]
      },
      {
        id: 8,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 24 }
      },
      {
        id: 9,
        type: ELEMENT_TYPE.HEADER_2 as ElementType,
        header2:
          '3. Search for “Did FDA agree with the sponsor to submit "2.7.3" OR "2.7.4" in lieu of ISS OR ISE?”'
      },
      {
        id: 10,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 8 }
      },
      {
        id: 11,
        type: ELEMENT_TYPE.UL_2 as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content:
              'Read the snippet of high relevant answers and open it to read full text in the document'
          },
          {
            id: 2,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `Then search for “Did FDA agree with the sponsor not to submit "2.7.3" OR "2.7.4?”`
          }
        ]
      },
      {
        id: 12,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 24 }
      },
      {
        id: 13,
        type: ELEMENT_TYPE.HEADER_2 as ElementType,
        header2: '4. Look for precedence'
      },
      {
        id: 14,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 8 }
      },
      {
        id: 15,
        type: ELEMENT_TYPE.UL_2 as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content:
              'Read the snippet of high relevant answers and open it to read full text in the document'
          }
        ]
      }
    ]
  },
  {
    id: 2,
    header: 'Search for “Did FDA agree with the sponsor not to submit "2.7.3" OR "2.7.4?”',
    content: [
      {
        id: 1,
        type: ELEMENT_TYPE.HEADER_2 as ElementType,
        header2: '1. Navigate to the Homepage'
      },
      {
        id: 2,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 8 }
      },
      {
        id: 3,
        type: ELEMENT_TYPE.UL_2 as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `In the “Source’ selection menu, choose “US-FDA - Drugs & Biologics”`
          },
          {
            id: 2,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `Then search for “Did FDA agree with the sponsor not to submit "2.7.3" OR "2.7.4?”`
          }
        ]
      }
    ]
  },
  {
    id: 3,
    header:
      'Search for “Did FDA agree with the sponsor to submit "2.7.3" OR "2.7.4" in lieu of ISS OR ISE?”',
    content: [
      {
        id: 1,
        type: ELEMENT_TYPE.HEADER_2 as ElementType,
        header2: '1. Navigate to the Homepage'
      },
      {
        id: 2,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 8 }
      },
      {
        id: 3,
        type: ELEMENT_TYPE.UL_2 as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `In the “Source’ selection menu, choose “US-FDA - Drugs & Biologics”`
          },
          {
            id: 2,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content: `Then search for “Did FDA agree with the sponsor not to submit "2.7.3" OR "2.7.4?”`
          }
        ]
      }
    ]
  }
];

export const ARTICLES: Article[] = [
  {
    id: 'does-fda-allow-the-sponsor-to-use-2.7.3-or-2.7.4-in-lieu-of-iss-or-ise',
    title: 'Does FDA allow the sponsor to use 2.7.3 OR 2.7.4 in lieu of ISS OR ISE?',
    rawDescription:
      'Yes, there are exceptions where FDA allows the sponsors to use to use 2.7.3 OR 2.7.4 in lieu of ISS OR ISE. FDA discusses this case in the guidance document.',
    image: 'https://d1fziiyze1kdfo.cloudfront.net/Expert_Answers/article-1.png',
    addedOn: new Date('2025-01-01'),
    updatedOn: new Date('2025-01-01'),
    author: 'Pravin Jadhav',
    mainContent: [
      {
        id: 1,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 20 }
      },
      {
        id: 2,
        type: ELEMENT_TYPE.PARAGRAPH as ElementType,
        paragraph:
          'Yes, there are exceptions where FDA allows the sponsors to use to use 2.7.3 OR 2.7.4 in lieu of ISS OR ISE. FDA discusses this case in the guidance document.'
      },
      {
        id: 3,
        type: ELEMENT_TYPE.UL as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content:
              'In cases, where the ISS and ISE is small and can also function as the Summary of Clinical Safety in Module 2, section 2.7.3 and 2.7.4 can be used in lieu of ISS and ISE.'
          },
          {
            id: 2,
            type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
            content:
              'Additionally, when the text portion of the ISS is too large for Module 2, the ISE and ISS can be split across Module 2 and Module 5, with the narrative portion located in section 2.7.3 or 2.7.4 and the appendices of tables, figures, and datasets located in section 5.3.5.3.',
            link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
            referenceNumber: 1
          }
        ]
      },
      {
        id: 4,
        type: ELEMENT_TYPE.HR as ElementType,
        styles: { my: 6, borderColor: 'gray.300' }
      },
      {
        id: 5,
        type: ELEMENT_TYPE.HEADER_1 as ElementType,
        header1: 'Precedence Analysis: (5 Results)'
      },
      {
        id: 6,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 24 }
      },
      {
        id: 7,
        type: ELEMENT_TYPE.POLL as ElementType,
        poll: {
          title: 'Consensus Meter',
          options: [
            {
              id: 1,
              labelPrefix: 'Yes - ',
              percentage: 60,
              labelSuffix: ' (3/5)',
              color: '#46A759',
              icon: 'Check'
            },
            {
              id: 2,
              labelPrefix: 'Possibly - ',
              percentage: 0,
              labelSuffix: ' (0/5)',
              color: 'yellow.400',
              icon: 'QuestionMark'
            },
            {
              id: 3,
              labelPrefix: 'Yes - ',
              percentage: 40,
              labelSuffix: ' (2/5)',
              color: '#E3504F',
              icon: 'Clear'
            }
          ]
        }
      },
      {
        id: 8,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 32 }
      },
      {
        id: 9,
        type: ELEMENT_TYPE.DOCUMENT_CARD as ElementType,
        documentCard: {
          title: '1. Revefenacin (YUPELRI)',
          flag: 'us',
          tag: 'nda',
          chip: {
            label: 'No',
            color: '#E3504F',
            borderColor: '#E78C95',
            icon: 'Cross'
          },
          metaData: [
            {
              key: 'approvalDate',
              tooltip: 'Approval Date: Nov 9, 2018',
              value: 'Nov 9, 2018'
            },
            {
              key: 'applicationNumber',
              tooltip: 'Application Number: 210598',
              value: '210598'
            },
            {
              key: 'submission',
              tooltip: 'Submission: ORIG-1',
              value: 'ORIG-1'
            },
            {
              key: 'category',
              tooltip: 'Category: Administrative Document(s) & Correspondence',
              value: 'Administrative Document(s) & Correspondence'
            },
            {
              key: 'sponsorName',
              tooltip: 'Sponsor Name: Merck Sharp Dohme',
              value: 'Merck Sharp Dohme'
            }
          ],
          content: [
            {
              id: 1,
              type: ELEMENT_TYPE.UL as ElementType,
              list: [
                {
                  id: 1,
                  type: ELEMENT_TYPE.PARAGRAPH as ElementType,
                  content: `The FDA did not agree with the sponsor's proposal to omit submissions for sections "2.7.3" and "2.7.4" in the NDA application.`
                },
                {
                  id: 2,
                  type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
                  content:
                    "They emphasized that the NDA must be complete, requiring all necessary safety and efficacy information to be included in Module 5, despite the sponsor's suggestion to include summaries in other sections.",
                  link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
                  referenceNumber: 2
                }
              ]
            }
          ]
        }
      },
      {
        id: 10,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 32 }
      },
      {
        id: 11,
        type: ELEMENT_TYPE.DOCUMENT_CARD as ElementType,
        documentCard: {
          title: '2. Eflornithine Hydrochloride (IWILFIN)',
          flag: 'us',
          tag: 'nda',
          chip: {
            label: 'No',
            color: '#E3504F',
            borderColor: '#E78C95',
            icon: 'Cross'
          },
          metaData: [
            {
              key: 'approvalDate',
              tooltip: 'Approval Date: Dec 13, 2019',
              value: 'Dec 13, 2019'
            },
            {
              key: 'applicationNumber',
              tooltip: 'Application Number: 215500',
              value: '215500'
            },
            {
              key: 'submission',
              tooltip: 'Submission: ORIG-1',
              value: 'ORIG-1'
            },
            {
              key: 'category',
              tooltip: 'Category: Administrative Document(s) & Correspondence',
              value: 'Administrative Document(s) & Correspondence'
            },
            {
              key: 'sponsorName',
              tooltip: 'Sponsor Name: Merck Sharp Dohme',
              value: 'Merck Sharp Dohme'
            }
          ],
          content: [
            {
              id: 1,
              type: ELEMENT_TYPE.UL as ElementType,
              list: [
                {
                  id: 1,
                  type: ELEMENT_TYPE.PARAGRAPH as ElementType,
                  content:
                    "The FDA did not fully agree with the sponsor's plans regarding the submission of clinical information in modules 2.7.3 and 2.7.4."
                },
                {
                  id: 2,
                  type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
                  content:
                    'While the proposed clinical information was generally acceptable, the FDA emphasized the need for efficacy data based on blinded independent review and requested further details on the review process, including the inclusion of two separate readers for image assessments.',
                  link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
                  referenceNumber: 3
                }
              ]
            }
          ]
        }
      },
      {
        id: 12,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 32 }
      },
      {
        id: 13,
        type: ELEMENT_TYPE.DOCUMENT_CARD as ElementType,
        documentCard: {
          title: '3. Hydrocortisone (ALKINDI SPRINKLE)',
          flag: 'us',
          tag: 'nda',
          chip: {
            label: 'Yes',
            color: 'primary.600',
            borderColor: 'primary.300',
            icon: 'Check'
          },
          metaData: [
            {
              key: 'approvalDate',
              tooltip: 'Approval Date: Sep 29, 2020',
              value: 'Sep 29, 2020'
            },
            {
              key: 'applicationNumber',
              tooltip: 'Application Number: 213876',
              value: '213876'
            },
            {
              key: 'submission',
              tooltip: 'Submission: ORIG-1',
              value: 'ORIG-1'
            },
            {
              key: 'category',
              tooltip: 'Category: Administrative Document(s) & Correspondence',
              value: 'Administrative Document(s) & Correspondence'
            },
            {
              key: 'sponsorName',
              tooltip: 'Sponsor Name: Merck Sharp Dohme',
              value: 'Merck Sharp Dohme'
            }
          ],
          content: [
            {
              id: 1,
              type: ELEMENT_TYPE.UL as ElementType,
              list: [
                {
                  id: 1,
                  type: ELEMENT_TYPE.PARAGRAPH as ElementType,
                  content:
                    'The FDA agreed with the sponsor that the limited number of clinical studies would allow sections 2.7.3 and 2.7.4 to serve as the Integrated Summaries of Effectiveness and Safety for the NDA submission.'
                },
                {
                  id: 2,
                  type: ELEMENT_TYPE.PARAGRAPH as ElementType,
                  content:
                    "However, they requested specific information in tabular format to assess the data's adequacy for supporting the NDA."
                },
                {
                  id: 3,
                  type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
                  content:
                    'The sponsor confirmed they would provide these summaries and additional details in their submission.',
                  link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
                  referenceNumber: 4
                }
              ]
            }
          ]
        }
      },
      {
        id: 14,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 32 }
      },
      {
        id: 15,
        type: ELEMENT_TYPE.DOCUMENT_CARD as ElementType,
        documentCard: {
          title: '4. Ansuvimab-Zykl (EBANGA)',
          flag: 'us',
          tag: 'nda',
          chip: {
            label: 'Yes',
            color: 'primary.600',
            borderColor: 'primary.300',
            icon: 'Check'
          },
          metaData: [
            {
              key: 'approvalDate',
              tooltip: 'Approval Date: Dec 21, 2020',
              value: 'Dec 21, 2020'
            },
            {
              key: 'applicationNumber',
              tooltip: 'Application Number: 761172',
              value: '761172'
            },
            {
              key: 'submission',
              tooltip: 'Submission: ORIG-1',
              value: 'ORIG-1'
            },
            {
              key: 'category',
              tooltip: 'Category: Administrative Document(s) & Correspondence',
              value: 'Administrative Document(s) & Correspondence'
            },
            {
              key: 'sponsorName',
              tooltip: 'Sponsor Name: Merck Sharp Dohme',
              value: 'Merck Sharp Dohme'
            }
          ],
          content: [
            {
              id: 1,
              type: ELEMENT_TYPE.UL as ElementType,
              list: [
                {
                  id: 1,
                  type: ELEMENT_TYPE.PARAGRAPH as ElementType,
                  content:
                    "The FDA agreed with Ridgeback's proposal to forgo a traditional Integrated Summary of Safety (ISS) and instead utilize Module 2.7.4 for safety data, citing challenges in integrating data from various trials."
                },
                {
                  id: 2,
                  type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
                  content:
                    'They also concurred with not merging nonclinical and clinical data. Ridgeback plans to provide a cross-reference to relevant modules for the Integrated Summary of Efficacy (ISE), which the FDA found acceptable.',
                  link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
                  referenceNumber: 5
                }
              ]
            },
            {
              id: 2,
              type: ELEMENT_TYPE.SPACE as ElementType,
              styles: { height: 12 }
            },
            {
              id: 3,
              type: ELEMENT_TYPE.NOTES as ElementType,
              notes:
                'Original question was unclear but the meeting discussion led to agreement with caution.'
            }
          ]
        }
      },
      {
        id: 16,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 32 }
      },
      {
        id: 17,
        type: ELEMENT_TYPE.DOCUMENT_CARD as ElementType,
        documentCard: {
          title: '5. Gilteritinib Fumarate (IWILFIN)',
          flag: 'us',
          tag: 'nda',
          chip: {
            label: 'No',
            color: '#E3504F',
            borderColor: '#E78C95',
            icon: 'Cross'
          },
          metaData: [
            {
              key: 'approvalDate',
              tooltip: 'Approval Date: Oct 28, 2021',
              value: 'Oct 28, 2021'
            },
            {
              key: 'applicationNumber',
              tooltip: 'Application Number: 211349',
              value: '211349'
            },
            {
              key: 'submission',
              tooltip: 'Submission: ORIG-1',
              value: 'ORIG-1'
            },
            {
              key: 'category',
              tooltip: 'Category: Administrative Document(s) & Correspondence',
              value: 'Administrative Document(s) & Correspondence'
            },
            {
              key: 'sponsorName',
              tooltip: 'Sponsor Name: Merck Sharp Dohme',
              value: 'Merck Sharp Dohme'
            }
          ],
          content: [
            {
              id: 1,
              type: ELEMENT_TYPE.UL as ElementType,
              list: [
                {
                  id: 1,
                  type: ELEMENT_TYPE.PARAGRAPH as ElementType,
                  content:
                    'The FDA did agree to allow the sponsor to use the Summary Clinical Efficacy (SCE) in place of the Integrated Summary of Efficacy (ISE) for their submission.'
                },
                {
                  id: 2,
                  type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
                  content:
                    'However, they emphasized that the entire Clinical Summary should not exceed 400 pages and provided specific guidelines for the presentation of the data within the electronic Common Technical Document (eCTD).',
                  link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
                  referenceNumber: 6
                }
              ]
            },
            {
              id: 2,
              type: ELEMENT_TYPE.SPACE as ElementType,
              styles: { height: 12 }
            },
            {
              id: 3,
              type: ELEMENT_TYPE.NOTES as ElementType,
              notes:
                'FDA emphasized that Clinical Summary (Modules 2.7.1 to 2.7.4) does not exceed the 400 page limit.'
            }
          ]
        }
      },
      {
        id: 18,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 20 }
      }
    ],
    stepsToRecreate: ARTICLE_1_STEPS_TO_RECREATE,
    history: [
      {
        id: 1,
        dateModified: new Date('2025-01-01'),
        changes: 'Article Created',
        author: 'Pravin Jadhav',
        view: 'Compare Changes',
        changeType: 'created'
      }
    ]
  },
  {
    id: 'has-fda-changed-designations-for-a-single-application-from-nda-to-bla',
    title: 'Has FDA changed designations for a single application from NDA to BLA?',
    rawDescription:
      'Yes, the FDA has changed the designation of certain applications from New Drug Applications (NDAs) to Biologics License Applications (BLAs). This transition was implemented as part of the Biologics Price Competition and Innovation Act of 2009, which aimed to clarify the statutory authority under which certain protein products are regulated. As a result, on March 23, 2020, the FDA transitioned more than 100 approved NDAs to BLAs. This change affected various stakeholders, including biopharma companies and drug compounders (RAPS).',
    image: 'https://d1fziiyze1kdfo.cloudfront.net/Expert_Answers/article-2.png',
    addedOn: new Date('2025-01-01'),
    updatedOn: new Date('2025-01-01'),
    author: 'Pravin Jadhav',
    mainContent: [
      {
        id: 1,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 20 }
      },
      {
        id: 2,
        type: ELEMENT_TYPE.PARAGRAPH as ElementType,
        paragraph:
          'Yes, the FDA has changed the designation of certain applications from New Drug Applications (NDAs) to Biologics License Applications (BLAs). This transition was implemented as part of the Biologics Price Competition and Innovation Act of 2009, which aimed to clarify the statutory authority under which certain protein products are regulated. As a result, on March 23, 2020, the FDA transitioned more than 100 approved NDAs to BLAs. This change affected various stakeholders, including biopharma companies and drug compounders (RAPS).'
      },
      {
        id: 3,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 16 }
      },
      {
        id: 4,
        type: ELEMENT_TYPE.PARAGRAPH as ElementType,
        paragraph:
          "The FDA provided guidance on this transition, explaining that affected NDA holders would receive a letter indicating that their approved NDA was deemed to be a BLA as of March 23, 2020. The letter also informed NDA holders that they would need to update their listing information in the FDA's electronic Drug Registration and Listing System (eDRLS) to reflect the change in application number prefix from NDA to BLA (WSGR)."
      },
      {
        id: 5,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 16 }
      },
      {
        id: 6,
        type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
        referenceParagraph:
          'This table highlights the main differences between NDA and BLA requirements. Both applications share many similarities, but BLAs generally require more detailed information on manufacturing consistency and biological characterization due to the complex nature of biological products.',
        link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
        referenceNumber: 1
      },
      {
        id: 7,
        type: ELEMENT_TYPE.HR as ElementType,
        allowedInReport: false,
        styles: { my: 6, borderColor: 'gray.300' }
      },
      {
        id: 8,
        allowedInReport: false,
        type: ELEMENT_TYPE.HEADER_1 as ElementType,
        header1: 'Precedence Analysis: (102 instances)'
      },
      {
        id: 9,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 24 }
      }
    ],
    stepsToRecreate: ARTICLE_1_STEPS_TO_RECREATE,
    history: [
      {
        id: 1,
        dateModified: new Date('2025-01-01'),
        changes: 'Article Created',
        author: 'Pravin Jadhav',
        view: 'Compare Changes',
        changeType: 'created'
      }
    ]
  },
  {
    id: 'what-are-differences-in-requirements-between-nda-and-bla',
    title: 'What are differences in requirements between NDA and BLA?',
    rawDescription:
      'There are several key differences between New Drug Applications (NDAs) and Biologics License Applications (BLAs) in terms of their requirements and regulatory processes',
    image: 'https://d1fziiyze1kdfo.cloudfront.net/Expert_Answers/article-3.png',
    addedOn: new Date('2025-01-01'),
    updatedOn: new Date('2025-01-01'),
    author: 'Pravin Jadhav',
    mainContent: [
      {
        id: 1,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 20 }
      },
      {
        id: 2,
        type: ELEMENT_TYPE.UL as ElementType,
        list: [
          {
            id: 1,
            type: ELEMENT_TYPE.PARAGRAPH as ElementType,
            content:
              'There are several key differences between New Drug Applications (NDAs) and Biologics License Applications (BLAs) in terms of their requirements and regulatory processes:'
          },
          {
            id: 2,
            type: ELEMENT_TYPE.REFERENCE_PARAGRAPH as ElementType,
            content:
              'This table highlights the main differences between NDA and BLA requirements. Both applications share many similarities, but BLAs generally require more detailed information on manufacturing consistency and biological characterization due to the complex nature of biological products.',
            link: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/ich-efficacy-guidelines',
            referenceNumber: 1
          }
        ]
      },
      {
        id: 3,
        type: ELEMENT_TYPE.HR as ElementType,
        styles: { my: 6, borderColor: 'gray.300' }
      },
      {
        id: 4,
        type: ELEMENT_TYPE.HEADER_1 as ElementType,
        header1: 'NDA vs BLA: (18 aspects)'
      },
      {
        id: 5,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 24 }
      },
      {
        id: 6,
        type: ELEMENT_TYPE.NORMAL_TABLE as ElementType,
        normalTable: ARTICLE_3_TABLE
      },
      {
        id: 7,
        type: ELEMENT_TYPE.SPACE as ElementType,
        styles: { height: 20 }
      }
    ],
    stepsToRecreate: ARTICLE_1_STEPS_TO_RECREATE,
    history: [
      {
        id: 1,
        dateModified: new Date('2025-01-01'),
        changes: 'Article Created',
        author: 'Pravin Jadhav',
        view: 'Compare Changes',
        changeType: 'created'
      }
    ]
  }
];

export default ARTICLES;
