import { Typography } from '@mui/material';
import React from 'react';
import styles from '../../styles/FormatHtmlString.styles';

interface CleanHtmlProps {
  htmlString: string;
}
const FormatHtmlString: React.FC<CleanHtmlProps> = ({ htmlString }) => {
  const cleanHtml = (input: string): string => {
    try {
      // Parse the HTML string into a DOM
      const parser = new DOMParser();
      const doc = parser.parseFromString(input, 'text/html');

      if (!doc || !doc.body) {
        console.error('Error parsing HTML string');
        return input;
      }

      // Remove empty <p> tags
      doc.querySelectorAll('p').forEach(p => {
        if (!p.textContent?.trim()) {
          p.remove();
        }
      });

      doc.querySelectorAll('br').forEach(br => {
        const { nextSibling } = br;
        if (nextSibling && nextSibling.nodeName === 'BR') {
          br.remove();
        }
      });

      // Return cleaned innerHTML
      return doc.body.innerHTML;
    } catch (error) {
      console.error('Error cleaning HTML:', error);
      return input;
    }
  };

  // Clean the input HTML
  const cleanedHtml = cleanHtml(htmlString);

  return <Typography sx={styles.text} dangerouslySetInnerHTML={{ __html: cleanedHtml }} />;
};

export default React.memo(FormatHtmlString);
