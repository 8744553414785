import React, { JSX } from 'react';

import { Box, Typography, Stack, Chip, Tooltip } from '@mui/material';

import { CheckCircle, Cancel } from '@mui/icons-material';

import { IDocumentCard } from '../interface';
import componentStyles from '../styles/Components.styles';
import ElementRenderer from './ElementRenderer';

import {
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_COLOR,
  LABEL_TOOLTIP_TITLE
} from '../../SearchResults/constants';

const iconMap: { [key: string]: JSX.Element } = {
  Check: <CheckCircle />,
  Cross: <Cancel />
};

interface DocumentCardProps {
  document: IDocumentCard | undefined;
  isReporting?: boolean;
}

const DocumentCard = ({ document, isReporting = false }: DocumentCardProps) => {
  const flag = document?.flag?.toLowerCase() ?? '';
  const FlagComponent = sourceFlagMapping[flag];
  const flagTooltipTitle = sourceFlagMappingTooltip[flag];

  const labelKey = document?.tag?.toLowerCase() ?? '';
  const labelColor = LABEL_COLOR[labelKey];
  const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];

  const ChipIcon = iconMap[document?.chip?.icon ?? ''];

  return (
    <Stack sx={componentStyles.documentCard.container}>
      <Stack direction='row' gap={5} sx={componentStyles.documentCard.headerContainer}>
        <Stack>
          <Stack direction='row' gap={1} alignItems='center'>
            <Typography sx={componentStyles.documentCard.title}>{document?.title}</Typography>
            <Tooltip
              title={flagTooltipTitle}
              disableInteractive
              disableFocusListener
              disableTouchListener>
              <FlagComponent sx={componentStyles.documentCard.flagComponent} />
            </Tooltip>
            <Tooltip
              title={labelTooltipTitle}
              disableInteractive
              disableFocusListener
              disableTouchListener>
              <Chip
                label={labelKey.toUpperCase()}
                sx={{ bgcolor: labelColor, ...componentStyles.documentCard.tag }}
              />
            </Tooltip>
          </Stack>
          <Stack
            direction='row'
            gap={1}
            divider={<Typography sx={componentStyles.documentCard.metaDataItem}>•</Typography>}>
            {document?.metaData?.map(meta => (
              <Tooltip key={meta.key} title={meta.tooltip}>
                <Typography
                  noWrap
                  sx={{
                    ...componentStyles.documentCard.metaDataItem,
                    ...(isReporting && componentStyles.documentCard.metadataItemReporting)
                  }}>
                  {meta.value}
                </Typography>
              </Tooltip>
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Stack
            direction='row'
            gap={0.5}
            sx={{
              ...componentStyles.documentCard.chipContainer,
              borderColor: document?.chip?.borderColor ?? ''
            }}>
            <Box
              sx={{
                ...componentStyles.documentCard.chipLabel,
                color: document?.chip?.color ?? ''
              }}>
              {document?.chip?.label}
            </Box>
            <Box
              sx={{
                display: 'flex',
                '& .MuiSvgIcon-root': {
                  height: 14,
                  width: 14,
                  color: document?.chip?.color ?? ''
                }
              }}>
              {ChipIcon}
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack p={2}>
        {document?.content?.map(content => (
          <ElementRenderer key={content.id} content={content} />
        ))}
      </Stack>
    </Stack>
  );
};

DocumentCard.defaultProps = {
  isReporting: false
};

export default React.memo(DocumentCard);
