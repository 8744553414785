import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';

// componentns

import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import Main from './Main';
import Header from '../../Guidelines/components/common/Header';
import NewFilters from '../../../components/NewFilters';

// Store
import GlobalStore from '../../../store';

// hooks

import { Stat } from '../../SearchResults/types';

import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import useICHGuidelinesData from '../hooks/useICHGuidelinesData';
import HeaderTitle from '../../Guidelines/components/common/HeaderTitle';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';

const Home = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [stats, setStats] = useState<Stat[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [appliedFilters, setAppliedFilters] = useState({});
  const [ariaModalOpen, setAriaModalOpen] = React.useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [documentCount, setDocumentCount] = useState({
    totalCount: 0,
    pageCount: 0
  });
  const {
    fetchGuidelinesDetails,
    guidelinesData,
    ICHGuidelinesFilters,
    isFilterApplied,
    guidelinesLoading
  } = useICHGuidelinesData();

  useEffect(() => {
    fetchGuidelinesDetails();
  }, []);

  useEffect(() => {
    if (guidelinesData?.stats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total Guidelines',
        value: guidelinesData?.stats?.total_guidelines || 0
      });
      newStats.push({
        title: 'Documents',
        value: guidelinesData?.stats?.documents || 0
      });
      newStats.push({
        title: 'Pages',
        value: guidelinesData?.stats?.pages || 0
      });
      setStats(newStats);
      setDocumentCount({
        totalCount: guidelinesData?.stats?.documents,
        pageCount: guidelinesData?.stats?.pages
      });
    }
  }, [ICHGuidelinesFilters, guidelinesData?.stats]);

  const handleFilterApply = useCallback((currentfilters: any) => {
    fetchGuidelinesDetails(currentfilters);
    setAppliedFilters(currentfilters);
  }, []);

  useAriaShareHandler(false, ariaModalOpen, setAriaModalOpen);

  const getAriaDocuments = () => {
    const ariaDocuments: Array<string> = [];

    Object.keys(guidelinesData?.guidelines || {}).forEach(key => {
      const items = guidelinesData?.guidelines[key];
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (item.code) {
            ariaDocuments.push(item.code);
          }
        });
      }
    });

    return ariaDocuments;
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  return (
    <>
      <Header
        title={
          <HeaderTitle
            meetingName='International Council for Harmonisation (ICH) Guidelines'
            tooltipContent='The International Council for Harmonisation of Technical Requirements for Pharmaceuticals for Human Use (ICH) provides globally accepted guidelines to ensure the development, registration, and lifecycle management of pharmaceuticals meet the highest standards of quality, safety, and efficacy.'
          />
        }
        stats={stats}
        handleVisualizeOpen={handleVisualizeOpen}
        showVisualize={false}
      />
      <Main selectedGuidelines={guidelinesData} guidelinesListLoading={guidelinesLoading} />
      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        hideReportIcon
        hideDivider>
        {/* TODO: Visualize code  */}
      </BottomDrawer>
      <Box px={2.8}>
        <NewFilters
          data={ICHGuidelinesFilters}
          applyFilters={handleFilterApply}
          isLoading={guidelinesLoading}
          showSearchKeywordInBottomFilters
          source='ich-guideline'
        />
      </Box>
      {ariaModalOpen && (
        <AriaDrawerCore
          applications={isFilterApplied ? getAriaDocuments() : []}
          sources={{ ich: ['ich-guideline'] }}
          groupId='entity_id'
          docCount={documentCount?.totalCount}
          docPageCount={documentCount?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}
      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={guidelinesLoading}
        />
      </Box>
    </>
  );
};

export default React.memo(Home);
