import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import styles from '../../styles/GuidelineSectionDetails.styles';
import { EmphasisedContentProps } from '../../types';

const EmphasisedContent: React.FC<EmphasisedContentProps> = ({
  label,
  mainText,
  boldEmphasis,
  tags,
  toolTipText
}) => {
  const tooltipText = () => {
    let text = '';
    if (mainText && mainText !== 'NA') {
      text += mainText;
    }
    if (boldEmphasis && boldEmphasis !== 'NA') {
      text += ` (${boldEmphasis})`;
    }
    if (tags?.length && tags?.[0] !== 'NA') {
      text += ` ${tags}`;
    }
    return text;
  };

  const isAllNA =
    (!mainText || mainText === 'NA') &&
    (!boldEmphasis || boldEmphasis === 'NA') &&
    (!tags?.length || tags?.[0] === 'NA');

  if (isAllNA) {
    return <Typography sx={styles.valueText}>NA</Typography>;
  }

  return (
    <Tooltip title={isAllNA ? 'NA' : toolTipText || `${label}: ${tooltipText()}`} placement='top'>
      <Typography sx={styles.valueText} key={mainText}>
        {mainText && mainText !== 'NA' && <span>{mainText}</span>}
        {boldEmphasis && boldEmphasis !== 'NA' && (
          <span
            style={
              { ...styles.brandText, marginLeft: !mainText ? 0 : 0.75 } as React.CSSProperties
            }>{` (${boldEmphasis})`}</span>
        )}
        {!!tags?.length &&
          tags[0] !== 'NA' &&
          tags.map((tag: string) => (
            <span key={tag} style={styles.partnerText as React.CSSProperties}>
              {tag}
            </span>
          ))}
      </Typography>
    </Tooltip>
  );
};

export default React.memo(EmphasisedContent);
