import { createSvgIcon } from '@mui/material';

const ChnFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_8986_2047)'>
      <path d='M0 0H18V12H0V0Z' fill='#EE1C25' />
      <path
        d='M3.00001 1.19995L4.05805 4.45615L1.28809 2.44375H4.71193L1.94197 4.45615L3.00001 1.19995Z'
        fill='#FFFF00'
      />
      <path
        d='M6.2351 0.647857L6.13493 1.78471L5.54772 0.8061L6.598 1.25269L5.48581 1.5087L6.2351 0.647857Z'
        fill='#FFFF00'
      />
      <path
        d='M7.63073 1.98222L7.09724 2.99111L6.93581 1.86131L7.73047 2.68048L6.6061 2.48483L7.63073 1.98222Z'
        fill='#FFFF00'
      />
      <path
        d='M7.76404 3.99417L6.8654 4.69767L7.1789 3.60031L7.57027 4.67238L6.62352 4.03508L7.76404 3.99417Z'
        fill='#FFFF00'
      />
      <path
        d='M6.21168 4.83866L6.15872 5.9787L5.53145 5.02527L6.59934 5.42793L5.49872 5.72983L6.21168 4.83866Z'
        fill='#FFFF00'
      />
    </g>
    <defs>
      <clipPath id='clip0_8986_2047'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ChnFlagIcon'
);

export default ChnFlagIcon;
