import { createSvgIcon } from '@mui/material';

const TurFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_8986_2220)'>
      <path d='M0 0H18V12H0V0Z' fill='#E30A17' />
      <path
        d='M8.35006 6L11.0637 5.1184L9.38646 7.4266V4.5734L11.0637 6.8816L8.35006 6ZM8.53506 7.6042C8.1875 8.15345 7.67095 8.57496 7.06315 8.8053C6.45535 9.03564 5.78918 9.06236 5.16489 8.88142C4.5406 8.70049 3.99196 8.32169 3.60153 7.80204C3.2111 7.28238 3 6.64998 3 6C3 5.35002 3.2111 4.71762 3.60153 4.19796C3.99196 3.67831 4.5406 3.29951 5.16489 3.11858C5.78918 2.93764 6.45535 2.96436 7.06315 3.1947C7.67095 3.42504 8.1875 3.84655 8.53506 4.3958C8.21049 4.03463 7.784 3.78041 7.31192 3.66672C6.83984 3.55302 6.34438 3.5852 5.89097 3.75899C5.43756 3.93279 5.04753 4.24004 4.7724 4.64015C4.49727 5.04026 4.34997 5.51442 4.34997 6C4.34997 6.48558 4.49727 6.95974 4.7724 7.35985C5.04753 7.75996 5.43756 8.06721 5.89097 8.24101C6.34438 8.4148 6.83984 8.44698 7.31192 8.33328C7.784 8.21959 8.21049 7.96537 8.53506 7.6042Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_8986_2220'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'TurFlagIcon'
);

export default TurFlagIcon;
