import React, { useState } from 'react';
import { Box, Divider, Typography, Tooltip } from '@mui/material';
import styles from '../../styles/Documents.styles';
import Comparison from './Comparison';
import { DATA_BUCKET_SOURCE_MAPPING, INITIAL_DOCUMENT_COMPARISON_DETAILS } from '../../contants';
import { OverviewProp } from '../../types';
import getDateString from '../../../../utils/getDateString';

const Overview = ({
  documentDetails,
  compareEnabled,
  handleCompareEnabled,
  handleExitCompare,
  totalSelectedDocument,
  parentData,
  setParentData,
  childData,
  setChildData,
  isMultipleDocumentAvailable
}: OverviewProp) => {
  const [showDocumentComparison, setShowDocumentComparison] = useState(false);

  const handleReset = () => {
    setParentData(INITIAL_DOCUMENT_COMPARISON_DETAILS);
    setChildData(INITIAL_DOCUMENT_COMPARISON_DETAILS);
  };

  const handleCompareClick = async () => {
    setShowDocumentComparison(true);
    const parentFilteredObj = DATA_BUCKET_SOURCE_MAPPING?.find(
      each => each?.source === parentData?.source
    );
    const parentDataSignedUrl = await parentFilteredObj?.getSignedUrl({
      s3_path: parentData?.s3Path,
      source: parentFilteredObj?.dataBucket
    });

    const childFilteredObj = DATA_BUCKET_SOURCE_MAPPING?.find(
      each => each?.source === childData?.source
    );
    const childDataSignedUrl = await childFilteredObj?.getSignedUrl({
      s3_path: childData?.s3Path,
      source: childFilteredObj?.dataBucket
    });
    setParentData({ ...parentData, signedUrl: parentDataSignedUrl?.data?.body?.s3_url });
    setChildData({ ...childData, signedUrl: childDataSignedUrl?.data?.body?.s3_url });
  };

  return (
    <>
      <Box px={2} pt={2} pb={1.5} sx={{ bgcolor: 'gray.100' }}>
        <Box display='flex' mb={0.5}>
          <Typography color='gray.600' fontSize={14} width={108}>
            About:
          </Typography>
          <Tooltip
            title={
              <Box sx={{ ...styles.common.scroll, maxHeight: 600, overflowY: 'auto', p: 1 }}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: documentDetails?.description }}
                  sx={{
                    '& p:first-of-type': {
                      m: 0,
                      p: 0
                    }
                  }}
                />
              </Box>
            }>
            <Typography
              dangerouslySetInnerHTML={{ __html: documentDetails?.description }}
              color='gray.800'
              fontSize={14}
              fontWeight={500}
              ml={1}
              sx={{
                ...styles.common.oneLineText,
                '& p:first-of-type': {
                  margin: 0,
                  padding: 0
                },
                width: '75%'
              }}
            />
          </Tooltip>
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography color='gray.600' fontSize={14} width={108}>
            Status:
          </Typography>
          <Typography color='gray.800' fontSize={14} fontWeight={500} ml={1}>
            {documentDetails?.status}
          </Typography>
          {!!documentDetails?.step_date && !!documentDetails?.step_date_label && (
            <>
              <Divider orientation='vertical' sx={{ height: 16, mx: 1 }} />
              <Typography color='gray.800' fontSize={14}>
                {`Date of ${documentDetails?.step_date_label}:`}
              </Typography>
              <Typography ml={1} color='gray.800' fontSize={14} fontWeight={500}>
                {getDateString(documentDetails?.step_date)}
              </Typography>
            </>
          )}
        </Box>
        <Divider
          orientation='horizontal'
          sx={{ ...styles.divider, mb: compareEnabled ? 1.5 : 1 }}
        />
        {compareEnabled ? (
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <Typography
                color='#0076D6'
                fontSize={14}
                fontWeight={700}>{`${totalSelectedDocument}/2 Selected`}</Typography>
              <Divider orientation='vertical' sx={{ height: 16, mx: 1 }} />
              <Typography
                onClick={() => handleReset()}
                color={totalSelectedDocument > 0 ? 'gray.800' : 'gray.500'}
                fontSize={14}
                fontWeight={500}
                sx={{ cursor: 'pointer' }}>
                Reset
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Typography
                onClick={() => handleCompareClick()}
                color={totalSelectedDocument === 2 ? 'white.main' : 'gray.500'}
                fontSize={14}
                fontWeight={600}
                bgcolor={totalSelectedDocument === 2 ? 'primary.600' : 'gray.200'}
                borderRadius={6}
                px={2}
                py={0.75}
                mr={1.5}
                sx={{ cursor: 'pointer' }}>
                Compare
              </Typography>
              <Typography
                color='primary.700'
                fontSize={14}
                fontWeight={600}
                borderRadius={6}
                px={2}
                py={0.75}
                border='1px solid'
                borderColor='primary.700'
                onClick={() => handleExitCompare()}
                sx={{
                  cursor: 'pointer'
                }}>
                Exit Compare
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography
            display='inline'
            onClick={() => isMultipleDocumentAvailable && handleCompareEnabled()}
            color={isMultipleDocumentAvailable ? 'primary.700' : 'gray.500'}
            fontSize={13}
            fontWeight={600}
            sx={{ cursor: isMultipleDocumentAvailable ? 'pointer' : 'default' }}>
            Compare Guidelines
          </Typography>
        )}
      </Box>
      {showDocumentComparison && (
        <Comparison
          showDocumentComparison={showDocumentComparison}
          setShowDocumentComparison={setShowDocumentComparison}
          parentData={parentData}
          setParentData={setParentData}
          childData={childData}
          setChildData={setChildData}
        />
      )}
    </>
  );
};

export default React.memo(Overview);
