import React, { useState, SyntheticEvent, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';

import { Box, Typography, Divider, Stack, Avatar, Tab, Tabs, Popover } from '@mui/material';

import { Person } from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';
import themev5 from '../../themev5';

import { ArrowSmallLeftIcon } from '../../assets/svgs/Icons';
import { ARTICLES } from './utils/constants';
import ElementRenderer from './components/ElementRenderer';

import articleStyles from './styles/Article.styles';
import TabPanel from './components/TabPanel';
import StepsToRecreate from './components/StepsToRecreate';
import History from './components/History';
import Feedback from './components/Feedback';
import ReportButton from '../../components/Report/ReportButton';
import useReportingHandler from '../../components/Report/hooks/useReportingHandler';

const Article = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { updateReportData } = useReportingHandler();

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = useCallback((_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const currentArticle = ARTICLES.find(article => article.id === id);

  const handleAddToReport = useCallback((key: any, reportData: any) => {
    const sectionLayout = {
      templateType: 'custom',
      sectionType: 'EXPERT_ANSWER_ARTICLE',
      id: reportData?.reportId,
      style: {
        placement: {
          h: 40,
          w: 12,
          i: reportData?.reportId,
          minW: 8,
          moved: false,
          static: false,
          x: 0,
          y: 0
        }
      },
      data: {
        expertAnswerArticleId: key.expertAnswerArticleId
      },
      title: `Expert Answer Article: ${currentArticle?.title}`
    };

    updateReportData(reportData?.id, sectionLayout);
  }, []);

  if (!currentArticle) {
    return (
      <ThemeProvider theme={themev5}>
        <Box sx={articleStyles.mainContainer}>
          <Stack
            direction='row'
            gap={0.5}
            alignItems='center'
            onClick={() => history.push('/expert-answers')}
            sx={articleStyles.backButtonContainer}>
            <ArrowSmallLeftIcon sx={articleStyles.arrowSmallLeftIcon} />
            Back
          </Stack>
          <Typography sx={articleStyles.articleNotFound}>Article not found.</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={themev5}>
      <Stack sx={articleStyles.mainContainer}>
        <Stack
          direction='row'
          gap={0.5}
          alignItems='center'
          onClick={() => history.push('/expert-answers')}
          sx={articleStyles.backButtonContainer}>
          <ArrowSmallLeftIcon sx={articleStyles.arrowSmallLeftIcon} />
          Back
        </Stack>
        <Typography sx={articleStyles.articleTitle}>{currentArticle.title}</Typography>
        <Divider sx={articleStyles.topDivider} />
        <Stack direction='row' my={1} justifyContent='space-between' alignItems='center'>
          <Stack direction='row' gap={0.75} alignItems='center'>
            <Avatar sx={articleStyles.avatar}>
              <Person sx={articleStyles.personIcon} />
            </Avatar>
            <Stack direction='row' gap={2} sx={articleStyles.articleMetaDataContainer}>
              <Typography>{currentArticle.author}</Typography>
              <Divider orientation='vertical' flexItem sx={articleStyles.verticalDivider} />
              <Typography>
                Last Updated:{' '}
                {currentArticle.updatedOn.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  timeZone: 'UTC'
                })}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row'>
            <Stack>
              <ReportButton
                data={{ expertAnswerArticleId: id }}
                addToReport={handleAddToReport}
                prefixId='expert_answers_'
              />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={articleStyles.bottomDivider} />

        <Tabs value={tabIndex} onChange={handleTabChange} sx={articleStyles.tabsHeaderContainer}>
          <Tab label='Article' sx={articleStyles.tabHeaderItem} />
          <Tab label='Steps to Recreate' sx={articleStyles.tabHeaderItem} />
          <Tab label='View History' sx={articleStyles.tabHeaderItem} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          {currentArticle.mainContent.map(content => (
            <ElementRenderer key={content.id} content={content} />
          ))}
          <Stack alignItems='center' gap={1} mb={6} mt={2}>
            <Feedback />
          </Stack>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <StepsToRecreate steps={currentArticle.stepsToRecreate} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <History history={currentArticle.history} />
        </TabPanel>
      </Stack>

      <Popover
        id='mouse-over-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Box>
          <Typography>Demo</Typography>
        </Box>
      </Popover>
    </ThemeProvider>
  );
};

export default React.memo(Article);
