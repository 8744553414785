import React from 'react';

import { Divider, Stack } from '@mui/material';

import styles from '../../styles/GroupsTabs.styles';
import { TabButtonProps } from '../../types';
import TabButton from '../../../../components/ui/TabButton';

const GroupTabs = ({
  tabs,
  selectedTab,
  onChange
}: {
  tabs: TabButtonProps[];
  selectedTab: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: string) => void;
}) => {
  const handleAllGroupClick = () => {
    onChange?.('All');
  };

  const handleOptionSelect = (e: React.SyntheticEvent, button: TabButtonProps) => {
    onChange?.(button.label);
  };

  return (
    <Stack
      spacing={1.5}
      direction='row'
      alignItems='center'
      flexWrap='wrap'
      sx={styles.tabContainer}>
      <TabButton
        title='All Guidelines'
        size='xsmall'
        onClick={handleAllGroupClick}
        selected={selectedTab === 'All'}
      />
      <Divider orientation='vertical' sx={styles.tabDivider} />
      {tabs.map((button: any) => (
        <React.Fragment key={button.id}>
          <TabButton
            title={button.label}
            size='xsmall'
            onClick={(e: React.SyntheticEvent) => handleOptionSelect(e, button)}
            selected={selectedTab === button.label}
            icon={button.icon}
            disabled={button.count === 0}
          />
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default React.memo(GroupTabs);
