const styles = {
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '120%',
    textTransform: 'capitalize'
  },
  statsContainer: { ml: 1.2, mt: 1.3, width: '100%' },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
    mt: 0.5
  },
  subHeaderPipeGreyIcon: { color: 'grey.700', height: 54, width: 'auto' },
  primaryButtonIcon: { height: 16, width: 16, color: 'primary.700' },
  primaryButton: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 600,
    ml: 0.5,
    color: 'primary.700'
  },
  emaChip: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'gray.0',
    '& span': {
      px: 0.5,
      pt: 0.1
    },
    bgcolor: 'blue.cyan'
  },
  euFlagIcon: { fontSize: 18, ml: 1 }
};

export default styles;
