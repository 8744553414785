import { createSvgIcon } from '@mui/material';

const KorFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_9027_3594)'>
      <path d='M0 0H18V12H0V0Z' fill='white' />
      <path
        d='M2.75879 3.62012L4.45753 1.14646L4.86489 1.42944L3.16615 3.90309L2.75879 3.62012ZM3.36984 4.04458L5.06857 1.57093L5.47594 1.8539L3.7772 4.32755L3.36984 4.04458ZM3.98089 4.46904L5.67962 1.99539L6.08699 2.27836L4.38825 4.75202L3.98089 4.46904Z'
        fill='black'
      />
      <path
        d='M11.9111 9.7218L13.6099 7.24815L14.0172 7.53112L12.3185 10.0048L11.9111 9.7218ZM12.5222 10.1463L14.2209 7.67261L14.6283 7.95559L12.9295 10.4292L12.5222 10.1463ZM13.1332 10.5707L14.832 8.09707L15.2393 8.38005L13.5406 10.8537L13.1332 10.5707Z'
        fill='black'
      />
      <path d='M12.5361 8.35754L14.6163 9.74429L12.5361 8.35754Z' fill='black' />
      <path d='M12.5361 8.35754L14.6163 9.74429' stroke='white' strokeWidth='0.25' />
      <path
        d='M6.50391 4.29492C6.95411 3.6438 7.64711 3.19683 8.43044 3.05234C9.21377 2.90786 10.0233 3.07769 10.6809 3.52448C11.3385 3.97128 11.7903 4.65843 11.9369 5.43478C12.0835 6.21112 11.913 7.01307 11.4628 7.6642L6.50391 4.29492Z'
        fill='#CD2E3A'
      />
      <path
        d='M6.53782 4.31676C6.08749 4.96861 5.91591 5.77211 6.06083 6.55051C6.20576 7.3289 6.65531 8.01844 7.31059 8.46742C7.96587 8.9164 8.77321 9.08805 9.555 8.94461C10.3368 8.80116 11.029 8.35437 11.4793 7.70253C11.7045 7.37661 11.7903 6.97486 11.7178 6.58566C11.6453 6.19646 11.4206 5.8517 11.0929 5.6272C10.7653 5.40271 10.3616 5.31689 9.97072 5.38861C9.57983 5.46033 9.23373 5.68373 9.00856 6.00965L6.53782 4.31676Z'
        fill='#0047A0'
      />
      <path
        d='M8.9841 6.01712C9.45245 5.33728 9.28049 4.40597 8.60002 3.937C7.91955 3.46803 6.98825 3.63898 6.5199 4.31883C6.05155 4.99868 6.22351 5.92998 6.90398 6.39895C7.58445 6.86792 8.51575 6.69697 8.9841 6.01712Z'
        fill='#CD2E3A'
      />
      <path
        d='M4.45801 10.8536L2.75927 8.37998L3.16664 8.09701L4.86537 10.5707L4.45801 10.8536ZM5.06906 10.4292L3.37032 7.95552L3.77769 7.67255L5.47642 10.1462L5.06906 10.4292ZM5.68011 10.0047L3.98137 7.53106L4.38873 7.24809L6.08747 9.72174L5.68011 10.0047Z'
        fill='black'
      />
      <path
        d='M13.6104 4.75183L11.9116 2.27818L12.319 1.9952L14.0177 4.46886L13.6104 4.75183ZM14.2214 4.32737L12.5227 1.85372L12.93 1.57074L14.6288 4.04439L14.2214 4.32737ZM14.8324 3.90291L13.1337 1.42925L13.5411 1.14628L15.2398 3.61993L14.8324 3.90291Z'
        fill='black'
      />
      <path
        d='M4.12598 9.27966L4.74835 8.86115L4.12598 9.27966ZM12.528 3.62971L13.2541 3.14145L12.528 3.62971ZM13.8765 2.72293L14.4988 2.30442L13.8765 2.72293Z'
        fill='black'
      />
      <path
        d='M4.12598 9.27966L4.74835 8.86115M12.528 3.62971L13.2541 3.14145M13.8765 2.72293L14.4988 2.30442'
        stroke='white'
        strokeWidth='0.25'
      />
    </g>
    <defs>
      <clipPath id='clip0_9027_3594'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'KorFlagIcon'
);

export default KorFlagIcon;
