import React, { memo, useMemo, useCallback, useState, useContext, useRef, useEffect } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Divider,
  Typography,
  Button,
  Stack,
  Tooltip
} from '@mui/material';
import { isEqual, isEmpty } from 'lodash';

import {
  SetDefaultSourcesProps,
  SourceCheckboxProps,
  SelectedSourceOptions,
  SourceMap
} from '../types';

import ResultsStore from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import CustomCheckbox from '../../../components/CustomComponents/Checkbox';

import { SOURCE_MENU_ITEMS } from '../const';

// styles
import setDefaultSourcesStyles from '../styles/SetDefaultSources.styles';

import { CrossIcon } from '../../../assets/svgs/Icons';

const SourceCheckbox: React.FC<SourceCheckboxProps> = ({
  source,
  selectedSourceOptions,
  handleChildCheckbox,
  handleParentCheckbox
}) => {
  type SourceKey = keyof typeof setDefaultSourcesStyles.sourceCheckbox;

  const isParentIndeterminate =
    selectedSourceOptions?.[source.value]?.length > 0 &&
    selectedSourceOptions?.[source.value]?.length < source.module.length;

  const isParentChecked = selectedSourceOptions?.[source.value]?.length === source.module.length;

  return (
    <Box
      sx={{
        ...setDefaultSourcesStyles.sourceContainer,
        ...setDefaultSourcesStyles.sourceCheckbox[source.value as SourceKey]
      }}
      key={source.value}>
      <Stack
        direction='row'
        alignItems='center'
        sx={setDefaultSourcesStyles.parentContainer}
        onClick={() => handleParentCheckbox(source.value)}>
        <CustomCheckbox
          value={source.label}
          name='radio-buttons'
          size='small'
          indeterminate={isParentIndeterminate}
          checked={isParentChecked}
          sx={setDefaultSourcesStyles.checkbox}
        />
        <Tooltip title={source.tooltip}>
          <Typography sx={setDefaultSourcesStyles.parentLabelText}>{source.label}</Typography>
        </Tooltip>
      </Stack>
      <Box sx={setDefaultSourcesStyles.childrenContainer}>
        {source.module.map(currentModule => (
          <Box
            key={currentModule.label}
            sx={setDefaultSourcesStyles.childCheckboxContainer}
            onClick={() => handleChildCheckbox(source.value, currentModule.value)}>
            <CustomCheckbox
              value={currentModule.label}
              name='radio-buttons'
              size='small'
              checked={
                selectedSourceOptions?.[source.value]?.includes(currentModule.value) ?? false
              }
              sx={setDefaultSourcesStyles.checkbox}
            />
            <Tooltip title={currentModule.tooltip}>
              <Typography sx={setDefaultSourcesStyles.childLabelText}>
                {currentModule.label}
              </Typography>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const SetDefaultSources: React.FC<SetDefaultSourcesProps> = ({ isOpen, handleClose }) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [isScrollable, setIsScrollable] = useState(false);
  const buttonsSectionRef = useRef<HTMLDivElement>(null);
  const { dispatch } = useContext(GlobalStore) as any;
  const DEFAULT_SOURCE_OPTION = useMemo(() => {
    return {
      usfda: ['sba']
    };
  }, []);

  useEffect(() => {
    const checkResetButtonVisibility = () => {
      if (!buttonsSectionRef?.current) {
        return;
      }

      const { bottom } = buttonsSectionRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      setIsScrollable(bottom > windowHeight);
    };

    if (isOpen) {
      setTimeout(checkResetButtonVisibility, 0); // Delay the check to ensure the DOM is updated
    }
  }, [isOpen]);

  const selectedDefaultSources = useMemo(() => {
    const localStorageDefaultSources = JSON.parse(
      localStorage.getItem('SDS_defaultSources') ?? '{}'
    ) as SelectedSourceOptions;

    if (isEmpty(localStorageDefaultSources)) {
      return DEFAULT_SOURCE_OPTION;
    }

    return localStorageDefaultSources;
  }, [resultsState?.defaultSources]);

  const [selectedSources, setSelectedSources] =
    useState<SelectedSourceOptions>(selectedDefaultSources);

  const sources = useMemo(() => {
    const sourceMap: SourceMap = {};

    SOURCE_MENU_ITEMS.forEach(source => {
      sourceMap[source.value] = source;
    });

    return sourceMap;
  }, []);

  const removeModule = useCallback((source: string, module: string) => {
    setSelectedSources(prevState => {
      const filteredModules = prevState[source].filter(selectedModule => selectedModule !== module);
      if (filteredModules.length === 0) {
        const { [source]: _, ...newState } = prevState;
        return newState;
      }
      return { ...prevState, [source]: filteredModules };
    });
  }, []);

  const addModule = useCallback((source: string, module: string) => {
    setSelectedSources(prevState => ({
      ...prevState,
      [source]: [...(prevState[source] ?? []), module]
    }));
  }, []);

  const handleChildCheckbox = useCallback(
    (source: string, module: string) => {
      const isModuleSelected = selectedSources?.[source]?.includes(module);
      if (isModuleSelected) {
        removeModule(source, module);
      } else {
        addModule(source, module);
      }
    },
    [selectedSources, removeModule, addModule]
  );

  const handleParentCheckbox = useCallback(
    (source: string) => {
      const isSourceSelected = selectedSources?.[source]?.length > 0;
      if (isSourceSelected) {
        setSelectedSources(prevState => {
          const { [source]: _, ...newState } = prevState;
          return newState;
        });
      } else {
        setSelectedSources(prevState => ({
          ...prevState,
          [source]: sources[source].module.map(module => module.value)
        }));
      }
    },
    [selectedSources, sources]
  );

  const handleReset = useCallback(() => {
    setSelectedSources(DEFAULT_SOURCE_OPTION);
  }, []);

  const handleApply = useCallback(() => {
    if (Object.keys(selectedSources).length === 0) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'You need to select at least one option' }
      });
      return;
    }

    localStorage.setItem('SDS_defaultSources', JSON.stringify(selectedSources));
    resultsDispatch({
      type: ResultsActions.SET_DEFAULT_SOURCES,
      value: selectedSources
    });
    handleClose();
  }, [selectedSources, handleClose]);

  const isApplyButtonDisabled = useMemo(() => {
    return (
      isEqual(selectedDefaultSources, selectedSources) || Object.keys(selectedSources).length === 0
    );
  }, [selectedDefaultSources, selectedSources]);

  const applyButtonTooltip = useMemo(() => {
    if (isEqual(selectedDefaultSources, selectedSources)) {
      return 'Please add or remove sources to apply changes';
    }

    if (Object.keys(selectedSources).length === 0) {
      return 'You need to select at least one option to apply changes';
    }

    return '';
  }, [selectedDefaultSources, selectedSources]);

  const isResetButtonDisabled = useMemo(() => {
    return isEqual(selectedSources, DEFAULT_SOURCE_OPTION);
  }, [selectedSources]);

  return (
    <Drawer
      anchor='bottom'
      open={isOpen}
      onClose={() => {
        setSelectedSources(selectedDefaultSources);
        handleClose();
      }}
      sx={setDefaultSourcesStyles.dialog}>
      <Stack spacing={3}>
        <Stack>
          <Stack direction='row' justifyContent='center' alignItems='center'>
            <Typography sx={setDefaultSourcesStyles.title}>Set Default Sources</Typography>
            <Box sx={setDefaultSourcesStyles.closeButtonWrapper}>
              <Tooltip title='Close'>
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    setSelectedSources(selectedDefaultSources);
                    handleClose();
                  }}
                  sx={setDefaultSourcesStyles.closeIconButton}>
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          <Stack direction='row' justifyContent='center'>
            <Divider sx={setDefaultSourcesStyles.divider} />
          </Stack>
        </Stack>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            ...(isScrollable && setDefaultSourcesStyles.scrollableContainer)
          }}>
          <Stack spacing={2} width={250}>
            <SourceCheckbox
              source={sources.usfda}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.ca}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.ct}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
          </Stack>

          <Stack spacing={2} width={250}>
            <SourceCheckbox
              source={sources.euema}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.eu_nmr}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.uk}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
          </Stack>

          <Stack spacing={2} width={250}>
            <SourceCheckbox
              source={sources.jp}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.au}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.sec}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
            <SourceCheckbox
              source={sources.ich}
              selectedSourceOptions={selectedSources}
              handleChildCheckbox={handleChildCheckbox}
              handleParentCheckbox={handleParentCheckbox}
            />
          </Stack>
        </Stack>
        <Stack>
          <Stack direction='row' justifyContent='center' spacing={3}>
            <Tooltip
              title={
                isResetButtonDisabled
                  ? 'No changes were detected. Use reset preferences to the default configuration.'
                  : 'Reset to default configuration.'
              }
              placement='top'>
              <span>
                <Button
                  disableRipple
                  sx={setDefaultSourcesStyles.resetButton}
                  onClick={handleReset}
                  disabled={isResetButtonDisabled}>
                  Reset
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={applyButtonTooltip} placement='top'>
              <span ref={buttonsSectionRef}>
                <Button
                  disableRipple
                  sx={setDefaultSourcesStyles.applyButton}
                  onClick={handleApply}
                  disabled={isApplyButtonDisabled}>
                  Apply
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default memo(SetDefaultSources);
