import React from 'react';
import { Stack, Tooltip, Typography, Box, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import styles from '../../styles/Documents.styles';
import { DocumentDetail } from '../../types';

interface HeaderProps {
  activeIngredient: string;
  // eslint-disable-next-line no-unused-vars
  setDocumentDetails: (value: DocumentDetail | Record<string, never>) => void;
}

const Header = ({ activeIngredient, setDocumentDetails }: HeaderProps) => {
  const getHeaderText = () =>
    activeIngredient && activeIngredient !== 'NA'
      ? activeIngredient.replace(/\b\w/g, (char: string) => char.toUpperCase())
      : 'NA';

  return (
    <Stack sx={styles.headerWrapper}>
      <Stack width='80%' direction='row'>
        <Tooltip title={getHeaderText()}>
          <Typography sx={styles.headerTitle}>{getHeaderText()}</Typography>
        </Tooltip>
      </Stack>
      <Box>
        <Tooltip title='Close Chat'>
          <IconButton size='small' onClick={() => setDocumentDetails({})}>
            <Close sx={{ fontSize: 27.45, color: 'gray.0' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default React.memo(Header);
