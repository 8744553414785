import React, { useEffect, useState } from 'react';

import { Box, Dialog, DialogContent, IconButton, Stack, Typography, Tooltip } from '@mui/material';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import DraftableComparisonView from '../../../Comparison/DraftableComparisonView';

import comparisonClasses from '../../../Comparison/styles/LabelSelection.style';

import { getLabelComparision } from '../../../../api/pages/Reg360';
import getComparisonFile from '../../../../helpers/draftableUtils';
import { DocumentItem } from '../types';
import ReportingDrawer from '../../../Report/ReportingDrawer';

interface CompareDialogProps {
  open: boolean;
  compareItems: DocumentItem[];
  // eslint-disable-next-line no-unused-vars
  onClose: (event: any) => void;
}

const ComparisonDialog: React.FC<any> = ({ open, compareItems, onClose }: CompareDialogProps) => {
  const [viewURL, setViewURL] = useState('');
  const [openReporting, setOpenReporting] = React.useState<boolean>(false);
  const [parentPDFURL, setParentPDFURL] = useState('');
  const [childPDFURL, setChildPDFURL] = useState('');

  useEffect(() => {
    (async () => {
      const parentPDFLinkPromise = compareItems[0].getPdfUrl
        ? await compareItems[0].getPdfUrl(compareItems[0].title_link as string)
        : compareItems[0].title_link;

      const childPDFLinkPromise = compareItems[1].getPdfUrl
        ? compareItems[1].getPdfUrl(compareItems[1].title_link as string)
        : compareItems[1].title_link;

      const parentPDFLink = (await parentPDFLinkPromise) as string;
      const childPDFLink = (await childPDFLinkPromise) as string;

      setParentPDFURL(parentPDFLink);
      setChildPDFURL(childPDFLink);

      if (compareItems.length === 2 && open) {
        const res = await getLabelComparision({
          parent_object: parentPDFLink,
          child_object: childPDFLink
        });
        if (res.data.success !== null) {
          setViewURL(res.data.success);
        }
      }
    })();
  }, [compareItems, open]);

  const downloadComparison = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  if (openReporting) {
    return (
      <ReportingDrawer
        isDrawerOpen={openReporting}
        closeDrawer={() => {
          setOpenReporting(false);
        }}
        isNavBar
      />
    );
  }
  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      aria-labelledby='customized-dialog-title'
      sx={comparisonClasses.dialog}
      open={open}
      onClose={onClose}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={comparisonClasses.headerWrapper}>
        <Box />
        <Stack direction='row' spacing={1}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            Documents Comparison
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton aria-label='delete' onClick={onClose} size='small'>
            <HighlightOffOutlinedIcon
              fontSize='inherit'
              sx={comparisonClasses.highlightOffOutlinedIcon}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <DialogContent sx={comparisonClasses.dialogContent}>
        {viewURL ? (
          <DraftableComparisonView
            viewerURL={viewURL}
            downloadFile={downloadComparison}
            parentPdfLink={parentPDFURL}
            childPdfLink={childPDFURL}
            parentApplicationNumber={compareItems[0]?.title}
            childApplicationNumber={compareItems[1]?.title}
            parentApplicationName={compareItems[0]?.title}
            childApplicationName={compareItems[1]?.title}
            setOpenReporting={setOpenReporting}
            enableChatRia
            comparsionType='guidance'
            parentApplication={{
              guidance_title: compareItems[0]?.filename,
              guidance_date: compareItems[0]?.doc_date
            }}
            childApplication={{
              guidance_title: compareItems[1]?.filename,
              guidance_date: compareItems[1]?.doc_date
            }}
          />
        ) : (
          <Box sx={comparisonClasses.progressContainer}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ComparisonDialog);
