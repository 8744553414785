import React, { useCallback, useEffect, useState } from 'react';

// mui
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CSVLink } from 'react-csv';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

// custom components
import VivproDatagrid from '../../../components/Datagrid';

// utils
import { DATASTATS_COLUMNS_MAPPINGS, FAQ_LIST } from '../utils/constants';
import downloadHeading from '../utils/downloadHeadingWithStats';
import { getFileName } from '../../../helpers/getFileName';
import formatDateInUTC from '../utils/formatDateInUTCformat';
// styles
import styles from '../styles/Content.styles';

// api
import { getDataStats } from '../../../api/pages/FAQDbStats';
import { getDownloadData } from '../../../api/pages/HomePage';

// constants
import { TIMELINE_HEADERS } from '../../../components/Timeline/constants';

const FaqContent = () => {
  const [statsData, setStatsData] = useState([]);
  const [canadaDownload, setCanadaDownload] = useState(false);
  const [canadaData, setCanadaData] = useState([]);
  const [filename, setFilename] = useState({
    prefix: `download`,
    module: `ca`,
    postfix: new Date().toISOString().split('T')[0],
    additional: '_clinical_information_docs',
    extension: 'csv'
  });
  const [selectedSubTopic, setSelectedSubTopic] = useState('Access and Support');

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getDataStats();
        response.data.Success.forEach(item => {
          if (item.updated_at) {
            const date = new Date(item.updated_at);
            item.updated_at = formatDateInUTC(date);
          } else {
            item.updated_at = '-';
          }
          if (item.next_refresh) {
            const date = new Date(item.next_refresh);
            item.next_refresh = formatDateInUTC(date);
          } else {
            item.next_refresh = '-';
          }
        });
        // TODO: Remove CDE from the list during the final release
        const finalData = response.data.Success.filter(x => x?.source !== 'CDE');
        setStatsData(finalData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    getData();
  }, [getDataStats]);

  useEffect(() => {
    if (canadaDownload) {
      document.getElementById('csvDownloadBtn')?.click();
      setCanadaDownload(false);
    }
  }, [canadaDownload]);

  const handleDownloadCanada = useCallback(
    async (source, descriptor) => {
      const day = new Date();
      try {
        const response = await getDownloadData(`${source}/${descriptor}`);
        if (response) {
          if (response.data.success !== '' && Object.keys(response.data.success).length !== 0) {
            setCanadaData([...response.data.success]);
            setFilename({
              prefix: `ria`,
              module: `${source}`,
              postfix: `${day.getMonth() + 1}-${day.getDate()}-${day.getFullYear()}`,
              additional: '_clinical_information_docs',
              extension: 'csv'
            });
            setCanadaDownload(true);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [canadaDownload, canadaData]
  );

  return (
    <Container>
      <Stack direction='row' spacing={2} justifyContent='center'>
        {FAQ_LIST?.map(faq => (
          <Button
            key={faq?.title}
            onClick={() => setSelectedSubTopic(faq?.title)}
            sx={{
              ...styles.btn,
              border: faq?.title === selectedSubTopic ? 0 : '1px solid'
            }}
            style={{
              backgroundColor: faq?.title === selectedSubTopic ? '#E1F3F1FF' : '#FFFFFF',
              borderColor: '#E0E0E0'
            }}>
            <Typography
              fontSize={13}
              fontWeight={faq?.title === selectedSubTopic ? 700 : 400}
              color={faq?.title === selectedSubTopic ? 'primary.700' : 'gray.800'}>
              {faq.title}
            </Typography>
          </Button>
        ))}
      </Stack>
      <Stack spacing={2} my={5}>
        {FAQ_LIST?.filter(each => each?.title === selectedSubTopic)?.map(item => (
          <Stack key={item.title} spacing={2}>
            {item.content?.map(faq => (
              <Accordion
                key={faq?.id}
                disableGutters={false}
                elavation={0}
                square
                sx={styles.accordian}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize='medium' sx={styles.expandIcon} />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'>
                  <Typography sx={styles.title}>{faq.summary}</Typography>
                </AccordionSummary>
                {faq.datagrid ? (
                  <AccordionDetails>
                    <Stack sx={styles.root} mt={2} pr={2}>
                      <Box sx={{ height: '400px' }}>
                        <VivproDatagrid
                          rows={statsData}
                          columnsMapping={DATASTATS_COLUMNS_MAPPINGS}
                          rowId='_id'
                          csvFileName='vivpro_data_stats'
                          noRowMessage='No rows selected'
                          loading={false}
                        />
                      </Box>
                    </Stack>
                  </AccordionDetails>
                ) : (
                  <AccordionDetails>
                    <Typography
                      sx={styles.description}
                      dangerouslySetInnerHTML={{ __html: faq.description }}>
                      {/* {faq.description} */}
                    </Typography>
                    {faq.download &&
                      faq.download.map(downloadItem => {
                        return (
                          <Box key={downloadItem.description}>
                            <Typography
                              variant='subtitle1'
                              fontWeight='bold'
                              sx={styles.description}>
                              {statsData.length > 0 && downloadItem.statsId
                                ? downloadHeading(
                                    statsData,
                                    downloadItem.statsId,
                                    downloadItem.heading
                                  )
                                : downloadItem.heading}
                            </Typography>
                            <Typography key={downloadItem.description}>
                              <Tooltip TransitionComponent={Zoom} title={downloadItem.tooltip}>
                                <Typography
                                  sx={styles.downloadText}
                                  onClick={() =>
                                    handleDownloadCanada(
                                      downloadItem.source,
                                      downloadItem.description
                                    )
                                  }>
                                  Click to Download
                                </Typography>
                              </Tooltip>
                            </Typography>
                            {canadaDownload && (
                              <CSVLink
                                id='csvDownloadBtn'
                                data={canadaData}
                                filename={getFileName(filename)}
                                headers={
                                  TIMELINE_HEADERS?.[downloadItem.source?.toLowerCase()]
                                    ?.clinical_information_details || []
                                }
                              />
                            )}
                          </Box>
                        );
                      })}
                    {faq.image && <img src={faq.image} alt='guide' style={styles.image} />}
                    {faq.table && (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              {faq.table.headers?.map(title => (
                                <TableCell key={title}>{title}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {faq.table.rows?.map(row => (
                              <TableRow
                                key={row.term}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row'>
                                  {row.term}
                                </TableCell>
                                <TableCell>{row.definition}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {faq.list?.map(row => {
                      return (
                        <>
                          <Typography variant='subtitle1' fontWeight='bold' sx={styles.description}>
                            {row.label}
                          </Typography>
                          <ul>
                            {row.items?.map(text => (
                              <li key={text}>
                                <Typography variant='subtitle1'>{text}</Typography>
                              </li>
                            ))}
                          </ul>
                        </>
                      );
                    })}
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
          </Stack>
        ))}
      </Stack>
    </Container>
  );
};

export default FaqContent;
