import { Box, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import CloseCrossIcon from '../../../assets/svgs/Icons/CloseCrossIcon';
import styles from '../styles/WorkingList.styles';
import ListSection from '../../../components/CustomComponents/ListSections/ListSection';

const Section = ({ sectionList }: { sectionList: Array<any> }) => {
  return (
    <Stack
      direction='column'
      spacing={1}
      alignItems='flex-start'
      component='ul'
      sx={{ pl: 2.5, m: 0 }}>
      {sectionList?.map((item: string) => (
        <span key={item} style={{ display: 'inline-block', fontWeight: 400 }}>
          {item}
        </span>
      ))}
    </Stack>
  );
};

const WorkingList = ({
  openDrawer,
  onClose,
  data
}: {
  openDrawer: boolean;
  onClose: () => void;
  data: any;
}) => {
  const WG_LIST = [
    {
      label: 'Rapporteur',
      value: data?.wg_list?.rapporteur,
      content: <Section sectionList={data?.wg_list?.rapporteur} />
    },
    {
      label: 'Regulatory Chair',
      value: data?.wg_list?.regulatory_chair,
      content: <Section sectionList={data?.wg_list?.regulatory_chair} />
    },
    {
      label: 'Rapporteur Supporter',
      value: data?.wg_list?.rapporteur_supporter,
      content: <Section sectionList={data?.wg_list?.rapporteur_supporter} />
    }
  ];

  const expertsList = useMemo(() => {
    const labelMap: Record<string, { names: string[]; sections: any }> = {};

    data?.experts?.forEach((item: any) => {
      const label = item?.party || 'Others';
      const name = item?.name;

      if (!labelMap[label]) {
        labelMap[label] = { names: [], sections: [] };
      }

      labelMap[label].names.push(name);
      labelMap[label].sections.push(<Section sectionList={name} />);
    });

    // Convert labelMap into the desired structure
    const dataList: Array<any> = Object.entries(labelMap).map(([label, { names, sections }]) => ({
      label,
      value: names,
      content: sections
    }));

    return dataList;
  }, [data]);
  return (
    <Drawer
      open={openDrawer}
      anchor='right'
      transitionDuration={500}
      hideBackdrop={false}
      sx={styles.mainDrawer}
      onClose={onClose}>
      <Box bgcolor='primary.600' py={1.5} px={2.5}>
        <Stack direction='row' alignItems='center'>
          <Tooltip title={data?.title}>
            <Typography sx={styles.drawerTitle}>{data?.title}</Typography>
          </Tooltip>
          <IconButton sx={styles.crossIcon} onClick={onClose}>
            <CloseCrossIcon sx={styles.drawerHeaderIcon} />
          </IconButton>
        </Stack>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100% - 70px)'
        }}>
        <Box px={2.5} py={1.5}>
          <Typography sx={styles.headingText}>Working Group List</Typography>
        </Box>
        <Box gap={2} sx={styles.infoContainer}>
          <ListSection dataList={WG_LIST} title='' showTitle={false} />
          {data?.experts?.length !== 0 && (
            <ListSection dataList={expertsList} title='Experts' showTitle />
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default React.memo(WorkingList);
