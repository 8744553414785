import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Drawer } from '@mui/material';
import GlobalStore from '../../../../store';
import styles from '../../styles/Documents.styles';
import Headers from './Headers';
import Overview from './Overview';
import Details from './Details';
import DocumentViewer from './DocumentViewer';
import { INITIAL_DOCUMENT_COMPARISON_DETAILS, INITIAL_DOCUMENT_DATA } from '../../contants';
import { Document, DocumentComparisonData, DocumentDataProp, DocumentProp } from '../../types';
import { isEmptyObject } from '../../utils';

const Documents = ({ documentDetails, setDocumentDetails, partyName }: DocumentProp) => {
  const [documentData, setDocumentData] = useState<DocumentDataProp>(INITIAL_DOCUMENT_DATA);
  const [compareEnabled, setCompareEnabled] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<Record<string, boolean> | Record<string, never>>({});
  const [parentData, setParentData] = useState<DocumentComparisonData>(
    INITIAL_DOCUMENT_COMPARISON_DETAILS
  );
  const [childData, setChildData] = useState<DocumentComparisonData>(
    INITIAL_DOCUMENT_COMPARISON_DETAILS
  );
  const { state } = useContext(GlobalStore) as any;

  const setInitialExpandedData = () => {
    const expandDocument: Document | Record<string, never> =
      documentDetails?.documents?.find(each =>
        partyName ? each?.party_name === partyName : each?.is_ich
      ) ?? {};
    if (Object.keys(expandDocument)?.length > 0) {
      setExpanded({ [expandDocument?.group_by_category_name]: true });
    }
  };

  useEffect(() => {
    setInitialExpandedData();
  }, [partyName]);

  const getDrawerWidth: any = () => {
    if (documentData?.isDocumentOpen) {
      return '46%';
    }
    if (state?.chatRiaOpen) {
      return '0';
    }
    return '50%';
  };

  const selectedDocumentCount = useMemo(() => {
    let count = 0;
    if (!isEmptyObject(parentData)) {
      count += 1;
    }
    if (!isEmptyObject(childData)) {
      count += 1;
    }
    return count;
  }, [parentData, childData]);

  const sortDocuments = (data: Record<string, Record<string, Document[]>>) => {
    const sortedKeys = Object.keys(data).sort((a, b) => {
      // Check if any `is_ich` is true within the nested structure
      const aHasIch = Object.values(data[a])
        .flat()
        .some(doc => doc.is_ich);
      const bHasIch = Object.values(data[b])
        .flat()
        .some(doc => doc.is_ich);

      if (aHasIch !== bHasIch) {
        return aHasIch ? -1 : 1; // Groups with `is_ich: true` come first
      }

      return a.localeCompare(b); // Alphabetical order otherwise
    });

    // Create a new sorted object
    return sortedKeys.reduce((acc: Record<string, Record<string, Document[]>>, key) => {
      acc[key] = data[key];
      return acc;
    }, {});
  };

  const groupDataByDocCategory = (data: Record<string, Document[]>) => {
    return Object.entries(data).reduce(
      (groupedData: Record<string, Record<string, Document[]>>, [group, documents]) => {
        const docCategoryGroup = documents.reduce((acc: Record<string, Document[]>, doc) => {
          return {
            ...acc,
            [doc.doc_category]: [...(acc[doc.doc_category] || []), doc]
          };
        }, {});

        return {
          ...groupedData,
          [group]: docCategoryGroup
        };
      },
      {}
    );
  };

  const formattedDocuments = useMemo(() => {
    const groupedDocuments = documentDetails?.documents?.reduce(
      (acc: Record<string, Document[]>, item) => {
        const groupName = item.group_by_category_name;
        acc[groupName] = acc[groupName] || [];
        acc[groupName].push(item);
        return acc;
      },
      {}
    );

    const finalGroupedData = groupDataByDocCategory(groupedDocuments);
    const sortedGroupedData = sortDocuments(finalGroupedData);
    return sortedGroupedData;
  }, [documentDetails]);

  const handleCompareEnabled = () => {
    let expandedItems = {};
    Object.keys(formattedDocuments)?.forEach(key => {
      expandedItems = { ...expandedItems, [key]: true };
    });
    setExpanded(expandedItems);
    setCompareEnabled(true);
    setDocumentData(INITIAL_DOCUMENT_DATA);
  };

  const isMultipleDocumentAvailable = documentDetails?.documents?.length > 1;

  const handleExitCompare = () => {
    setCompareEnabled(false);
    setInitialExpandedData();
    setParentData(INITIAL_DOCUMENT_COMPARISON_DETAILS);
    setChildData(INITIAL_DOCUMENT_COMPARISON_DETAILS);
  };

  return (
    <Drawer
      anchor='right'
      open
      transitionDuration={500}
      hideBackdrop={false}
      sx={{
        ...styles.document.drawer,
        '& >.MuiDrawer-paper': {
          width: {
            md: getDrawerWidth()
          },
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12,
          overflow: 'hidden'
        }
      }}>
      <Headers
        activeIngredient={`${documentDetails?.code}: ${documentDetails?.title}`}
        setDocumentDetails={setDocumentDetails}
      />
      <Overview
        // @ts-ignore
        documentDetails={documentDetails}
        compareEnabled={compareEnabled}
        handleCompareEnabled={handleCompareEnabled}
        handleExitCompare={handleExitCompare}
        totalSelectedDocument={selectedDocumentCount}
        parentData={parentData}
        setParentData={setParentData}
        childData={childData}
        setChildData={setChildData}
        isMultipleDocumentAvailable={isMultipleDocumentAvailable}
      />
      <Details
        formattedDocuments={formattedDocuments}
        documentData={documentData}
        setDocumentData={setDocumentData}
        compareEnabled={compareEnabled}
        expanded={expanded}
        setExpanded={setExpanded}
        parentData={parentData}
        setParentData={setParentData}
        childData={childData}
        setChildData={setChildData}
        documentDetails={documentDetails}
      />
      <DocumentViewer documentData={documentData} setDocumentData={setDocumentData} />
    </Drawer>
  );
};

export default React.memo(Documents);
