import makeRequest from '../client';
import { SignedUrlPayload } from '../../pages/ICHGuidelines/types';

const fetchICHGuidelines = (filters: any) =>
  makeRequest(`/ich-guideline/details`, 'POST', { filters });

export const getICHGuidanceDocumentsSignedURL = (payload: SignedUrlPayload) =>
  makeRequest('/ich-guideline/resource', 'POST', payload);

export const getEMAGuidanceDocumentsSignedURL = (payload: SignedUrlPayload) =>
  makeRequest('/ema-guidances/resource', 'POST', payload);

export const getCanadaGuidanceDocumentsSignedURL = (payload: SignedUrlPayload) =>
  makeRequest('/canada-guidance/resource', 'POST', payload);

export const getGuidanceDocumentsSignedURL = (payload: SignedUrlPayload) =>
  makeRequest('/guidance_documents/resource', 'POST', payload);

export default fetchICHGuidelines;
