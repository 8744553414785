import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import componentStyles from '../styles/Components.styles';

import { INormalTable } from '../interface';

interface NormalTableProps {
  table: INormalTable | undefined;
}

const NormalTable = ({ table }: NormalTableProps) => {
  return (
    <TableContainer component={Paper} sx={componentStyles.table.container}>
      <Table>
        <TableHead>
          <TableRow sx={componentStyles.table.headerRow}>
            {table?.headerRow?.map(header => (
              <TableCell key={header.id} align='left' sx={componentStyles.table.headerCell}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {table?.dataRows?.map(row => (
            <TableRow key={row.id}>
              {row.cells.map((cell, index) => (
                <TableCell
                  key={cell.id}
                  align='left'
                  sx={
                    index === 0
                      ? componentStyles.table.mainColumnRowCell
                      : componentStyles.table.rowCell
                  }>
                  {cell.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(NormalTable);
