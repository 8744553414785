import React from 'react';

import { Tooltip, Typography, Stack } from '@mui/material';
import { STATUS_COLOR, STATUS_MAPPING, STATUS_TEXT_COLOR } from '../contants';
import styles from '../styles/GuidelinesList.styles';
import getDateString from '../../../utils/getDateString';

const StatusChips = ({ statusData }: { statusData: any }) => {
  const status = statusData?.condition?.toLowerCase();
  const statusKey = STATUS_MAPPING[status in STATUS_MAPPING ? status : 'default'];
  const statusColor = STATUS_COLOR[statusKey];
  const statusTextColor = STATUS_TEXT_COLOR[statusKey];

  const fields = [
    { label: 'Status', value: statusData?.condition },
    { label: 'Date', value: statusData?.date ? getDateString(statusData?.date) : '' },
    { label: 'Reference', value: statusData?.reference }
  ];
  return (
    <Tooltip
      title={
        <Typography sx={styles.toolTipText}>
          {fields?.map((item: any) => {
            if (item?.value) {
              return (
                <Stack display='flex' flexDirection='row' key={item?.label}>
                  {item?.label === 'Reference' ? (
                    <span
                      style={{
                        marginTop: '8px',
                        wordBreak: 'break-word',
                        hyphens: 'auto'
                      }}>
                      <b
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          hyphens: 'auto'
                        }}>
                        {item?.label}:{' '}
                      </b>
                      <br />
                      {item?.value}
                    </span>
                  ) : (
                    <span
                      style={{
                        wordBreak: 'break-word',
                        hyphens: 'auto'
                      }}>
                      <b
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          hyphens: 'auto'
                        }}>
                        {item?.label}:{' '}
                      </b>
                      {item?.value}
                    </span>
                  )}
                </Stack>
              );
            }

            return null;
          })}
        </Typography>
      }>
      <Typography
        key={statusKey}
        sx={{
          ...styles.statusChip,
          color: statusTextColor,
          bgcolor: statusColor
        }}>
        {statusKey}
      </Typography>
    </Tooltip>
  );
};

export default React.memo(StatusChips);
