import { Box, Divider, Stack } from '@mui/material';
import React from 'react';
import styles from '../../styles/GuidelinesCards.styles';
import GuidelinesCardHeader from './GuidelineCardHeader';
import GuidelinesAccordion from './GuidelinesAccordion';
import GuidelineSectionDetails from './GuidelineSectionDetails';

const GuidelinesCards = ({
  title,
  data,
  setDocumentDetails,
  GuidelinesDetails,
  metadataList,
  onSectionExpand,
  isExpanded
}: {
  title: string;
  data: any;
  // eslint-disable-next-line no-shadow, no-unused-vars
  setDocumentDetails: (data: any) => void;
  GuidelinesDetails: React.FC<{ data: any }>;
  metadataList: Array<any>;
  // eslint-disable-next-line no-unused-vars
  onSectionExpand: (id: string) => void;
  isExpanded: boolean;
}) => {
  return (
    <Stack
      spacing={1}
      aria-label='guideline-item-card'
      width='100%'
      sx={{ margin: '0px !important' }}>
      <Box sx={styles.cardBox}>
        <GuidelinesCardHeader
          // eslint-disable-next-line no-underscore-dangle
          headerText={title}
          isDocumentsAvailable={data?.documents?.length !== 0}
          onDocumentClick={() => setDocumentDetails(data)}
        />
        <Box sx={styles.cardSection}>
          {metadataList?.length !== 0 && (
            <>
              <Box sx={styles.container}>
                <GuidelineSectionDetails data={metadataList} />
              </Box>
              <Divider orientation='horizontal' sx={styles.divider} />
            </>
          )}

          <GuidelinesAccordion
            isExpanded={isExpanded}
            onSectionExpand={onSectionExpand}
            // eslint-disable-next-line no-underscore-dangle
            idx={data?._id}
            data={data}
            GuidelinesDetails={GuidelinesDetails}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default React.memo(GuidelinesCards);
