const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      backgroundColor: 'transparent',
      elevation: 20,
      minWidth: '70vw !important'
    },
    '& .MuiDialog-paperFullScreen': {
      backgroundColor: 'transparent'
    }
  },
  closeIconButton: {
    backgroundColor: 'white !important',
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 100
  },
  video: {
    borderRadius: '10px'
  }
};

export default styles;
