import React from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
  Tooltip,
  Checkbox
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import styles from '../../styles/Documents.styles';
import {
  DropdownIcon,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon
} from '../../../../assets/svgs/Icons';
import DocumentIcon from '../../../../assets/svgs/PDCO/DocumentIcon';
import {
  DATA_BUCKET_SOURCE_MAPPING,
  INITIAL_DOCUMENT_COMPARISON_DETAILS,
  ICH_GUIDELINE_SOURCE
} from '../../contants';
import { DocumentDetailsProp, Document, DocumentComparisonData } from '../../types';
import { isEmptyObject } from '../../utils';
import formatDateVersionedTitle from '../../../CanadaGudiance/utils/formatDateVersionedTitle';

const Details = ({
  formattedDocuments,
  documentData,
  setDocumentData,
  compareEnabled,
  expanded,
  setExpanded,
  parentData,
  setParentData,
  childData,
  setChildData,
  documentDetails
}: DocumentDetailsProp) => {
  const isChecked = (s3Path: string) => {
    return [parentData?.s3Path, childData?.s3Path]?.includes(s3Path);
  };

  const handleCheckboxChange = (selectedCheckboxData: Document, isAlreadyChecked: boolean) => {
    const formattedData: DocumentComparisonData = {
      brandName: selectedCheckboxData?.doc_category,
      source:
        DATA_BUCKET_SOURCE_MAPPING?.find(each => each?.dataBucket === selectedCheckboxData?.source)
          ?.source ?? ICH_GUIDELINE_SOURCE,
      s3Path: selectedCheckboxData?.s3_path,
      signedUrl: '',
      fileName: formatDateVersionedTitle(selectedCheckboxData?.file_name)
    };
    if (!isAlreadyChecked) {
      if (isEmptyObject(parentData)) {
        setParentData(formattedData);
      } else if (isEmptyObject(childData)) {
        setChildData(formattedData);
      }
    } else if (isEqual(formattedData, { ...parentData, signedUrl: '' })) {
      setParentData(INITIAL_DOCUMENT_COMPARISON_DETAILS);
    } else if (isEqual(formattedData, { ...childData, signedUrl: '' })) {
      setChildData(INITIAL_DOCUMENT_COMPARISON_DETAILS);
    }
  };

  const handleEnableComparison = async (data: Document) => {
    const payload = { source: data?.source, s3_path: data?.s3_path };
    const filteredObj = DATA_BUCKET_SOURCE_MAPPING?.find(each => each?.dataBucket === data?.source);

    if (filteredObj) {
      const res = await filteredObj.getSignedUrl(payload);
      if (res?.status === 200) {
        setDocumentData({
          isDocumentOpen: true,
          documentTitle: data?.file_name,
          documentPath: res?.data?.body?.s3_url,
          source: filteredObj?.source,
          metadataSource: filteredObj?.metadataSource,
          step_date: documentDetails?.step_date,
          category: documentDetails?.category,
          guideline_group: documentDetails?.guideline_group
        });
      }
    }
  };

  const handleCompareEnable = async (data: Document) => {
    if (!compareEnabled) {
      handleEnableComparison(data);
    } else {
      handleCheckboxChange(data, isChecked(data?.s3_path));
    }
  };

  return (
    <Box sx={styles.detail.container}>
      {Object.keys(formattedDocuments)?.length > 0 &&
        Object.entries(formattedDocuments)?.map(([key, documents], index) => (
          <React.Fragment key={key}>
            <Accordion
              expanded={!!expanded[key]}
              onChange={() => setExpanded({ ...expanded, [key]: !expanded[key] })}
              sx={{
                ...styles.detail.accordion,
                mb: index === 0 ? 0 : 1.5,
                '&.Mui-expanded': {
                  mb: index !== 0 ? 1.5 : 0,
                  mt: 0
                }
              }}>
              <AccordionSummary
                sx={{
                  ...styles.detail.accordionSummary,
                  bgcolor: Object.values(documents)[0][0]?.is_ich
                    ? 'primary.lightVariant1'
                    : 'gray.200'
                }}
                expandIcon={
                  <DropdownIcon
                    sx={{
                      fontSize: 16
                    }}
                  />
                }>
                <Box display='flex' flexDirection='column' width='100%' pr={1}>
                  <Box ml={-0.75} display='flex' alignItems='center'>
                    <Tooltip title={key}>
                      <Typography
                        color='gray.800'
                        fontSize={14}
                        fontWeight={500}
                        mr={0.5}
                        sx={styles.common.oneLineText}>
                        {key}
                      </Typography>
                    </Tooltip>
                    <Typography color='gray.800' fontSize={14} fontWeight={700}>
                      {`(${Object.values(documents)?.[0]?.[0]?.is_ich ? 'Current' : 'References'})`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              {Object.keys(documents)?.length > 0 &&
                Object.entries(documents)?.map(
                  ([documentCategoryKey, documentCategoryValue], documentCategoryIndex) => (
                    <AccordionDetails
                      key={documentCategoryKey}
                      sx={{
                        ...styles.detail.accordionDetail,
                        pb:
                          Object.keys(documents)?.length > 0 &&
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          documentCategoryIndex === Object.keys(documents)?.length - 1
                            ? 3
                            : 0.5
                      }}>
                      <Box>
                        <Typography color='gray.900' fontSize={14} fontWeight={700}>
                          {documentCategoryKey}
                        </Typography>
                        {documentCategoryValue?.length > 0 &&
                          documentCategoryValue?.map((document, documentIndex) => (
                            <Box
                              // eslint-disable-next-line react/no-array-index-key
                              key={documentIndex}
                              onClick={() => handleCompareEnable(document)}
                              sx={{
                                ...styles.detail.accordionDetailSubCategoryContainer,
                                borderColor:
                                  documentData?.documentTitle === document?.file_name
                                    ? 'primary.300'
                                    : 'gray.200'
                              }}>
                              {compareEnabled ? (
                                <Checkbox
                                  checked={isChecked(document?.s3_path)}
                                  value={isChecked(document?.s3_path)}
                                  size='small'
                                  disabled={
                                    !isEmptyObject(parentData) &&
                                    !isEmptyObject(childData) &&
                                    !isChecked(document?.s3_path)
                                  }
                                  checkedIcon={
                                    <CheckboxCheckedIcon
                                      sx={{
                                        color: 'primary.600',
                                        fontSize: 14
                                      }}
                                    />
                                  }
                                  icon={<CheckboxUncheckedIcon sx={{ fontSize: 14, p: 0 }} />}
                                  sx={{
                                    '&.Mui-disabled': {
                                      color: 'grey.400'
                                    },
                                    p: 0
                                  }}
                                />
                              ) : (
                                <DocumentIcon sx={{ fontSize: 14, color: 'secondary.700' }} />
                              )}
                              <Tooltip title={document?.file_name}>
                                <Typography
                                  ml={1.5}
                                  color='gray.700'
                                  fontSize={14}
                                  fontWeight={600}
                                  sx={styles.common.twoLineText}>
                                  {document?.source === 'ca_guidance'
                                    ? formatDateVersionedTitle(document?.file_name)
                                    : document?.file_name}
                                </Typography>
                              </Tooltip>
                            </Box>
                          ))}
                      </Box>
                    </AccordionDetails>
                  )
                )}
            </Accordion>
            {index === 0 && Object.keys(formattedDocuments)?.length > 1 && (
              <Divider orientation='horizontal' sx={{ width: '100%', my: 1.5 }} />
            )}
          </React.Fragment>
        ))}
    </Box>
  );
};

export default React.memo(Details);
