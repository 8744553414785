import { memo } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Stack,
  Typography
} from '@mui/material';

import { Person } from '@mui/icons-material';

import componentStyles from '../styles/Components.styles';
import articleStyles from '../styles/Article.styles';

import { IHistory } from '../interface';

interface HistoryProps {
  history: IHistory[];
}

const History = ({ history }: HistoryProps) => {
  return (
    <TableContainer component={Paper} sx={componentStyles.historyTable.container}>
      <Table>
        <TableHead>
          <TableRow sx={componentStyles.historyTable.headerRow}>
            <TableCell align='left' sx={componentStyles.historyTable.headerCell}>
              Date Modified
            </TableCell>
            <TableCell align='left' sx={componentStyles.historyTable.headerCell}>
              Changes
            </TableCell>
            <TableCell align='left' sx={componentStyles.historyTable.headerCell}>
              Author
            </TableCell>
            <TableCell align='left' sx={componentStyles.historyTable.headerCell}>
              View
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map(item => (
            <TableRow key={item.id} sx={componentStyles.historyTable.normalRow}>
              <TableCell align='left' sx={componentStyles.historyTable.rowCell}>
                {item.dateModified.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  timeZone: 'UTC'
                })}
              </TableCell>
              <TableCell align='left' sx={componentStyles.historyTable.rowCell}>
                {item.changes}
              </TableCell>
              <TableCell align='left' sx={componentStyles.historyTable.rowCell}>
                <Stack direction='row' gap={0.75} alignItems='center'>
                  <Avatar sx={articleStyles.avatar}>
                    <Person sx={articleStyles.personIcon} />
                  </Avatar>
                  <Typography sx={componentStyles.historyTable.author}>{item.author}</Typography>
                </Stack>
              </TableCell>
              <TableCell
                align='left'
                sx={{
                  ...componentStyles.historyTable.rowCell,
                  ...(item?.changeType === 'created' && { color: 'gray.400' })
                }}>
                {item.view}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(History);
