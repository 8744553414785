import { createSvgIcon } from '@mui/material';

const JpnFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 12' fill='none'>
    <g clipPath='url(#clip0_8986_519)'>
      <path d='M0 0H18V12H0V0Z' fill='white' />
      <path d='M0 0H18V4H0V0Z' fill='black' />
      <path d='M0 8H18V12H0V8Z' fill='#007A3D' />
      <path
        d='M9 6L0 12V0M2.78036 5.14286L2.64107 5.61429L2.27857 5.46667L2.46786 5.90476L2.15357 6.19048L2.53036 6.26667L2.50179 6.77143L2.78036 6.42857L3.06071 6.77143L3.03214 6.26667L3.40714 6.19048L3.09464 5.90476L3.28393 5.46667L2.92143 5.61429L2.78036 5.14286Z'
        fill='#CE1126'
      />
    </g>
    <defs>
      <clipPath id='clip0_8986_519'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'JpnFlagIcon'
);

export default JpnFlagIcon;
