const styles = {
  paragraph: {
    fontSize: 16,
    fontFamily: 'Mulish',
    color: 'gray.900',
    fontWeight: 400
  },
  ul: {
    list: { listStyleType: 'disc', py: 0, pl: 2 },
    listItem: {
      display: 'list-item',
      p: 0,
      '& .MuiListItemText-root': {
        m: 0
      }
    },
    listItemText: { m: 0, fontSize: 16, fontFamily: 'Mulish', color: 'gray.900', fontWeight: 400 },
    listItemTextProps: {}
  },
  header1: {
    fontSize: 18,
    fontFamily: 'Mulish',
    color: 'gray.800',
    fontWeight: 700
  },
  poll: {
    container: {
      borderRadius: 2,
      py: 2,
      px: 2.5,
      backgroundColor: 'primary.0'
    },
    title: {
      fontSize: 16,
      fontFamily: 'Mulish',
      color: 'gray.800',
      fontWeight: 700,
      mb: 2
    },
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1
    },
    avatar: {
      height: '14px',
      width: '14px',
      '& .MuiSvgIcon-root': {
        height: 10
      }
    },
    optionText: {
      fontSize: 14,
      fontFamily: 'Mulish',
      color: 'gray.600',
      fontWeight: 500,
      minWidth: 128
    },
    progressBar: {
      height: 8,
      borderRadius: 0.5,
      backgroundColor: 'gray.300'
    }
  },
  referenceNumber: {
    ml: 0.5,
    mb: 0.375,
    backgroundColor: 'primary.50',
    height: 16,
    width: 16,
    fontSize: 12,
    display: 'inline-flex',
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: 'primary.800',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'primary.100',
    '&:hover': {
      color: 'black !important'
    }
  },
  documentCard: {
    container: {
      borderRadius: 2,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'gray.300',
      backgroundColor: 'gray.50'
    },
    headerContainer: {
      py: 1.25,
      px: 2,
      backgroundColor: 'gray.200',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    title: {
      fontSize: 16,
      fontWeight: 700,
      color: 'gray.900',
      fontFamily: 'Mulish'
    },
    flagComponent: {
      height: 12,
      width: 18
    },
    tag: {
      fontSize: 10,
      height: 13,
      borderRadius: 1,
      fontWeight: 800,
      color: 'white.main',
      fontFamily: 'Mulish',
      '& span': {
        px: 0.5
      },
      cursor: 'default'
    },
    metaDataItem: {
      fontSize: 12,
      fontFamily: 'Mulish',
      fontWeight: 500,
      color: 'gray.700',
      maxWidth: 188
    },
    metadataItemReporting: {
      maxWidth: 116
    },
    chipContainer: {
      alignItems: 'center',
      py: 0.125,
      px: 0.75,
      height: 24,
      borderRadius: 3,
      borderWidth: 1,
      borderStyle: 'solid'
    },
    chipLabel: { fontSize: 14, fontWeight: 700, fontFamily: 'Mulish', textTransform: 'Capitalize' }
  },
  notes: {
    container: {
      borderRadius: 2,
      backgroundColor: 'gray.100',
      gap: 0.5,
      py: 0.5,
      px: 1
    },
    text: {
      fontSize: 13,
      fontFamily: 'Mulish',
      color: 'secondary.600',
      fontWeight: 700
    },
    content: {
      fontSize: 13,
      fontFamily: 'Mulish',
      color: 'gray.700',
      fontWeight: 600
    }
  },
  table: {
    container: {
      borderRadius: 1,
      overflow: 'hidden',
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: 'primary.600'
    },
    headerRow: {
      backgroundColor: 'primary.50'
    },
    headerCell: {
      color: 'primary.600',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'capitalize',
      py: 0.75,
      px: 2,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'primary.50'
    },
    rowCell: {
      color: '#1D2433',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 400,
      py: 1,
      pl: 2,
      pr: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary.50'
    },
    mainColumnRowCell: {
      color: '#1D2433',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 600,
      py: 1,
      pl: 2,
      pr: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary.50'
    }
  },
  stepsToRecreate: {
    text: {
      fontFamily: 'Mulish',
      fontSize: 18,
      fontWeight: 700,
      color: 'gray.800',
      mt: 4
    },
    stepsContainer: {
      borderRadius: 2,
      borderWidth: 1,
      borderColor: 'gray.300',
      borderStyle: 'solid',
      px: 2
    },
    timelineContainer: {
      [`& .MuiTimelineItem-root:before`]: {
        flex: 0,
        p: 0
      },
      m: 0,
      p: 0
    },
    timelineItem: {
      minHeight: 32
    },
    emptyTimelineItem: {
      minHeight: 16
    },
    iconButtonDefault: {
      backgroundColor: 'gray.500',
      height: 20,
      width: 20,
      fontSize: 12,
      display: 'inline-flex',
      fontFamily: 'Mulish',
      fontWeight: 700,
      color: 'white.main',
      cursor: 'default',
      '&:hover': {
        color: 'white.main',
        backgroundColor: 'gray.500'
      }
    },
    iconButtonHighlighted: {
      backgroundColor: 'primary.300',
      '&:hover': {
        color: 'white.main',
        backgroundColor: 'primary.300'
      }
    },
    timelineConnector: {
      width: 2,
      height: '100%',
      flexGrow: 0,
      backgroundColor: 'gray.200'
    },
    timelineSeparator: {
      mr: 2
    },
    timelineContentContainer: {
      padding: '0px !important'
    },
    accordionContainer: {
      boxShadow: 'none',
      border: 'none',
      borderRadius: '8px !important'
    },
    accordionSummaryContainer: {
      p: 0,
      minHeight: 'auto',
      alignItems: 'flex-start',
      '& .MuiAccordionSummary-content': {
        m: 0
      }
    },
    accordionHeader: {
      fontFamily: 'Mulish',
      fontSize: 16,
      fontWeight: 600,
      color: 'gray.900',
      lineHeight: 1
    },
    accordionDetailsContainer: {
      pl: 0,
      pt: 3,
      pr: 0
    },
    divider: { my: 2, borderColor: 'gray.200' },
    emptyTimelineSeparator: {
      ml: 1.125
    }
  },
  header2: {
    py: 0.25,
    pl: 1,
    pr: 1.5,
    borderRadius: 1,
    backgroundColor: 'primary.0',
    width: 'fit-content',
    fontSize: 14,
    fontWeight: 600,
    color: 'gray.900',
    fontFamily: 'Mulish'
  },
  ul2: {
    list: { listStyleType: 'disc', py: 0, pl: 3.25 },
    listItemText: {
      m: 0,
      fontSize: 14,
      fontFamily: 'Mulish',
      color: 'gray.700',
      fontWeight: 500
    }
  },
  historyTable: {
    container: {
      borderRadius: 2,
      overflow: 'hidden',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'gray.300',
      mt: 4,
      boxShadow: 'none'
    },
    headerRow: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'gray.300'
    },
    normalRow: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'gray.300',
      '&:last-child': {
        borderBottomWidth: 0
      }
    },
    headerCell: {
      color: 'gray.600',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'capitalize',
      py: 1.625,
      px: 2
    },
    rowCell: {
      color: 'gray.800',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 500,
      py: 2,
      pl: 2,
      pr: 1,
      border: 'none'
    },
    author: {
      color: 'gray.800',
      fontFamily: 'Mulish',
      fontSize: 14,
      fontWeight: 500
    }
  }
};

export default styles;
