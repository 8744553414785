const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 1,
    width: '100%'
  },
  labelBox: {
    minWidth: '114px'
  },
  labelText: {
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
    color: 'gray.600',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '120%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Limit text to 2 lines
    maxHeight: '3em' // Ensures that no more than 2 lines are shown based on line height
  },
  valueText: {
    color: 'gray.800',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '120%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1, // Limit text to 2 lines
    maxHeight: '3em', // Ensures that no more than 2 lines are shown based on line height,
    overflowY: 'clip',
    textTransform: 'capitalize',
    paddingLeft: 0.75
  },
  partnerText: {
    fontSize: 12,
    borderRadius: 4,
    padding: '2px 4px',
    color: 'gray.900',
    marginLeft: '6px',
    fontWeight: 500,
    backgroundColor: '#EEE'
  },
  brandText: {
    marginLeft: '6px',
    fontSize: 14,
    color: 'gray.800',
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'uppercase'
  },
  showMoreButton: {
    padding: 0,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '120%',
    textTransform: 'none',
    cursor: 'pointer',
    color: 'secondary.700',
    justifyContent: 'flex-start'
  }
};

export default styles;
