import getDateString from '../../utils/getDateString';
import { METADATA_KEYS, METADATA_KEYS_MAPPING, STEP_DETAILS_MAPPING } from './contants';
import { ICHMetadataProps } from './types';

// Function to get or memoize a nested value by key path (supports dot notation)
const getNestedValue = (obj: any, key: string) => {
  const nestedValueCache = new Map();
  const cacheKey = JSON.stringify({ obj, key });
  if (!nestedValueCache.has(cacheKey)) {
    const value = key.split('.').reduce((acc, part) => acc?.[part], obj);
    nestedValueCache.set(cacheKey, value);
  }
  return nestedValueCache.get(cacheKey);
};

export const isEmptyObject = (data: any) => Object.values(data).every(value => value === '');

export const getCardMetadataList = (selectedData: any) => {
  const metadataList: ICHMetadataProps[] = [];

  METADATA_KEYS.forEach((key: string) => {
    const formatedValue = getNestedValue(selectedData, key);
    if (Array.isArray(formatedValue) ? formatedValue.length !== 0 : formatedValue) {
      const label = METADATA_KEYS_MAPPING[key];
      const mainText = Array.isArray(formatedValue) ? formatedValue.join(', ') : formatedValue;
      const metadata = [];
      let type = 'text';
      if (key === 'status') {
        metadata.push({
          label: '',
          value: `${formatedValue} (${STEP_DETAILS_MAPPING[formatedValue] || 'NA'})`,
          toolTipText: `${formatedValue} (${STEP_DETAILS_MAPPING[formatedValue] || 'NA'})`
        });

        type = 'piped-text';
      }
      if (key === 'step_date') {
        const statusData = metadataList.find((item: { id: string }) => item?.id === 'status');
        if (statusData?.metadata) {
          statusData?.metadata.push({
            label: `${label} ${selectedData?.step_date_label}`,
            value: getDateString(formatedValue),
            toolTipText: `${formatedValue} (${STEP_DETAILS_MAPPING[formatedValue] || 'NA'})`
          });
        }
      } else {
        metadataList.push({
          id: key,
          label,
          mainText,
          metadata,
          type,
          toolTipText: mainText
        });
      }
    }
  });

  return metadataList;
};

export default getNestedValue;
