import React, { memo } from 'react';
import { Box, Stack, Skeleton } from '@mui/material';
import styles from '../../styles/GuidelineDetailsLoader.styles';

const GuidelinesDetailsLoader = () => {
  return (
    <>
      <Box display='flex' justifyContent='space-between' sx={styles.wrapper}>
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='320px' />
        <Box display='flex' alignItems='center'>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='120px' />
        </Box>
      </Box>
      <Box sx={styles.sectionWrapper}>
        <Stack spacing={1} aria-label='section' margin={0}>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='120px' />
        </Stack>
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} height='110px' width='100%' />
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} height='110px' width='100%' />
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} height='110px' width='100%' />
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} height='110px' width='100%' />
      </Box>
    </>
  );
};

export default memo(GuidelinesDetailsLoader);
