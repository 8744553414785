import React, { useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { HideIcon, ViewIcon } from '../../../../assets/svgs/Icons';
import styles from '../../styles/Guidelines.styles';

const getExpandButtonText = (isValidActions: boolean, expanded: boolean) => {
  if (!isValidActions) {
    return 'No Details';
  }
  return expanded ? 'Hide Details' : 'View Details';
};

export interface GuidelinesAccordionProps {
  isExpanded: boolean;
  // eslint-disable-next-line no-unused-vars
  onSectionExpand: (id: string) => void;
  idx: any;
  data: any;
  GuidelinesDetails: React.FC<{ data: any }>;
}

const GuidelinesAccordion: React.FC<GuidelinesAccordionProps> = ({
  isExpanded,
  onSectionExpand,
  idx,
  data,
  GuidelinesDetails
}) => {
  const isValidActions = useMemo(() => {
    return Object.keys(data)?.length !== 0;
  }, [data]);

  const expandButtonText = getExpandButtonText(isValidActions, isExpanded);
  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => isValidActions && onSectionExpand(idx)}
      defaultExpanded
      sx={{ ...styles.cardSummaryAccordion, '&.Mui-expanded': { margin: 0 } }}>
      <AccordionSummary
        expandIcon={
          isExpanded ? (
            <HideIcon
              sx={{
                ...styles.hideIcon,
                color: isValidActions ? 'primary.700' : 'gray.500'
              }}
            />
          ) : (
            <ViewIcon
              sx={{
                ...styles.hideIcon,
                color: isValidActions ? 'primary.700' : 'gray.500'
              }}
            />
          )
        }
        aria-controls={idx}
        id={idx}
        sx={styles.cardSummaryAccordionSummary}>
        <Typography
          fontWeight={500}
          sx={{
            ...styles.cardAccordionHideSummaryText,
            color: isValidActions ? 'primary.700' : 'gray.500'
          }}>
          {expandButtonText}
        </Typography>
      </AccordionSummary>
      {isExpanded && (
        <AccordionDetails sx={styles.cardSummaryAccordionDetails}>
          <GuidelinesDetails key={idx} data={data} />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default React.memo(GuidelinesAccordion);
