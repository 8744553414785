import { createSvgIcon } from '@mui/material';

const CheFlagIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <g clipPath='url(#clip0_8986_754)'>
      <path d='M0 0H12V12H0V0Z' fill='#FF0000' />
      <path
        d='M4.875 2.25H7.125V4.875H9.75V7.125H7.125V9.75H4.875V7.125H2.25V4.875H4.875V2.25Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_8986_754'>
        <rect width='12' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'CheFlagIcon'
);

export default CheFlagIcon;
