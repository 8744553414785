import React, { useEffect, useMemo, useState } from 'react';
// mui
import { Grid, Box, Typography, Tooltip } from '@mui/material';
// icons
import RightArrowIcon from '../../../../assets/svgs/PDCO/RightArrow';
import LeftArrowIcon from '../../../../assets/svgs/PDCO/LeftArrow';
// styles
import styles from '../../../Guidelines/styles/GuidelineSectionIndex.styles';

const RightIndex = ({
  activeSection,
  showSection,
  setShowSection,
  handleSectionClick,
  selectedGuidelineSections,
  sections
}: any) => {
  const [sectionsWithCount, setSectionsWithCount] = useState<
    Record<string, { label: string; value: string }[]>
  >({});

  const sectionsKeyCount = useMemo(() => {
    const countMap: { [key: string]: number } = {};
    if (selectedGuidelineSections) {
      Object.keys(selectedGuidelineSections).forEach(key => {
        const count = selectedGuidelineSections[key]?.length || 0;
        // this need to hardcoded as others does not come with any section number in chmp
        countMap[key] = count;
      });
    }
    return countMap;
  }, [selectedGuidelineSections]);

  useEffect(() => {
    const updatedSections: Record<string, any> = {};
    Object.entries(sections)?.forEach(([key, value]) => {
      if (Array.isArray(value) && value.some(subSection => sectionsKeyCount[subSection?.value])) {
        updatedSections[key] = value;
      }
    });
    setSectionsWithCount(updatedSections);
  }, [sections, sectionsKeyCount]);

  const handleView = () => {
    setShowSection(!showSection);
  };

  const getTextColor = (subsectionKeyValue: string) => {
    if (activeSection === subsectionKeyValue) {
      return 'primary.600';
    }
    if (sectionsKeyCount[subsectionKeyValue]) {
      return 'gray.800';
    }
    return 'gray.500';
  };

  return (
    <>
      <Box onClick={handleView} sx={styles.hideView}>
        {showSection ? (
          <RightArrowIcon sx={{ fontSize: 10 }} />
        ) : (
          <LeftArrowIcon sx={{ fontSize: 10 }} />
        )}
        <Typography sx={styles.hideViewText}>{showSection ? 'Hide' : 'View'}</Typography>
      </Box>
      {/* Opinions & Discussion */}
      {Object.keys(sectionsWithCount).map(sectionKey => {
        return (
          <Box key={sectionKey} sx={styles.sectionWrapper}>
            <Box width='100%'>
              <Tooltip placement='top' title={sectionKey}>
                <Typography
                  sx={{
                    ...styles.sectionKeyText,
                    color: activeSection[0] === sectionKey[0] ? 'primary.600' : 'gray.800'
                  }}>
                  {sectionKey}
                </Typography>
              </Tooltip>
            </Box>
            <Box sx={styles.subSectionWrapper}>
              {sectionsWithCount[sectionKey].map((subsectionKey: any) => {
                return (
                  <Tooltip key={subsectionKey.value} title={subsectionKey?.label || ''}>
                    <Box
                      onClick={() => {
                        if (subsectionKey?.value in sectionsKeyCount) {
                          handleSectionClick(subsectionKey?.value);
                        }
                      }}
                      sx={styles.subSectionTextContainer}>
                      <Typography
                        component='span'
                        sx={{
                          ...styles.subSectionTextLabel,
                          fontWeight: activeSection === subsectionKey?.value ? '700' : '400',
                          color: getTextColor(subsectionKey?.value),
                          '&:hover': {
                            cursor: subsectionKey?.value in sectionsKeyCount ? 'pointer' : null
                          }
                        }}>
                        {subsectionKey?.label}
                      </Typography>
                      <Typography
                        component='span'
                        sx={{
                          ...styles.subSectionTextCount,
                          fontWeight: activeSection === subsectionKey?.value ? '700' : '400',
                          color: getTextColor(subsectionKey?.value),
                          '&:hover': {
                            cursor: subsectionKey?.value in sectionsKeyCount ? 'pointer' : null
                          }
                        }}>
                        ({sectionsKeyCount[subsectionKey?.value] || 0})
                      </Typography>
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default React.memo(RightIndex);
