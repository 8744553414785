import { memo } from 'react';
import { Box, Stack, Typography, Grid, Tooltip } from '@mui/material';

import articlesStyles from '../../styles/Articles.styles';
import { ARTICLES } from '../../../ExpertAnswers/utils/constants';

const Articles = () => {
  return (
    <Grid container sx={articlesStyles.gridContainer}>
      {ARTICLES.map(article => (
        <Grid key={article.id} item sx={articlesStyles.gridItem}>
          <Box sx={articlesStyles.articleCardContainer}>
            <Stack direction='row' gap={2.5} justifyContent='space-between'>
              <Stack>
                <Tooltip title={article.title}>
                  <Typography sx={articlesStyles.articleTitle}>{article.title}</Typography>
                </Tooltip>

                <Tooltip title={article.rawDescription}>
                  <Typography sx={articlesStyles.articleRawDescription}>
                    {article.rawDescription}
                  </Typography>
                </Tooltip>

                <Typography sx={articlesStyles.articleDate}>
                  {article.addedOn.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timeZone: 'UTC'
                  })}
                </Typography>
              </Stack>
              <Stack>
                <img src={article.image} alt={article.image} style={articlesStyles.articleImage} />
              </Stack>
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(Articles);
