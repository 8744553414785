const styles = {
  mainContainer: {
    backgroundColor: 'gray.50',
    height: '100%'
  },
  primaryHeading: {
    fontSize: 32,
    fontWeight: 700,
    color: 'gray.800',
    fontFamily: 'Mulish'
  },
  textField: {
    '& .MuiInputBase-root': {
      borderRadius: 2,
      borderColor: 'gray.400',
      height: 42,
      width: 488,
      pr: 0.75,
      fontSize: 14,
      fontFamily: 'Mulish',
      backgroundColor: 'white.main'
    }
  },
  inputAdornmentIconWrapper: {
    borderRadius: 2,
    height: 30,
    width: 30,
    background: 'linear-gradient(118deg, #109E8A 7.17%, #37B45B 91.04%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  magnifyingGlassIcon: { height: '14px', width: '14px', color: 'white.main' },
  secondaryHeading: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
    color: 'black.main',
    fontFamily: 'Mulish',
    mt: 9
  },
  gridContainer: {
    mt: 3,
    rowGap: 4,
    columnGap: 4,
    width: 924,
    mx: 'auto'
  },
  gridItem: {
    flexBasis: 446,
    maxWidth: 446,
    cursor: 'pointer'
  },
  articleCardContainer: {
    width: 446,
    height: 172,
    backgroundColor: 'white.main',
    py: 1.5,
    px: 2,
    borderRadius: 2
  },
  articleTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: 'gray.900',
    fontFamily: 'Mulish',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical'
  },
  articleRawDescription: {
    fontSize: 16,
    fontWeight: 300,
    color: 'gray.800',
    fontFamily: 'Mulish',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  articleDate: {
    mt: 2,
    fontSize: 12,
    fontWeight: 400,
    color: 'gray.700',
    fontFamily: 'Mulish'
  },
  articleImage: {
    width: 140,
    height: 140,
    borderRadius: 16
  },
  emptySearchText: {
    textAlign: 'center',
    mt: 3,
    fontSize: 16,
    fontWeight: 600,
    color: '#191919',
    fontFamily: 'Mulish'
  }
};

export default styles;
