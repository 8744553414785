import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';

interface SectionProps {
  label: string;
  children: React.ReactNode;
  show: boolean;
}

const Section: React.FC<SectionProps> = ({ label, children, show }) => (
  <Stack direction='column' sx={styles.sectionContainer}>
    <Stack direction='row' width='100%' spacing={2} gap={4} alignItems='flex-start'>
      <Typography variant='subtitle2' sx={styles.sectionHeading}>
        {label}:
      </Typography>
      {children}
    </Stack>
    {show && <Divider sx={styles.divider} />}
  </Stack>
);

const ListSection = ({
  dataList,
  title,
  showTitle
}: {
  dataList: Array<any>;
  title: string;
  showTitle: boolean;
}) => {
  return (
    <Stack spacing={1} direction='column' width='100%'>
      {showTitle && <Typography sx={styles.headingText}>{title}</Typography>}

      <Stack direction='column' width='100%'>
        {dataList
          ?.filter(item => item?.value?.length !== 0)
          .map(({ label, content }, index: number) => (
            <Section key={label} label={label} show={index !== (dataList?.length || 0) - 1}>
              <Typography variant='subtitle2' sx={styles.infoText}>
                {content}
              </Typography>
            </Section>
          ))}
      </Stack>
    </Stack>
  );
};

export default React.memo(ListSection);
