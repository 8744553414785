import React, { useContext, useMemo } from 'react';
import {
  Portal,
  Snackbar,
  Stack,
  Box,
  Tooltip,
  Typography,
  Divider,
  Button,
  Slide
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '../../../../assets/svgs/Icons/CloseIcon';
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import PDFViewer from '../../../../components/PDFViewer';
import styles from '../../styles/Documents.styles';
import trimText from '../../../../utils/trimText';
import { getDocumentSourceName } from '../../../SearchResults/utils/documentResultsUtils';
import { DocumentViewerProps } from '../../types';
import { sourceFlagMapping } from '../../../SearchResults/constants';
import formatDateVersionedTitle from '../../../CanadaGudiance/utils/formatDateVersionedTitle';
import { ICH_GUIDELINE_SOURCE } from '../../contants';

const Transition = (props: TransitionProps) => {
  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction='left' />;
};

const DocumentViewer = ({ documentData, setDocumentData }: DocumentViewerProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const FlagComponent = sourceFlagMapping[documentData?.source];
  const handleChatRia = () => {
    const mappedSourceName = getDocumentSourceName(documentData?.metadataSource);
    const docDetails = {
      title: documentData?.documentTitle, // chat ria document title for ICH, CA, and US-FDA
      doc_title: documentData?.documentTitle, // chat ria document title for EMA
      step_date: documentData?.step_date,
      _category: documentData?.source === ICH_GUIDELINE_SOURCE ? documentData?.category : '',
      guideline_group: documentData?.guideline_group
    };

    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: documentData?.documentPath,
        url: documentData?.documentPath,
        item: {
          ...docDetails
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setDocumentData({ ...documentData, isDocumentOpen: false });
  };

  const formattedTitle = useMemo(() => {
    if (documentData?.source === 'ca') {
      return formatDateVersionedTitle(trimText(documentData?.documentTitle, 40));
    }
    return trimText(documentData?.documentTitle, 40) ?? '';
  }, [documentData?.documentTitle, documentData?.source]);

  return (
    <Portal>
      <Snackbar
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          ...styles.documentViewer.snackBarContainer,
          right: {
            md: '47% !important'
          }
        }}
        open={documentData?.isDocumentOpen}>
        <Stack
          flexDirection='column'
          sx={{ ...styles.documentViewer.pdfContainer, width: { md: '98%' } }}>
          <Box
            display='flex'
            alignItems='center'
            width='100%'
            justifyContent='space-between'
            mb={2.5}>
            <Stack flex='display' flexDirection='row' alignItems='center' pl={1.9}>
              {documentData?.documentTitle && (
                <>
                  <Tooltip title={documentData?.documentTitle}>
                    <Typography sx={styles.documentViewer.titleText}>{formattedTitle}</Typography>
                  </Tooltip>
                  <FlagComponent sx={styles.documentViewer.flagIcon} />
                </>
              )}
              <Divider
                orientation='vertical'
                variant='middle'
                flexItem
                sx={{ p: 0.7, height: 16 }}
              />
              <Tooltip title='Use Chat RIA to interact with the document'>
                <Button
                  variant='text'
                  disabled={!documentData?.documentPath}
                  onClick={handleChatRia}
                  sx={styles.documentViewer.chatRiaButton}>
                  Chat RIA
                  <SendIcon sx={styles.documentViewer.chatRiaIcon} />
                </Button>
              </Tooltip>
            </Stack>

            <Box mr={2.5}>
              <CloseIcon
                onClick={() =>
                  setDocumentData({ ...documentData, documentTitle: '', isDocumentOpen: false })
                }
                sx={styles.documentViewer.closeIcon}
              />
            </Box>
          </Box>
          <Box id='pdfBox'>
            <PDFViewer fileUrl={documentData?.documentPath} defaultScale={1} />
          </Box>
        </Stack>
      </Snackbar>
    </Portal>
  );
};

export default React.memo(DocumentViewer);
