import React from 'react';

import { Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';

import componentStyles from '../styles/Components.styles';

import { ELEMENT_TYPE, IUnorderedList } from '../interface';

interface UnorderedListProps {
  list: IUnorderedList[] | undefined;
}

const UnorderedList = ({ list }: UnorderedListProps) => {
  return (
    <List sx={componentStyles.ul.list}>
      {list?.map((item: any) => (
        <ListItem key={item.id} sx={componentStyles.ul.listItem}>
          <ListItemText
            primary={
              <Typography sx={componentStyles.ul.listItemText}>
                {item.content}
                {item.type === ELEMENT_TYPE.REFERENCE_PARAGRAPH && (
                  <IconButton sx={componentStyles.referenceNumber} size='small'>
                    {item.referenceNumber}
                  </IconButton>
                )}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default React.memo(UnorderedList);
