import React from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { EuFlagIcon, ModuleInfo } from '../../../../assets/svgs/Icons';
import styles from '../../styles/Header.styles';

interface HeaderTitleProps {
  meetingName: string;
  tooltipContent: string;
  // eslint-disable-next-line react/require-default-props
  iconComponent?: any;
  // eslint-disable-next-line react/require-default-props
  showIcon?: boolean;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  meetingName,
  tooltipContent,
  iconComponent,
  showIcon = false
}) => (
  <Box display='flex' alignItems='center'>
    {meetingName}
    {showIcon && (
      <>
        {iconComponent || <EuFlagIcon sx={styles.euFlagIcon} />}
        <Chip label='EMA' sx={styles.emaChip} />
      </>
    )}

    <Tooltip title={tooltipContent} placement='top'>
      <ModuleInfo sx={{ fontSize: 16, ml: 1 }} />
    </Tooltip>
  </Box>
);

export default React.memo(HeaderTitle);
