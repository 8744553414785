import { createSvgIcon } from '@mui/material';

const ArgFlagIcon = createSvgIcon(
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7860_61522)'>
      <path d='M0 0H18V12H0V0Z' fill='#74ACDF' />
      <path d='M0 4.00012H18V8.0002H0V4.00012Z' fill='white' />
      <path
        d='M8.92969 6.03144L9.5699 7.51924C9.5699 7.51924 9.58093 7.54768 9.5987 7.53986C9.61648 7.53201 9.60543 7.50357 9.60543 7.50357L9.07184 5.96863M9.05654 6.54751C9.04874 6.77378 9.17921 6.89822 9.16216 7.10028C9.14513 7.30236 9.24917 7.4166 9.27331 7.49517C9.29745 7.57375 9.24721 7.62074 9.26885 7.63192C9.29052 7.64311 9.33793 7.58104 9.32247 7.46932C9.30701 7.3576 9.22752 7.32444 9.2462 7.07764C9.26487 6.83085 9.15156 6.77337 9.17915 6.54825'
        fill='#F6B40E'
      />
      <path
        d='M8.92969 6.03144L9.5699 7.51924C9.5699 7.51924 9.58093 7.54768 9.5987 7.53986C9.61648 7.53201 9.60543 7.50357 9.60543 7.50357L9.07184 5.96863M9.05654 6.54751C9.04874 6.77378 9.17921 6.89822 9.16216 7.10028C9.14513 7.30236 9.24917 7.4166 9.27331 7.49517C9.29745 7.57375 9.24721 7.62074 9.26885 7.63192C9.29052 7.64311 9.33793 7.58104 9.32247 7.46932C9.30701 7.3576 9.22752 7.32444 9.2462 7.07764C9.26487 6.83085 9.15156 6.77337 9.17915 6.54825'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.92278 6.00004L8.98049 7.63593C8.98049 7.63593 8.98047 7.66671 8.9997 7.66674C9.01894 7.66674 9.01893 7.63595 9.01893 7.63595L9.07665 6.00004M8.85483 6.52861C8.76644 6.73447 8.84234 6.9027 8.75409 7.08242C8.66585 7.26216 8.72099 7.41017 8.7151 7.49262C8.70922 7.57507 8.64594 7.59798 8.66193 7.61715C8.67793 7.63632 8.744 7.59834 8.7698 7.48881C8.7956 7.37929 8.73406 7.31619 8.83985 7.09581C8.94564 6.87543 8.86158 6.77607 8.96783 6.57935'
        fill='#F6B40E'
      />
      <path
        d='M8.92278 6.00004L8.98049 7.63593C8.98049 7.63593 8.98047 7.66671 8.9997 7.66674C9.01894 7.66674 9.01893 7.63595 9.01893 7.63595L9.07665 6.00004M8.85483 6.52861C8.76644 6.73447 8.84234 6.9027 8.75409 7.08242C8.66585 7.26216 8.72099 7.41017 8.7151 7.49262C8.70922 7.57507 8.64594 7.59798 8.66193 7.61715C8.67793 7.63633 8.744 7.59834 8.7698 7.48881C8.7956 7.37929 8.73406 7.31619 8.83985 7.09581C8.94564 6.87543 8.86158 6.77607 8.96783 6.57935'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.92907 5.96861L8.39548 7.50353C8.39548 7.50353 8.38442 7.53195 8.40218 7.53983C8.41995 7.54768 8.43099 7.51924 8.43099 7.51924L9.07122 6.03141M8.67666 6.4292C8.52114 6.58331 8.53091 6.76972 8.3849 6.89973C8.2389 7.02978 8.23673 7.18903 8.20171 7.2628C8.1667 7.33657 8.10002 7.3319 8.10791 7.35614C8.11582 7.38039 8.19048 7.37226 8.25361 7.2816C8.31674 7.19094 8.28252 7.10754 8.45933 6.94711C8.63613 6.78669 8.59412 6.66058 8.76286 6.5222'
        fill='#F6B40E'
      />
      <path
        d='M8.92907 5.96861L8.39548 7.50353C8.39548 7.50353 8.38442 7.53195 8.40218 7.53983C8.41995 7.54768 8.43099 7.51924 8.43099 7.51924L9.07122 6.03141M8.67666 6.4292C8.52114 6.58331 8.53091 6.76972 8.3849 6.89973C8.2389 7.02978 8.23673 7.18903 8.20171 7.2628C8.1667 7.33657 8.10002 7.3319 8.10791 7.35614C8.11582 7.38039 8.19048 7.37226 8.25361 7.2816C8.31674 7.19094 8.28252 7.10754 8.45933 6.94711C8.63613 6.78669 8.59412 6.66058 8.76286 6.5222'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.9456 5.942L7.90195 7.14228C7.90195 7.14228 7.88154 7.16403 7.89512 7.17855C7.90872 7.19306 7.92912 7.17129 7.92912 7.17129L9.0544 6.05806M8.54716 6.26451C8.34819 6.3434 8.29033 6.51961 8.1088 6.58012C7.92725 6.64067 7.86812 6.78692 7.8093 6.84078C7.75048 6.89464 7.69055 6.86311 7.68915 6.88872C7.68776 6.91435 7.75965 6.93732 7.8505 6.87934C7.94135 6.82135 7.93966 6.73032 8.16056 6.65428C8.38146 6.57824 8.38789 6.44458 8.59343 6.38561'
        fill='#F6B40E'
      />
      <path
        d='M8.9456 5.942L7.90195 7.14228C7.90195 7.14228 7.88154 7.16403 7.89512 7.17855C7.90872 7.19306 7.92912 7.17129 7.92912 7.17129L9.0544 6.05806M8.54716 6.26451C8.34819 6.3434 8.29033 6.51961 8.1088 6.58012C7.92725 6.64067 7.86812 6.78692 7.8093 6.84078C7.75048 6.89464 7.69055 6.86311 7.68915 6.88872C7.68776 6.91435 7.75965 6.93732 7.8505 6.87934C7.94135 6.82135 7.93966 6.73032 8.16056 6.65428C8.38146 6.57824 8.38789 6.44458 8.59343 6.38561'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.09737 6.58592C9.10756 6.80322 9.22308 6.89944 9.2004 7.09746C9.25019 6.94086 9.13011 6.81947 9.13695 6.58818M8.96484 6.01801L9.4033 7.03986L9.0359 5.98657'
        fill='#85340A'
      />
      <path
        d='M8.88007 6.58085C8.81152 6.78576 8.88373 6.92181 8.79173 7.0955C8.89391 6.97115 8.82652 6.80998 8.91582 6.59909M8.96138 6.00207L8.99985 7.12511L9.0383 6.00203'
        fill='#85340A'
      />
      <path
        d='M8.68077 6.48739C8.54392 6.64872 8.56182 6.80389 8.41451 6.9268C8.55353 6.85363 8.5491 6.67722 8.70726 6.51883M8.96353 5.98585L8.59617 7.03912L9.03462 6.01722'
        fill='#85340A'
      />
      <path
        d='M8.52964 6.3198C8.34533 6.413 8.3062 6.56366 8.12601 6.61709C8.2807 6.60623 8.33989 6.44144 8.54283 6.35967M8.97082 5.97187L8.25355 6.795L9.02524 6.02986'
        fill='#85340A'
      />
      <path
        d='M8.97041 5.92407L7.57559 6.60697C7.57559 6.60697 7.54893 6.61873 7.55627 6.63769C7.56362 6.65665 7.59029 6.64486 7.59029 6.64486L9.0293 6.0757M8.4866 6.05938C8.27447 6.05106 8.1578 6.19023 7.96838 6.17204C7.77893 6.15387 7.67183 6.26485 7.59816 6.2906C7.5245 6.31635 7.48044 6.26276 7.46996 6.28585C7.45947 6.30896 7.51766 6.35953 7.62239 6.34304C7.72713 6.32655 7.75823 6.24176 7.98959 6.26168C8.22096 6.2816 8.27485 6.16074 8.4859 6.19016'
        fill='#F6B40E'
      />
      <path
        d='M8.97041 5.92407L7.57559 6.60697C7.57559 6.60697 7.54893 6.61873 7.55627 6.63769C7.56362 6.65665 7.59029 6.64486 7.59029 6.64486L9.0293 6.0757M8.4866 6.05938C8.27447 6.05106 8.1578 6.19023 7.96838 6.17204C7.77893 6.15387 7.67183 6.26485 7.59816 6.2906C7.5245 6.31635 7.48044 6.26276 7.46996 6.28585C7.45947 6.30896 7.51766 6.35953 7.62239 6.34304C7.72713 6.32655 7.75823 6.24176 7.98959 6.26168C8.22096 6.2816 8.27485 6.16074 8.4859 6.19016'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.99833 5.91786L7.46469 5.97941C7.46469 5.97941 7.43584 5.97939 7.43581 5.99991C7.43581 6.02043 7.46467 6.02042 7.46467 6.02042L8.99833 6.08198M8.5028 5.84538C8.30981 5.75109 8.15209 5.83205 7.98361 5.73792C7.8151 5.64381 7.67634 5.70262 7.59904 5.69634C7.52174 5.69006 7.50027 5.62257 7.4823 5.63962C7.46432 5.65669 7.49993 5.72716 7.60261 5.75468C7.7053 5.7822 7.76444 5.71656 7.97105 5.8294C8.17766 5.94225 8.27081 5.85258 8.45524 5.96592'
        fill='#F6B40E'
      />
      <path
        d='M8.99833 5.91786L7.46469 5.97941C7.46469 5.97941 7.43584 5.97939 7.43581 5.99991C7.43581 6.02043 7.46467 6.02042 7.46467 6.02042L8.99833 6.08198M8.5028 5.84538C8.30981 5.75109 8.15209 5.83205 7.98361 5.73792C7.8151 5.64381 7.67634 5.70262 7.59904 5.69634C7.52174 5.69006 7.50027 5.62257 7.4823 5.63962C7.46432 5.65669 7.49993 5.72716 7.60261 5.75468C7.7053 5.7822 7.76444 5.71655 7.97105 5.8294C8.17766 5.94225 8.27081 5.85258 8.45524 5.96592'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.02868 5.92409L7.58969 5.35494C7.58969 5.35494 7.56304 5.34314 7.55566 5.36208C7.54829 5.38104 7.57496 5.39281 7.57496 5.39281L8.96979 6.07573M8.59687 5.65486C8.45239 5.48897 8.27763 5.49939 8.15575 5.34365C8.03383 5.18791 7.88453 5.1856 7.81537 5.14825C7.74621 5.1109 7.75059 5.03977 7.72787 5.04819C7.70513 5.05663 7.71275 5.13627 7.79774 5.20361C7.88273 5.27095 7.96093 5.23444 8.11133 5.42304C8.26172 5.61163 8.37995 5.56681 8.50968 5.7468'
        fill='#F6B40E'
      />
      <path
        d='M9.02868 5.92409L7.58969 5.35494C7.58969 5.35494 7.56304 5.34314 7.55566 5.36208C7.54829 5.38104 7.57496 5.39281 7.57496 5.39281L8.96979 6.07573M8.59687 5.65486C8.45239 5.48897 8.27763 5.49939 8.15575 5.34365C8.03383 5.18791 7.88453 5.1856 7.81537 5.14825C7.74621 5.1109 7.75059 5.03977 7.72787 5.04819C7.70513 5.05663 7.71275 5.13627 7.79774 5.20361C7.88273 5.27095 7.96093 5.23444 8.11133 5.42304C8.26172 5.61163 8.37995 5.56681 8.50968 5.7468'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.05364 5.94185L7.92838 4.82863C7.92838 4.82863 7.90799 4.80685 7.89438 4.82134C7.88077 4.83584 7.90118 4.85761 7.90118 4.85761L8.94484 6.0579M8.75129 5.51684C8.67733 5.30461 8.51214 5.2429 8.4554 5.04926C8.39864 4.85561 8.26153 4.79254 8.21104 4.7298C8.16054 4.66706 8.1901 4.60314 8.16609 4.60164C8.14206 4.60015 8.12053 4.67684 8.17489 4.77375C8.22925 4.87065 8.31459 4.86885 8.38588 5.10448C8.45717 5.34011 8.58247 5.34696 8.63775 5.5662'
        fill='#F6B40E'
      />
      <path
        d='M9.05364 5.94185L7.92838 4.82863C7.92838 4.82863 7.90799 4.80685 7.89438 4.82134C7.88077 4.83584 7.90118 4.85761 7.90118 4.85761L8.94484 6.0579M8.75129 5.51684C8.67733 5.30461 8.51214 5.2429 8.4554 5.04926C8.39864 4.85561 8.26153 4.79254 8.21104 4.7298C8.16054 4.66706 8.1901 4.60314 8.16609 4.60164C8.14206 4.60015 8.12053 4.67684 8.17489 4.77375C8.22925 4.87065 8.31459 4.86885 8.38588 5.10448C8.45717 5.34011 8.58247 5.34696 8.63775 5.5662'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.44983 6.1034C8.24611 6.11427 8.15591 6.23748 7.97026 6.21329C8.11707 6.2664 8.23088 6.13832 8.44771 6.14561M8.98224 5.96204L8.02426 6.42972L9.01172 6.03783'
        fill='#85340A'
      />
      <path
        d='M8.45555 5.87133C8.26345 5.79822 8.1359 5.87524 7.97307 5.7771C8.08965 5.8861 8.24075 5.81422 8.43845 5.90947M8.99816 5.95806L7.94531 5.9991L8.9982 6.04012'
        fill='#85340A'
      />
      <path
        d='M8.54218 5.65882C8.39092 5.51285 8.24546 5.53194 8.13022 5.37481C8.19882 5.5231 8.36421 5.51837 8.51269 5.68707M9.01236 5.96043L8.02493 5.56858L8.98296 6.03626'
        fill='#85340A'
      />
      <path
        d='M8.69965 5.49829C8.61227 5.3017 8.47103 5.25996 8.42094 5.06775C8.43112 5.23275 8.58561 5.29589 8.66227 5.51236M9.02583 5.96888L8.25414 5.20379L8.97146 6.02693'
        fill='#85340A'
      />
      <path
        d='M9.07031 5.96856L8.4301 4.48076C8.4301 4.48076 8.41907 4.45232 8.4013 4.46014C8.38352 4.46799 8.39457 4.49643 8.39457 4.49643L8.92816 6.03137M8.94346 5.45249C8.95126 5.22622 8.82079 5.10178 8.83784 4.89972C8.85487 4.69764 8.75083 4.5834 8.72669 4.50483C8.70255 4.42625 8.75279 4.37926 8.73115 4.36808C8.70948 4.35689 8.66207 4.41896 8.67753 4.53068C8.69299 4.6424 8.77248 4.67556 8.7538 4.92236C8.73513 5.16915 8.84844 5.22663 8.82085 5.45175'
        fill='#F6B40E'
      />
      <path
        d='M9.07031 5.96856L8.4301 4.48076C8.4301 4.48076 8.41907 4.45232 8.4013 4.46014C8.38352 4.46799 8.39457 4.49643 8.39457 4.49643L8.92816 6.03137M8.94346 5.45249C8.95126 5.22622 8.82079 5.10178 8.83784 4.89972C8.85487 4.69764 8.75083 4.5834 8.72669 4.50483C8.70255 4.42625 8.75279 4.37926 8.73115 4.36808C8.70948 4.35689 8.66207 4.41896 8.67753 4.53068C8.69299 4.6424 8.77248 4.67556 8.7538 4.92236C8.73513 5.16915 8.84844 5.22663 8.82085 5.45175'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.07722 5.99996L9.01951 4.36407C9.01951 4.36407 9.01953 4.33329 9.0003 4.33326C8.98106 4.33326 8.98107 4.36405 8.98107 4.36405L8.92335 5.99996M9.14517 5.47139C9.23356 5.26553 9.15766 5.0973 9.24591 4.91758C9.33415 4.73784 9.27901 4.58983 9.2849 4.50738C9.29078 4.42493 9.35406 4.40202 9.33807 4.38285C9.32207 4.36368 9.256 4.40166 9.2302 4.51119C9.2044 4.62071 9.26594 4.68381 9.16015 4.90419C9.05436 5.12457 9.13842 5.22393 9.03217 5.42065'
        fill='#F6B40E'
      />
      <path
        d='M9.07722 5.99996L9.01951 4.36407C9.01951 4.36407 9.01953 4.33329 9.0003 4.33326C8.98106 4.33326 8.98107 4.36405 8.98107 4.36405L8.92335 5.99996M9.14517 5.47139C9.23356 5.26553 9.15766 5.0973 9.24591 4.91758C9.33415 4.73784 9.27901 4.58983 9.2849 4.50738C9.29078 4.42493 9.35406 4.40202 9.33807 4.38285C9.32207 4.36367 9.256 4.40166 9.2302 4.51119C9.2044 4.62071 9.26594 4.68381 9.16015 4.90419C9.05436 5.12457 9.13842 5.22393 9.03217 5.42065'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.07093 6.03139L9.60452 4.49647C9.60452 4.49647 9.61558 4.46805 9.59782 4.46017C9.58005 4.45232 9.56901 4.48076 9.56901 4.48076L8.92878 5.96859M9.32334 5.5708C9.47886 5.41669 9.46909 5.23028 9.6151 5.10027C9.7611 4.97022 9.76327 4.81097 9.79829 4.7372C9.8333 4.66343 9.89998 4.6681 9.89209 4.64386C9.88418 4.61961 9.80952 4.62774 9.74639 4.7184C9.68326 4.80906 9.71748 4.89246 9.54067 5.05289C9.36387 5.21331 9.40588 5.33942 9.23714 5.4778'
        fill='#F6B40E'
      />
      <path
        d='M9.07093 6.03139L9.60452 4.49647C9.60452 4.49647 9.61558 4.46805 9.59782 4.46017C9.58005 4.45232 9.56901 4.48076 9.56901 4.48076L8.92878 5.96859M9.32334 5.5708C9.47886 5.41669 9.46909 5.23028 9.6151 5.10027C9.7611 4.97022 9.76327 4.81097 9.79829 4.7372C9.8333 4.66343 9.89998 4.6681 9.89209 4.64386C9.88418 4.61961 9.80952 4.62774 9.74639 4.7184C9.68326 4.80906 9.71748 4.89246 9.54067 5.05289C9.36387 5.21331 9.40588 5.33942 9.23714 5.4778'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.0544 6.058L10.098 4.85772C10.098 4.85772 10.1185 4.83597 10.1049 4.82145C10.0913 4.80694 10.0709 4.82871 10.0709 4.82871L8.9456 5.94194M9.45284 5.73549C9.65181 5.6566 9.70967 5.48039 9.8912 5.41988C10.0727 5.35933 10.1319 5.21308 10.1907 5.15922C10.2495 5.10536 10.3094 5.13689 10.3108 5.11128C10.3122 5.08565 10.2403 5.06268 10.1495 5.12066C10.0586 5.17865 10.0603 5.26968 9.83944 5.34572C9.61854 5.42176 9.61211 5.55542 9.40657 5.61439'
        fill='#F6B40E'
      />
      <path
        d='M9.0544 6.058L10.098 4.85772C10.098 4.85772 10.1185 4.83597 10.1049 4.82145C10.0913 4.80694 10.0709 4.82871 10.0709 4.82871L8.9456 5.94194M9.45284 5.73549C9.65181 5.6566 9.70967 5.48039 9.8912 5.41988C10.0727 5.35933 10.1319 5.21308 10.1907 5.15922C10.2495 5.10536 10.3094 5.13689 10.3108 5.11128C10.3122 5.08565 10.2403 5.06268 10.1495 5.12066C10.0586 5.17865 10.0603 5.26968 9.83944 5.34572C9.61854 5.42176 9.61211 5.55542 9.40657 5.61439'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.90263 5.41408C8.89244 5.19678 8.77692 5.10056 8.7996 4.90254C8.74981 5.05914 8.86989 5.18053 8.86305 5.41182M9.03516 5.98199L8.5967 4.96014L8.9641 6.01343'
        fill='#85340A'
      />
      <path
        d='M9.11993 5.41915C9.18848 5.21424 9.11627 5.07819 9.20827 4.9045C9.10609 5.02885 9.17348 5.19002 9.08418 5.40091M9.03862 5.99793L9.00015 4.87489L8.9617 5.99797'
        fill='#85340A'
      />
      <path
        d='M9.31923 5.51261C9.45608 5.35128 9.43818 5.19611 9.58549 5.0732C9.44647 5.14637 9.4509 5.32278 9.29274 5.48117M9.03647 6.01415L9.40383 4.96088L8.96538 5.98278'
        fill='#85340A'
      />
      <path
        d='M9.47036 5.6802C9.65467 5.587 9.6938 5.43634 9.87399 5.38291C9.7193 5.39377 9.66011 5.55856 9.45717 5.64033M9.02918 6.02813L9.74645 5.205L8.97476 5.97014'
        fill='#85340A'
      />
      <path
        d='M9.02959 6.07593L10.4244 5.39303C10.4244 5.39303 10.4511 5.38127 10.4437 5.36231C10.4364 5.34335 10.4097 5.35514 10.4097 5.35514L8.9707 5.9243M9.5134 5.94062C9.72553 5.94894 9.8422 5.80977 10.0316 5.82796C10.2211 5.84613 10.3282 5.73515 10.4018 5.7094C10.4755 5.68365 10.5196 5.73724 10.53 5.71415C10.5405 5.69104 10.4823 5.64047 10.3776 5.65696C10.2729 5.67345 10.2418 5.75824 10.0104 5.73832C9.77904 5.7184 9.72515 5.83926 9.5141 5.80984'
        fill='#F6B40E'
      />
      <path
        d='M9.02959 6.07593L10.4244 5.39303C10.4244 5.39303 10.4511 5.38127 10.4437 5.36231C10.4364 5.34335 10.4097 5.35514 10.4097 5.35514L8.9707 5.9243M9.5134 5.94062C9.72553 5.94894 9.8422 5.80977 10.0316 5.82796C10.2211 5.84613 10.3282 5.73515 10.4018 5.7094C10.4755 5.68365 10.5196 5.73724 10.53 5.71415C10.5405 5.69104 10.4823 5.64047 10.3776 5.65696C10.2729 5.67345 10.2418 5.75824 10.0104 5.73832C9.77904 5.7184 9.72515 5.83926 9.5141 5.80984'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.00167 6.08214L10.5353 6.02059C10.5353 6.02059 10.5642 6.02061 10.5642 6.00009C10.5642 5.97957 10.5353 5.97958 10.5353 5.97958L9.00167 5.91802M9.4972 6.15462C9.69019 6.24891 9.84791 6.16795 10.0164 6.26208C10.1849 6.35619 10.3237 6.29738 10.401 6.30366C10.4783 6.30994 10.4997 6.37743 10.5177 6.36038C10.5357 6.34331 10.5001 6.27284 10.3974 6.24532C10.2947 6.2178 10.2356 6.28344 10.0289 6.1706C9.82234 6.05775 9.72919 6.14742 9.54476 6.03408'
        fill='#F6B40E'
      />
      <path
        d='M9.00167 6.08214L10.5353 6.02059C10.5353 6.02059 10.5642 6.02061 10.5642 6.00009C10.5642 5.97957 10.5353 5.97958 10.5353 5.97958L9.00167 5.91802M9.4972 6.15462C9.69019 6.24891 9.84791 6.16795 10.0164 6.26208C10.1849 6.35619 10.3237 6.29738 10.401 6.30366C10.4783 6.30994 10.4997 6.37743 10.5177 6.36038C10.5357 6.34331 10.5001 6.27284 10.3974 6.24532C10.2947 6.2178 10.2356 6.28345 10.0289 6.1706C9.82234 6.05775 9.72919 6.14742 9.54476 6.03408'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.97132 6.07591L10.4103 6.64506C10.4103 6.64506 10.437 6.65686 10.4443 6.63792C10.4517 6.61896 10.425 6.60719 10.425 6.60719L9.03021 5.92427M9.40313 6.34514C9.54761 6.51103 9.72237 6.50061 9.84425 6.65635C9.96617 6.81209 10.1155 6.8144 10.1846 6.85175C10.2538 6.8891 10.2494 6.96023 10.2721 6.95181C10.2949 6.94337 10.2872 6.86373 10.2023 6.79639C10.1173 6.72905 10.0391 6.76556 9.88867 6.57696C9.73828 6.38837 9.62005 6.43319 9.49032 6.2532'
        fill='#F6B40E'
      />
      <path
        d='M8.97132 6.07591L10.4103 6.64506C10.4103 6.64506 10.437 6.65686 10.4443 6.63792C10.4517 6.61896 10.425 6.60719 10.425 6.60719L9.03021 5.92427M9.40313 6.34514C9.54761 6.51103 9.72237 6.50061 9.84425 6.65635C9.96617 6.81209 10.1155 6.8144 10.1846 6.85175C10.2538 6.8891 10.2494 6.96023 10.2721 6.95181C10.2949 6.94337 10.2872 6.86373 10.2023 6.79639C10.1173 6.72905 10.0391 6.76556 9.88867 6.57696C9.73828 6.38837 9.62005 6.43319 9.49032 6.2532'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M8.94636 6.05815L10.0716 7.17137C10.0716 7.17137 10.092 7.19315 10.1056 7.17866C10.1192 7.16416 10.0988 7.14239 10.0988 7.14239L9.05516 5.9421M9.24871 6.48316C9.32267 6.69539 9.48786 6.7571 9.5446 6.95074C9.60136 7.14439 9.73847 7.20746 9.78896 7.2702C9.83946 7.33294 9.8099 7.39686 9.83391 7.39836C9.85794 7.39985 9.87947 7.32316 9.82511 7.22625C9.77075 7.12935 9.68541 7.13115 9.61412 6.89552C9.54283 6.65989 9.41753 6.65304 9.36225 6.4338'
        fill='#F6B40E'
      />
      <path
        d='M8.94636 6.05815L10.0716 7.17137C10.0716 7.17137 10.092 7.19315 10.1056 7.17866C10.1192 7.16416 10.0988 7.14239 10.0988 7.14239L9.05516 5.9421M9.24871 6.48316C9.32267 6.69539 9.48786 6.7571 9.5446 6.95074C9.60136 7.14439 9.73847 7.20746 9.78896 7.2702C9.83946 7.33294 9.8099 7.39686 9.83391 7.39836C9.85794 7.39985 9.87947 7.32316 9.82511 7.22625C9.77075 7.12935 9.68541 7.13115 9.61412 6.89552C9.54283 6.65989 9.41753 6.65304 9.36225 6.4338'
        stroke='#85340A'
        strokeWidth='1.112'
      />
      <path
        d='M9.55017 5.8966C9.75389 5.88573 9.84409 5.76252 10.0297 5.78671C9.88293 5.7336 9.76912 5.86168 9.55229 5.85439M9.01776 6.03796L9.97574 5.57028L8.98828 5.96217'
        fill='#85340A'
      />
      <path
        d='M9.54445 6.12867C9.73655 6.20178 9.8641 6.12476 10.0269 6.2229C9.91035 6.1139 9.75925 6.18578 9.56155 6.09053M9.00184 6.04194L10.0547 6.0009L9.0018 5.95988'
        fill='#85340A'
      />
      <path
        d='M9.45782 6.34118C9.60908 6.48715 9.75454 6.46806 9.86978 6.62519C9.80118 6.4769 9.63579 6.48163 9.48731 6.31293M8.98764 6.03957L9.97507 6.43142L9.01704 5.96374'
        fill='#85340A'
      />
      <path
        d='M9.30035 6.50171C9.38773 6.6983 9.52897 6.74004 9.57906 6.93225C9.56888 6.76725 9.41439 6.70411 9.33773 6.48764M8.97417 6.03112L9.74586 6.79621L9.02854 5.97307'
        fill='#85340A'
      />
      <path
        d='M9.00001 6.66672C9.34519 6.66672 9.62501 6.36824 9.62501 6.00005C9.62501 5.63185 9.34519 5.33337 9.00001 5.33337C8.65482 5.33337 8.375 5.63185 8.375 6.00005C8.375 6.36824 8.65482 6.66672 9.00001 6.66672Z'
        fill='#F6B40E'
        stroke='#85340A'
        strokeWidth='1.5'
      />
      <path
        d='M9.21304 5.85742C9.17036 5.85742 9.1295 5.87715 9.10547 5.91817C9.15353 5.96432 9.25973 5.96933 9.33186 5.91291C9.3166 5.89558 9.2982 5.88173 9.27778 5.87219C9.25736 5.86265 9.23533 5.85763 9.21304 5.85742ZM9.21234 5.86793C9.25388 5.86712 9.29269 5.88747 9.29811 5.90768C9.25005 5.96408 9.17324 5.95918 9.12516 5.91817C9.14619 5.88229 9.18003 5.86856 9.21234 5.86791V5.86793Z'
        fill='#843511'
      />
      <path
        d='M9.22195 5.82581C9.15947 5.82581 9.14502 5.84121 9.1162 5.86685C9.08731 5.89248 9.07291 5.88734 9.06812 5.89248C9.06332 5.89764 9.06812 5.91297 9.0777 5.90784C9.08729 5.9027 9.10659 5.89248 9.13539 5.86685C9.16426 5.84117 9.19306 5.84119 9.22193 5.84119C9.30846 5.84119 9.35654 5.91811 9.36615 5.91297C9.37574 5.90784 9.31809 5.82581 9.22195 5.82581Z'
        fill='#85340A'
      />
      <path
        d='M9.39453 5.85651C9.28394 5.75396 9.14456 5.73855 9.06761 5.8155C9.04877 5.8424 9.03671 5.87118 9.03176 5.90118C9.02209 5.95957 9.03919 6.02267 9.08207 6.08732C9.07724 6.0873 9.06761 6.09241 9.06281 6.09757C9.02463 6.01971 9.01113 5.93972 9.02366 5.86367C9.027 5.84373 9.03205 5.82416 9.03874 5.8052C9.14449 5.71292 9.28871 5.70267 9.39446 5.85651H9.39453Z'
        fill='#85340A'
      />
      <path
        d='M9.21124 5.95375C9.23513 5.95375 9.2545 5.93309 9.2545 5.9076C9.2545 5.88211 9.23513 5.86145 9.21124 5.86145C9.18734 5.86145 9.16797 5.88211 9.16797 5.9076C9.16797 5.93309 9.18734 5.95375 9.21124 5.95375Z'
        fill='#85340A'
      />
      <path
        d='M9.33594 5.93859C9.2542 6.00526 9.17248 6.00013 9.12439 5.96936C9.07633 5.93859 9.07633 5.92834 9.08592 5.92834C9.09553 5.92834 9.10511 5.93859 9.14361 5.95909C9.18208 5.97963 9.23975 5.97963 9.33589 5.93859H9.33594Z'
        fill='#85340A'
      />
      <path
        d='M8.90516 6.09216C8.88461 6.09617 8.86999 6.11561 8.86999 6.13791C8.86999 6.1634 8.88974 6.18365 8.91359 6.18365C8.9205 6.18369 8.92731 6.18194 8.93344 6.17854C8.93956 6.17515 8.94482 6.17021 8.94876 6.16416C8.96539 6.17751 8.98845 6.17892 9.00078 6.17916C9.00267 6.17921 9.00512 6.17916 9.00641 6.17916C9.01874 6.17892 9.0418 6.17751 9.05845 6.16416C9.06655 6.17655 9.07949 6.18365 9.09359 6.18365C9.11744 6.18365 9.1372 6.1634 9.1372 6.13791C9.1372 6.11561 9.12257 6.09615 9.10203 6.09216C9.11357 6.09648 9.12102 6.10839 9.12102 6.12142C9.12102 6.12546 9.12028 6.12946 9.11883 6.13319C9.11739 6.13692 9.11526 6.14031 9.11259 6.14317C9.10991 6.14602 9.10673 6.14829 9.10323 6.14983C9.09973 6.15137 9.09599 6.15217 9.0922 6.15216C9.0769 6.15216 9.06425 6.1392 9.06335 6.12291C9.05867 6.13292 9.04009 6.16263 9.00359 6.16416C8.9671 6.16263 8.94854 6.13292 8.94383 6.12291C8.94293 6.1392 8.93033 6.15216 8.91501 6.15216C8.91123 6.15217 8.90748 6.15137 8.90398 6.14983C8.90048 6.14829 8.8973 6.14602 8.89462 6.14317C8.89195 6.14031 8.88982 6.13692 8.88838 6.13319C8.88693 6.12946 8.88619 6.12546 8.88619 6.12142C8.88619 6.10841 8.89361 6.09651 8.90516 6.09216ZM8.95218 6.22872C8.90408 6.22872 8.88506 6.27521 8.8418 6.30598C8.86583 6.29573 8.88477 6.2755 8.91843 6.25498C8.95209 6.23446 8.9808 6.25947 8.99999 6.25947H9.00069C9.01988 6.25947 9.04862 6.23448 9.08225 6.25498C9.11594 6.27552 9.13556 6.29573 9.15961 6.30598C9.11632 6.27521 9.09661 6.22872 9.0485 6.22872C9.03892 6.22872 9.01991 6.23424 9.00069 6.24447H8.99999C8.9808 6.23424 8.96179 6.22872 8.95218 6.22872Z'
        fill='#85340A'
      />
      <path
        d='M8.93564 6.2895C8.91665 6.29039 8.89154 6.29447 8.85547 6.30601C8.94205 6.28549 8.96115 6.3165 8.99963 6.3165H9.0003C9.03878 6.3165 9.0579 6.28549 9.14446 6.30599C9.04829 6.27522 9.02917 6.2955 9.00032 6.2955H8.99963C8.98158 6.2955 8.96729 6.28801 8.93564 6.2895Z'
        fill='#85340A'
      />
      <path
        d='M8.86009 6.30526C8.85451 6.30533 8.84841 6.30538 8.8418 6.306C8.94278 6.31627 8.89424 6.378 9.00002 6.378H9.00069C9.10649 6.378 9.05863 6.31627 9.15961 6.306C9.05386 6.29578 9.08729 6.36226 9.00071 6.36226H9.00002C8.91884 6.36226 8.94386 6.30415 8.86007 6.30526H8.86009ZM9.08734 6.47182C9.08733 6.44734 9.07821 6.42387 9.06198 6.40656C9.04575 6.38925 9.02375 6.37953 9.0008 6.37951C8.97785 6.37952 8.95584 6.38925 8.93961 6.40655C8.92338 6.42386 8.91426 6.44734 8.91425 6.47182C8.91888 6.45104 8.92994 6.43254 8.94565 6.4193C8.96135 6.40606 8.98079 6.39886 9.0008 6.39886C9.02082 6.39885 9.04025 6.40605 9.05596 6.41929C9.07166 6.43254 9.08272 6.45104 9.08734 6.47182Z'
        fill='#85340A'
      />
      <path
        d='M8.61133 5.85651C8.72192 5.75396 8.8613 5.73855 8.93825 5.8155C8.95709 5.8424 8.96915 5.87118 8.9741 5.90118C8.98377 5.95957 8.96667 6.02267 8.92379 6.08732C8.92862 6.0873 8.93825 6.09241 8.94305 6.09757C8.98123 6.01971 8.99473 5.93972 8.9822 5.86367C8.97886 5.84373 8.97381 5.82416 8.96712 5.8052C8.86137 5.71292 8.71715 5.70267 8.6114 5.85651H8.61133Z'
        fill='#85340A'
      />
      <path
        d='M8.78391 5.82581C8.84639 5.82581 8.86084 5.84121 8.88966 5.86685C8.91855 5.89248 8.93295 5.88734 8.93774 5.89248C8.94253 5.89764 8.93774 5.91297 8.92816 5.90784C8.91857 5.9027 8.89927 5.89248 8.87047 5.86685C8.8416 5.84117 8.8128 5.84119 8.78393 5.84119C8.6974 5.84119 8.64931 5.91811 8.63971 5.91297C8.63012 5.90784 8.68777 5.82581 8.78391 5.82581Z'
        fill='#85340A'
      />
      <path
        d='M8.7814 5.85742C8.73872 5.85742 8.69786 5.87715 8.67383 5.91817C8.72189 5.96432 8.82809 5.96933 8.90022 5.91291C8.88496 5.89558 8.86656 5.88173 8.84614 5.87219C8.82572 5.86265 8.80369 5.85763 8.7814 5.85742ZM8.7807 5.86793C8.82224 5.86712 8.86105 5.88747 8.86647 5.90768C8.81841 5.96408 8.7416 5.95918 8.69352 5.91817C8.71455 5.88229 8.74839 5.86856 8.7807 5.86791V5.86793Z'
        fill='#843511'
      />
      <path
        d='M8.78741 5.95375C8.8113 5.95375 8.83068 5.93309 8.83068 5.9076C8.83068 5.88211 8.8113 5.86145 8.78741 5.86145C8.76351 5.86145 8.74414 5.88211 8.74414 5.9076C8.74414 5.93309 8.76351 5.95375 8.78741 5.95375Z'
        fill='#85340A'
      />
      <path
        d='M8.66992 5.93859C8.75166 6.00526 8.83338 6.00013 8.88147 5.96936C8.92953 5.93859 8.92953 5.92834 8.91994 5.92834C8.91033 5.92834 8.90075 5.93859 8.86225 5.95909C8.82378 5.97963 8.76611 5.97963 8.66997 5.93859H8.66992Z'
        fill='#85340A'
      />
    </g>
    <defs>
      <clipPath id='clip0_7860_61522'>
        <rect width='18' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ArgFlagIcon'
);

export default ArgFlagIcon;
