const scrollContainer = {
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'gray.lightVariant3',
    borderRadius: 1,
    opacity: '0.8'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  }
};

const styles = {
  sectionFlyoutWrapper: {
    ...scrollContainer,
    overflowX: 'hidden',
    marginTop: '0px !important',
    height: 'calc(100vh - 260px)',
    borderLeft: '1px solid',
    borderLeftColor: 'gray.lightVariant8',
    backgroundColor: 'white.main'
  },
  meetingListContainer: {
    height: 'calc(100vh - 260px)',
    mt: 2,
    bgcolor: 'white.main',
    borderRadius: 2,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',

    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  meetingListHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    px: 2,
    py: 1.5,
    borderBottom: '1px solid',
    borderBottomColor: 'gray.200',
    backgroundColor: 'gray.50',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  meetingListHeaderText: {
    color: 'gray.600',
    fontSize: 13,
    lineHeight: '160%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  scrollContainer: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.lightVariant3',
      borderRadius: '4px',
      opacity: '0.8'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    }
  },
  listScrollContainer: {
    ...scrollContainer,
    px: 2
  },
  sectionsScrollContainer: {
    ...scrollContainer,
    height: 'calc(100vh - 308px)',
    marginTop: '0px !important'
  },
  listAccordion: {
    boxShadow: 'none',
    border: 'none',
    mt: '8px !important'
  },
  listAccordionSummary: {
    cursor: 'pointer',
    width: 'fit-content',
    borderRadius: 4,
    border: '1px solid',
    borderColor: 'gray.200',
    backgroundColor: 'gray.100',
    mx: 'auto',
    minHeight: 'unset'
  },
  listAccordionDetails: {
    my: 1.5,
    padding: 0
  },
  listMeetingBox: {
    pt: 1,
    pb: 1.25,
    px: 1,
    transition: 'transform 0.3s',
    cursor: 'pointer',
    border: '1px solid',
    borderRadius: 1.5,
    '&:hover': {
      transform: 'scale(1)',
      zIndex: 2
    }
  },
  listMeetingDate: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19.6px',
    textAlign: 'left',
    color: 'gray.900'
  },
  headerWrapper: {
    px: 2.5,
    py: 1.5,
    borderBottom: '1px solid',
    borderBottomColor: 'gray.background',
    backgroundColor: 'gray.50',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  headerText: {
    color: 'gray.900',
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%'
  },
  iconSize: { fontSize: '16px' },
  documentButton: {
    cursor: 'pointer',
    marginLeft: '6px'
  },
  documentButtonText: {
    color: 'blue.background',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  sectionsContainer: {
    display: 'flex',
    px: 5,
    pt: 2,
    pb: 7,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2.5,
    flex: '1 0 0',
    alignSelf: 'stretch',
    margin: '0px !important'
  },
  sectionKey: {
    color: 'gray.950',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '120%'
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'gray.background'
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '8px 16px',
    gap: 5,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    backgroundColor: 'primary.background',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px solid',
    borderColor: 'primary.background'
  },
  cardHeaderText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: 'gray.950',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px'
  },
  cardHeaderTimelineText: {
    color: 'blue.background',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px'
  },
  cardSection: {
    backgroundColor: 'gray.50',
    px: 2,
    py: 1.5,
    width: '100%',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: '1px solid',
    borderColor: 'gray.50'
  },
  cardSectionDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 4,
    mb: 1.5,
    width: '100%'
  },
  cardSectionDetailsText: {
    flex: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
    color: 'gray.800',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '120%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Limit text to 2 lines
    maxHeight: '3em' // Ensures that no more than 2 lines are shown based on line height
  },
  cardSectionOpinionDaysBox: {
    borderRadius: 1,
    backgroundColor: 'gray.background',
    px: 0.5,
    py: 0.3
  },
  cardSummaryWrapper: {
    borderTop: '1px solid',
    borderTopColor: 'gray.200'
  },
  cardSummaryAccordion: {
    display: 'flex',
    flexDirection: 'column-reverse',
    boxShadow: 'none',
    background: 'none',
    border: 'none',
    '&::before': {
      display: 'none'
    },
    '& .Mui-expanded': {
      marginTop: '0px !important'
    }
  },
  hideIcon: {
    width: '16px',
    height: '12px',
    marginRight: '3px'
  },
  cardSummaryAccordionSummary: {
    width: 'fit-content',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '0px',
    mx: 'auto',
    paddingLeft: '16px',
    minHeight: 'unset',
    '&.Mui-expanded': {
      mx: 'auto',

      minHeight: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0
      }
    }
  },
  cardAccordionHideSummaryText: {
    color: 'primary.darkVariant2',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%'
  },
  cardSummaryAccordionDetails: {
    boxShadow: 'none',
    border: 'none',
    padding: 0,
    '& .MuiAccordionDetails-root': {
      padding: 0,
      marginTop: '12px'
    }
  },
  accordionCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '2px',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  accordionCardWrapper: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    paddingBottom: 1.5
  },
  accordionCardHeader: {
    color: 'gray.800',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '150%',
    textTransform: 'capitalize'
  },
  accordionCardProductNumber: {
    color: 'gray.500',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '150%',
    textTransform: 'capitalize'
  },
  accordionPipNumberText: {
    color: 'gray.550',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '150%'
  },
  accordionSummaryText: {
    color: 'gray.800',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '150%'
  },
  pdfDataBox: {
    height: '79vh',
    boxShadow: '0px 0px 10px blue.alice',
    border: 'unset',
    borderRadius: '10px'
  },
  summaryText: {
    maxHeight: 'calc(100vh - 460px)',
    overflowY: 'auto',
    color: 'gray.main',
    // display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflowX: 'hidden'
  },
  listContainer: {
    p: 2,
    border: '1px solid #dfe4ea',
    borderRadius: 2
  },
  productNumberText: {
    width: 'fit-content',
    maxWidth: '100%',
    paddingTop: 0.5,
    paddingBottom: 0.5,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    bgcolor: 'blue.main',
    color: 'white.main'
  },
  brandName: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'blue.main'
  },
  indicationsText: {
    width: '100%',
    color: 'black.main',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflowX: 'hidden'
  },
  sponsorText: {
    ml: 2,
    color: 'black.main',
    textTransform: 'uppercase'
  },
  noMeetingDetailsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noMeetingDetailsText: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '140%',
    color: 'gray.900'
  },
  noMeetingDetailsLink: {
    color: 'secondary.600',
    fontSize: 13,
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  meetingSummarySwtich: {
    fontSize: 14,
    fontWeight: 400,
    color: 'gray.800',
    lineHeight: '140%',
    letterSpacing: '0.2px'
  },
  icon: {
    fontSize: 11,
    marginLeft: 0
  },
  iconText: {
    bgcolor: 'white.main',
    color: 'gray.700',
    fontSize: 12,
    fontWeight: 700,
    height: 'unset',
    '& .MuiChip-label': {
      pl: 1,
      pr: 0
    }
  },
  meetingYearSubInformationContainer: {
    display: 'flex',
    alignItems: 'center',
    mt: '0px !important',
    gap: 1.5
  },
  noResultHeading: {
    fontSize: 14,
    fontWeight: 700,
    color: 'gray.900',
    lineHeight: '140%',
    letterSpacing: '0.2px'
  },
  noResultSubHeading: {
    fontSize: 14,
    fontWeight: 500,
    color: 'gray.900',
    lineHeight: '140%',
    letterSpacing: '0.2px'
  },
  noResultFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center'
  }
};

export default styles;
