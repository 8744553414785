import React, { useState } from 'react';

import { Dialog, Button } from '@mui/material';

import ServiceRequests from '../../UserProfile/components/Moxo/ServiceRequests';

const Feedback = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        disableRipple
        onClick={() => setOpen(true)}
        sx={{
          borderRadius: 6,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'primary.700',
          color: 'primary.700',
          textTransform: 'none',
          fontFamily: 'Mulish',
          fontSize: 14,
          fontWeight: 600,
          px: 2,
          py: 0.75,
          height: 32,
          width: 152,
          '&:hover': {
            backgroundColor: 'primary.0'
          },
          '&:active': {
            backgroundColor: 'primary.50'
          },
          '&:disabled': {
            borderWidth: 0,
            backgroundColor: 'gray.200',
            color: 'gray.500'
          }
        }}>
        Provide Feedback
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: 2,
            backgroundColor: 'white.main',
            width: 833,
            maxWidth: 833,
            height: 716,
            maxHeight: 716,
            px: 3,
            pt: 3,
            pb: 5
          },
          '& .MuiDialog-paperFullScreen': {
            backgroundColor: 'transparent'
          }
        }}>
        <ServiceRequests />
      </Dialog>
    </>
  );
};

export default React.memo(Feedback);
