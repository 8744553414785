import { createSvgIcon } from '@mui/material';

const ArrowSmallLeftIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='9' height='8' viewBox='0 0 9 8' fill='none'>
    <path
      d='M8.87473 3.37449H2.62473L4.68286 1.31637L3.79911 0.432617L1.11598 3.11574C0.881644 3.35015 0.75 3.66804 0.75 3.99949C0.75 4.33095 0.881644 4.64883 1.11598 4.88324L3.79911 7.56637L4.68286 6.68262L2.62473 4.62449H8.87473V3.37449Z'
      fill='currentColor'
    />
  </svg>,
  'ArrowSmallLeft'
);

export default ArrowSmallLeftIcon;
