import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';
import getApprovalTracks from './getApprovalTracks';

interface EMAAPIApps {
  accelerated_assessment: boolean;
  accelerated_assessment_text: string;
  generic_name: string;
  advanced_therapy: boolean;
  advanced_therapy_text: string;
  approval_year: string;
  atc_classification: Array<any>;
  atc_code: string;
  biosimilar: boolean;
  biosimilar_text: string;
  center: string;
  conditional_approval: boolean;
  conditional_approval_text: string;
  document_count: number;
  document_total_page_count: number;
  exceptional_circumstances: boolean;
  exceptional_circumstances_text: string;
  formulation: Array<string>;
  inProjects: Array<{
    id: number;
    name: string;
  }>;
  indications_and_usage: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  marketing_status: string;
  matched_synonyms: Array<string>;
  monoclonal_antibody: boolean;
  orphan_medicine: boolean;
  orphan_medicine_text: string;
  pediatric_use: Array<string>;
  product_number: string;
  route: Array<string>;
  source_index: string;
  sponsor_name: string;
  product_name: string;
  submission_pathway: string;
  submission_pathway_text: string;
}

export const EMA_IDENTIFIER_KEY = 'product_number';

const mapEMAApps = (apiData: EMAAPIApps) => {
  const approvalTracks = getApprovalTracks(apiData, 'eu');
  const cardProps: CardData = {
    source: 'eu',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    groupTitle: titleCase(apiData.generic_name),
    // using application_number as fallback for product_number (used for reg360 header popup card)
    identifier: apiData.product_number ?? (apiData as any)?.application_number,
    showDiscontinued: apiData.marketing_status?.toLowerCase() === 'red',
    showTentative: apiData.marketing_status?.toLowerCase() === 'yellow',
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        )
      },
      {
        key: 'Formulation',
        value: titleCase(
          (Array.isArray(apiData.formulation)
            ? apiData.formulation.join(';')
            : apiData.formulation) || '---'
        )
      },
      {
        key: 'Route',
        value: titleCase(
          (Array.isArray(apiData.route) ? apiData.route.join(' ') : apiData.route) || '---'
        )
      },
      {
        key: 'Submission Pathway',
        value: titleCase(
          (Array.isArray(apiData?.submission_pathway)
            ? apiData.submission_pathway.join(';')
            : apiData.submission_pathway) || '---'
        ),
        tooltipTitle: `Submission Pathway: ${apiData.submission_pathway} ${apiData?.submission_pathway_text}`
      },
      { key: 'First Approval Year', value: apiData.approval_year }
    ],
    colorScheme: apiData?.center,
    labels: [apiData?.center.toLowerCase() === 'ema-who' ? 'EMA WHO' : 'EMA'],
    approvalTracks,
    ...apiData
  };

  return cardProps;
};

export default mapEMAApps;
