import React, { useEffect, useState, useRef, useMemo } from 'react';
// third party
import { debounce, throttle } from 'lodash';
//
import { Box, Grid, Stack, Typography } from '@mui/material';

// styles
import styles from '../../Guidelines/styles/Guidelines.styles';

import GuidelinesDetailsLoader from '../../Guidelines/components/common/GuidelinesDetailsLoader';
import GuidelinesDetails from './GuidelinesDetails';
import RightIndex from '../../Guidelines/components/common/RightIndex';
import { GUIDELINES } from '../contants';

const Main = ({
  selectedGuidelines,
  guidelinesListLoading
}: {
  selectedGuidelines: any;
  guidelinesListLoading: boolean;
}) => {
  const containerRef = useRef(null);
  const [activeGuideline, setActiveGuideline] = useState('');
  const [showGuideline, setShowGuideline] = useState(true);

  const [filteredGuidelines, setFilteredGuidelines] = useState<Record<string, any>>(
    selectedGuidelines?.guidelines || {}
  );

  const [selectedGroup, setSelectedGroup] = useState<string>('All');
  const scrollStatus = useRef<boolean>(false);

  useEffect(() => {
    if (selectedGuidelines?.guidelines) {
      setFilteredGuidelines(selectedGuidelines?.guidelines);
    }
  }, [selectedGuidelines]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // throttle in order to reduce the stutter while scrolling
    const throttledSetActiveGuideline = throttle(guidelineId => {
      setActiveGuideline(guidelineId);
    }, 300);

    // handle intersections when scrolling manually
    const handleIntersectionEntries = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          throttledSetActiveGuideline(entry.target.id);
        }
      });
    };

    // handle intersection to avoid when user click on any guidelines
    const handleEmptyIntersection = debounce(() => {
      scrollStatus.current = false;
    }, 200);

    const handleIntersection = (entries: any) => {
      if (scrollStatus.current) {
        handleEmptyIntersection();
      } else {
        handleIntersectionEntries(entries);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: container,
      threshold: 0.1
    });

    const guidelineId = Object.keys(filteredGuidelines);

    if (guidelineId?.length > 0) {
      guidelineId.forEach((guidelines: any) => {
        const element = document.getElementById(guidelines);
        if (element) observer.observe(element);
      });
    } else {
      setActiveGuideline('');
    }

    // eslint-disable-next-line consistent-return
    return () => {
      guidelineId.forEach(guidelines => {
        const element = document.getElementById(guidelines);
        if (element) observer.unobserve(element);
      });
    };
  }, [guidelinesListLoading, filteredGuidelines, selectedGroup]);

  const handleSectionClick = (id: string) => {
    const guidelinesId = id;
    const guidelinesElement = document.getElementById(guidelinesId);
    if (guidelinesElement) {
      guidelinesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveGuideline(id);
      // ref is used in order for not triggering scroll intersection function
      scrollStatus.current = true;
    }
  };

  const guidelinesGroupByCategory = useMemo(() => {
    const groupedData: any = {};

    Object.values(filteredGuidelines).forEach(items => {
      items.forEach((item: { group_by_category_name: any; guideline_group: any }) => {
        const groupName = item?.group_by_category_name;
        if (!groupedData[groupName]) {
          groupedData[groupName] = {};
        }
        const guidelineGroup = item?.guideline_group;
        if (!groupedData[groupName][guidelineGroup]) {
          groupedData[groupName][guidelineGroup] = [];
        }
        groupedData[groupName][guidelineGroup].push(item);
      });
    });

    return groupedData;
  }, [filteredGuidelines]);

  const isGuidelinesAvailable = Object.keys(filteredGuidelines || {})?.length > 0;
  const guidelinesContainerSize = isGuidelinesAvailable ? 11 : 12;
  return (
    <Stack direction='column' px={5}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          {/* Meeting details */}
          <Stack spacing={2} sx={styles.meetingListContainer}>
            {!guidelinesListLoading && isGuidelinesAvailable && (
              <>
                {/* guidelines */}
                <Grid
                  container
                  id='guidelinesContainer'
                  ref={containerRef}
                  sx={{
                    marginTop: '0px !important'
                  }}>
                  <Grid
                    item
                    sm={showGuideline && isGuidelinesAvailable ? 8.8 : guidelinesContainerSize}
                    md={showGuideline && isGuidelinesAvailable ? 9 : guidelinesContainerSize}
                    lg={showGuideline && isGuidelinesAvailable ? 9.4 : guidelinesContainerSize}
                    id='guidelineSections'
                    sx={styles.sectionsScrollContainer}>
                    {/* TODO: @Rohan code here */}
                    <GuidelinesDetails
                      guidelines={guidelinesGroupByCategory}
                      selectedGroup={selectedGroup}
                      setSelectedGroup={setSelectedGroup}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={showGuideline ? 2.2 : 1}
                    md={showGuideline ? 3 : 1}
                    lg={showGuideline ? 2.6 : 1}
                    id='sectionFlyout'
                    sx={styles.sectionFlyoutWrapper}>
                    <RightIndex
                      activeSection={activeGuideline}
                      showSection={showGuideline}
                      setShowSection={setShowGuideline}
                      handleSectionClick={handleSectionClick}
                      selectedGuidelineSections={
                        selectedGroup === 'All'
                          ? filteredGuidelines
                          : guidelinesGroupByCategory[selectedGroup]
                      }
                      sections={GUIDELINES}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!guidelinesListLoading && !isGuidelinesAvailable && (
              <Box sx={styles.noResultFoundContainer}>
                <Typography sx={styles.noResultHeading}>Sorry, no results found</Typography>
                <Typography sx={styles.noResultSubHeading}>
                  Please modify filters and try again
                </Typography>
              </Box>
            )}
            {guidelinesListLoading && <GuidelinesDetailsLoader />}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(Main);
